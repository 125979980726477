import { activitiesForModerator } from "./_activitiesForModerator"
import { activitiesForOrganizer } from "./_activitiesForOrganizer"
import { listingsForModerator } from "./_listingsForModerator"
import { listingsForOrganizer } from "./_listingsForOrganizer"
import { locationsForModerator } from "./_locationsForModerator"
import { locationsForOrganizer } from "./_locationsForOrganizer"

import parameters from './_parameters'

export const queries = {
    activitiesForModerator,
    activitiesForOrganizer,
    listingsForModerator,
    listingsForOrganizer,
    locationsForModerator,
    locationsForOrganizer,

    parameters,
}