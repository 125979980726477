import PropTypes from 'prop-types';
// @mui
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
// routes
import { paths } from 'src/routes/paths';
import { RouterLink } from 'src/routes/components';
// utils
import { fDateTime } from 'src/utils/format-time';
import { fCurrency } from 'src/utils/format-number';
// components
import Image from 'src/components/image';
import Iconify from 'src/components/iconify';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { shortDateLabel } from 'src/components/custom-date-range-picker';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { reduxFunc } from 'src/hophop/redux';
import { Skeleton } from '@mui/material';
// import Skeleton from 'src/theme/overrides/components/skeleton';

// ----------------------------------------------------------------------

export default function CreateActivityCard({ values, onView, onEdit, onDelete }) {

  // console.log({ values })
  // const popover = usePopover();
  const dispatch = useDispatch()
  const { accessToken } = useSelector(state => state.auth)
  const { selectedListingForModerator } = useSelector(state => state.listing)
  const { locations } = useSelector(state => state.parameter)
  // const shortLabel = shortDateLabel(values?.available?.startDate, values?.available?.endDate);

  // console.log({ locations })


  useEffect(() => {
    if (accessToken && values.listingId) {
      dispatch(reduxFunc.listingFunc.fetchSelectedListingForModeratorFunc({ accessToken, listingId: values?.listingId }))
    }
  }, [accessToken, dispatch, values.listingId])



  // const renderRating = (
  //   <Stack
  //     direction="row"
  //     alignItems="center"
  //     sx={{
  //       top: 8,
  //       right: 8,
  //       zIndex: 9,
  //       borderRadius: 1,
  //       position: 'absolute',
  //       p: '2px 6px 2px 4px',
  //       typography: 'subtitle2',
  //       bgcolor: 'warning.lighter',
  //     }}
  //   >
  //     <Iconify icon="eva:star-fill" sx={{ color: 'warning.main', mr: 0.25 }} /> {values?.ratingNumber}
  //   </Stack>
  // );

  const renderPrice = (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        top: 8,
        left: 8,
        zIndex: 9,
        borderRadius: 1,
        bgcolor: 'grey.800',
        position: 'absolute',
        p: '2px 6px 2px 4px',
        color: 'common.white',
        typography: 'subtitle2',
      }}
    >
      {!!values?.priceSale && (
        <Box component="span" sx={{ color: 'grey.500', mr: 0.25, textDecoration: 'line-through' }}>
          {fCurrency(values?.priceSale)}
        </Box>
      )}
      {fCurrency(values?.price)}
    </Stack>
  );

  const renderImages = (
    <Stack
      spacing={0.5}
      direction="row"
      sx={{
        p: (theme) => theme.spacing(1, 1, 0, 1),
        mb: 2,
      }}
    >
      <Stack flexGrow={1} sx={{ position: 'relative' }}>
        {renderPrice}
        {/* {renderRating} */}
        <Image
          alt={selectedListingForModerator?.title}
          src={selectedListingForModerator ? selectedListingForModerator?.coverImage : ""}
          sx={{ borderRadius: 1, height: 164, width: 1 }}
        />
      </Stack>

      <Stack spacing={0.5}>
        <Image
          alt={selectedListingForModerator?.images?.[0]}
          src={selectedListingForModerator ? selectedListingForModerator?.images?.[0] : null}
          ratio="1/1"
          sx={{ borderRadius: 1, width: 80 }}
        />
        <Image
          alt={selectedListingForModerator?.images?.[1]}
          src={selectedListingForModerator ? selectedListingForModerator?.images?.[1] : null}
          ratio="1/1"
          sx={{ borderRadius: 1, width: 80 }}
        />
      </Stack>

    </Stack>
  );

  // const renderTexts = (
  //   <ListItemText
  //     sx={{
  //       p: (theme) => theme.spacing(2.5, 2.5, 2, 2.5),
  //     }}
  //     primary={`Oluşturulma Tarihi : ${new Intl.DateTimeFormat("tr", { dateStyle: "medium" }).format(new Date())}`}
  //     // primary={`Posted date: ${fDateTime(values?.schedule?.onceDate)}`}
  //     secondary={
  //       <Link component={RouterLink} href={paths.dashboard.tour.details(values?.id)} color="inherit">
  //         {values?.name}
  //       </Link>
  //     }
  //     primaryTypographyProps={{
  //       typography: 'caption',
  //       color: 'text.disabled',
  //     }}
  //     secondaryTypographyProps={{
  //       // mt: 1,
  //       noWrap: true,
  //       component: 'span',
  //       color: 'text.primary',
  //       typography: 'subtitle1',
  //     }}
  //   />
  // );

  const renderInfo = (
    <Stack
      spacing={1.5}
      sx={{
        position: 'relative',
        p: (theme) => theme.spacing(2.5, 2.5, 2.5, 2.5),
      }}
    >
      {/* <IconButton onClick={popover.onOpen} sx={{ position: 'absolute', bottom: 20, right: 8 }}>
        <Iconify icon="eva:more-vertical-fill" />
      </IconButton> */}

      {[
        {
          label: locations?.find(item => item?._id === values?.locationId)?.address?.title,
          icon: <Iconify icon="mingcute:location-fill" sx={{ color: 'error.main' }} />,
        },
        {
          label: values?.duration ? `${values?.duration} saat` : "",
          icon: <Iconify icon="solar:clock-circle-bold" sx={{ color: 'info.main' }} />,
        },
        {
          label: values?.quota ? `${values?.quota} Kişi` : "",
          icon: <Iconify icon="solar:users-group-rounded-bold" sx={{ color: 'primary.main' }} />,
        },
        {
          label: values?.hoursBeforeReservationExpires !== "" ? `Son başvuru ${values?.hoursBeforeReservationExpires} saat önce` : "",
          icon: <Iconify icon="solar:clock-circle-bold" sx={{ color: 'info.main' }} />,
        },
        {
          label: values?.fee ? `${values?.price} TL` : "",
          icon: <Iconify icon="ph:money" sx={{ color: 'primary.main' }} />,
        },
        {
          label: values?.additionalDetails ? `${values?.additionalDetails}` : "",
          icon: <Iconify icon="fluent:text-description-20-filled" sx={{ color: 'primary.main' }} />,
        },
      ].map((item, i) => (
        <Stack
          key={`${item?.label}-${i}`}
          spacing={1}
          direction="row"
          alignItems="center"
          sx={{ typography: 'body2' }}
        >
          {
            item.label ? (
              <>
                {item.icon}
                {item.label}
              </>
            ) : (
              <Skeleton width={200} height={10} />
            )
          }

        </Stack>
      ))}
    </Stack>
  );

  return (
    <>
      <Card sx={{ boxShadow: '1px 1px 10px 0px #ccc' }}>
        {renderImages}

        {/* {renderTexts} */}

        {renderInfo}
      </Card>

      {/* <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        <MenuItem
          onClick={() => {
            popover.onClose();
            onView();
          }}
        >
          <Iconify icon="solar:eye-bold" />
          View
        </MenuItem>

        <MenuItem
          onClick={() => {
            popover.onClose();
            onEdit();
          }}
        >
          <Iconify icon="solar:pen-bold" />
          Edit
        </MenuItem>

        <MenuItem
          onClick={() => {
            popover.onClose();
            onDelete();
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon="solar:trash-bin-trash-bold" />
          Delete
        </MenuItem>
      </CustomPopover> */}
    </>
  );
}

CreateActivityCard.propTypes = {
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  onView: PropTypes.func,
  values: PropTypes.object,
};
