const listingDetail = ({ listingId }) => {

  const data = JSON.stringify({
    query: `
    query Organizer_listing($listingId: String!) {
      organizer_listing(listingId: $listingId) {
        _id
        title
        category
        subCategory
        ageInterval
        format
        description
        images
        coverImage
        companionship
        # createdBy {
        #   _id
        #   displayName
        #   displayImageUrl
        #   description
        #   role
        # }
        updatedBy {
          _id
          displayName
          displayImageUrl
          description
          role
        }
        status
        statusUpdatedBy {
          _id
          displayName
          displayImageUrl
          description
          role
        }
        type
        rejectionDetail
        createdAt
        updatedAt
      }
    }
              `,
    variables: {
      "listingId": listingId
    }
  })

  return data

}

const allListings = ({ variables }) => {

  const data = JSON.stringify({
    query: `
    query Organizer_listings($pagination: Pagination, $filter: FilterListingByOrganizerInput) {
      organizer_listings(pagination: $pagination, filter: $filter) {
        count
        data {
          _id
          title
          category
          subCategory
          ageInterval
          format
          description
          images
          coverImage
          companionship
          # createdBy {
          #   _id
          #   displayName
          #   displayImageUrl
          #   description
          #   role
          # }
          updatedBy {
            _id
            displayName
            displayImageUrl
            description
            role
          }
          status
          statusUpdatedBy {
            _id
            displayName
            displayImageUrl
            description
            role
          }
          type
          rejectionDetail
          createdAt
          updatedAt
        }
      }
    }
              `,
    variables: {
      "pagination": {
        "page": variables?.pagination?.page ?? null,
        "pageSize": variables?.pagination?.pageSize ?? null
      },
      "filter": {
        "ageInterval": null,
        "category": null,
        "companionship": null,
        "createdByCurrentUser": null,
        "description": null,
        "format": null,
        "title": null
      }
    }

  })

  return data

}

const isUpdatable = ({ listingId }) => {

  const data = JSON.stringify({
    query: `
    query Query($listingId: String!) {
      user_isListingUpdatable(listingId: $listingId)
    }
    `,
    variables: {
      "listingId": listingId
    }
  })

  return data
}

export const listingsForOrganizer = {
  listingDetail,
  allListings,
  isUpdatable,
}