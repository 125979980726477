/* eslint-disable arrow-body-style */
import { enqueueSnackbar } from 'notistack';
import isEqual from 'lodash/isEqual';
import { useDispatch, useSelector } from 'react-redux'
import React, { useEffect, useCallback, useState } from 'react'

import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Container from '@mui/material/Container';
import TableBody from '@mui/material/TableBody';
import IconButton from '@mui/material/IconButton';
import TableContainer from '@mui/material/TableContainer';

import {
    useTable,
    emptyRows,
    TableNoData,
    getComparator,
    TableEmptyRows,
    TableHeadCustom,
    TableSelectedAction,
    TablePaginationCustom,
} from 'src/components/table';
import { graphqlApi } from 'src/hophop/api';
import { reduxFunc } from 'src/hophop/redux'
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { RouterLink } from 'src/routes/components';
import { useBoolean } from 'src/hooks/use-boolean';
import useFetchData from 'src/hophop/hooks/_useFetchData';
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';

import UserTableRow from './location-table-row';



const TABLE_HEAD = [
    { id: 'adress', label: 'Adres' },
    { id: 'city', label: 'İl', width: 180 },
    { id: 'district', label: 'İlçe', width: 220 },
    { id: 'park', label: 'Park', width: 100 },
    { id: '', width: 88 },
];


const Locations = () => {

    const table = useTable();
    const confirm = useBoolean();
    const dispatch = useDispatch()
    const settings = useSettingsContext();
    const { handleFetchData } = useFetchData()
    const { accessToken } = useSelector(state => state.auth)
    const { locationsForOrganizer: locations } = useSelector(state => state.location)
    const [variables, setVariables] = useState({
        "filter": {
            // "byCoordinates": {
            //   "lat": null,
            //   "long": null,
            //   "radius": null
            // },
            "city": null,
            "createdByCurrentUser": null,
            "district": null,
            "parking": null
        },
        "pagination": {
            "page": 1,
            "pageSize": 5
        }
    })

    useEffect(() => {
        if (accessToken) {
            dispatch(reduxFunc.locationFunc.fetchLocationsForOrganizatorFunc({ accessToken, variables }))
        }
    }, [accessToken, dispatch, variables])


    const handleChangePage = (a, b) => {
        console.log({ a, b })

        setVariables(prevState => ({
            ...prevState,
            pagination: {
                ...prevState.pagination,
                page: b + 1
            }
        }))

        table.onChangePage(a, b)

    }



    const handleDeleteRow = useCallback(async (id) => {

        try {
            const response = await handleFetchData({
                data: graphqlApi.activity.mutations.locationsForOrganizer.removeLocation({ locationId: id }),
                accessToken,
                url: "/activity/graphql",
            })
            console.log({ response })

            if (response?.data?.data?.organizer_removeLocation?.success) {
                enqueueSnackbar('Başarılı bir şekilde silinmiştir!');
                dispatch(reduxFunc.locationFunc.fetchLocationsForOrganizatorFunc({ accessToken, variables }))
            }

            if (response?.data?.errors) {
                response?.data?.errors?.forEach((item, index) => {
                    console.log(item)
                    if (item?.message === "There are activities are referenced to the location") {
                        enqueueSnackbar('Önce referans olunan aktivitelerin silinmesi gerekir!', {
                            variant: "error"
                        })
                    }
                })
            }

        } catch (error) {
            console.log(error)
        }

    }, [handleFetchData, accessToken, dispatch, variables]);


    return (
        <Container maxWidth={settings.themeStretch ? false : 'lg'}>

            <CustomBreadcrumbs
                heading="Konumlar"
                action={
                    <Button
                        component={RouterLink}
                        href="/konumlar/olustur"
                        variant="contained"
                        startIcon={<Iconify icon="mingcute:add-line" />}
                    >
                        Yeni Konum
                    </Button>
                }
                sx={{
                    mb: { xs: 3, md: 5 },
                }}
            />

            <Card>


                <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
                    <TableSelectedAction
                        dense={table.dense}
                        numSelected={table?.selected?.length}
                        rowCount={locations?.data?.length}
                        onSelectAllRows={(checked) =>
                            table.onSelectAllRows(
                                checked,
                                locations?.data.map((row) => row.id)
                            )
                        }
                        action={
                            <Tooltip title="Delete">
                                <IconButton color="primary" onClick={confirm.onTrue}>
                                    <Iconify icon="solar:trash-bin-trash-bold" />
                                </IconButton>
                            </Tooltip>
                        }
                    />

                    <Scrollbar>
                        <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
                            <TableHeadCustom
                                order={table.order}
                                orderBy={table.orderBy}
                                headLabel={TABLE_HEAD}
                                rowCount={locations?.data?.length}
                                numSelected={table?.selected?.length}
                                onSort={table.onSort}
                            />

                            <TableBody>
                                {locations?.data
                                    // ?.slice(
                                    //     table.page * table.rowsPerPage,
                                    //     table.page * table.rowsPerPage + table.rowsPerPage
                                    // )
                                    ?.map((row) => (
                                        <UserTableRow
                                            key={row?._id}
                                            row={row}
                                            selected={table.selected.includes(row?._id)}
                                            onSelectRow={() => table.onSelectRow(row?._id)}
                                            onDeleteRow={() => handleDeleteRow(row?._id)}
                                        />
                                    ))}

                                {/* <TableEmptyRows
                                        height={denseHeight}
                                        emptyRows={emptyRows(table?.page, table?.rowsPerPage, locations?.data?.length)}
                                    /> */}

                                <TableNoData notFound={locations?.count === 0} />
                            </TableBody>
                        </Table>
                    </Scrollbar>
                </TableContainer>

                <TablePaginationCustom
                    count={locations?.count ?? 0}
                    page={table.page}
                    rowsPerPage={variables.pagination.pageSize}

                    onPageChange={handleChangePage}
                    // onRowsPerPageChange={table.onChangeRowsPerPage}

                    dense={table.dense}
                    onChangeDense={table.onChangeDense}
                />
            </Card>
        </Container>
    )
}

export default Locations