/* eslint-disable no-nested-ternary */
// /* eslint-disable react/destructuring-assignment */
/* eslint-disable react/self-closing-comp */
/* eslint-disable arrow-body-style */
/* eslint-disable no-constant-condition */
/* eslint-disable react/prop-types */

import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { useParams } from 'react-router';
import { useForm } from "react-hook-form";
import { styled } from '@mui/material/styles';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { Box, Grid, Stepper, Step, StepLabel, MenuItem, Typography, Stack, IconButton, Container, Chip } from '@mui/material';

import ChecklistIcon from '@mui/icons-material/Checklist';
import ThumbUpRoundedIcon from '@mui/icons-material/ThumbUpRounded';
import { BusinessCenterOutlined, Person2Outlined } from '@mui/icons-material';
import OutlinedFlagRoundedIcon from '@mui/icons-material/OutlinedFlagRounded';
import PlayCircleOutlineOutlinedIcon from '@mui/icons-material/PlayCircleOutlineOutlined';

import { graphqlApi } from 'src/hophop/api';
import { reduxFunc } from 'src/hophop/redux';
import { useSnackbar } from 'src/components/snackbar';
import { MultiFilePreview } from 'src/components/upload';
import useFetchData from 'src/hophop/hooks/_useFetchData';
import { useSettingsContext } from 'src/components/settings';
import FormProvider from 'src/components/hook-form/form-provider';
import { RHFAutocomplete, RHFSelect, RHFTextField, RHFUpload } from 'src/components/hook-form';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';
import { ageIntervalsForListing, companionshipsForListing, formatsForListing } from 'src/hophop/utils/_enums';

// import Chip from 'src/theme/overrides/components/chip';

import CreateListingCard from './_CreateListingCard';



const steps = ['Başlangıç', 'Temel Bilgiler', 'Detay Bilgiler', 'Bitiş'];


const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    transitionDuration: '1s',

    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22,
        transitionDuration: '1s',
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor: '#7DC9CF',
            transitionDuration: '1s',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor: '#7DC9CF',
            transitionDuration: '1s',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderRadius: 1,
        transitionDuration: '1s'
    },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
        backgroundColor: '#7DC9CF',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
        backgroundColor: '#7DC9CF',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',

    }),
    transitionDuration: '1s',
}));

function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
        1: <PlayCircleOutlineOutlinedIcon />,
        2: <Person2Outlined />,
        3: <BusinessCenterOutlined />,
        4: <OutlinedFlagRoundedIcon />,
    };

    return (
        <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
            {icons[String(props?.icon)]}
        </ColorlibStepIconRoot>
    );
}


const CreateListing = () => {

    const { id } = useParams()
    const dispatch = useDispatch()
    const settings = useSettingsContext();
    const { handleFetchData } = useFetchData()
    const { enqueueSnackbar } = useSnackbar();
    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = useState(new Set());
    const { accessToken } = useSelector(state => state?.auth)
    const { selectedListingForOrganizer } = useSelector(state => state?.listing)


    const handleNext = useCallback(() => {
        let newSkipped = skipped;
        if (skipped.has(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    }, [activeStep, skipped]);

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };


    useEffect(() => {

        if (accessToken) {
            dispatch(reduxFunc.parameterFunc.fetchParametersFunc({ accessToken }))
            if (id) {
                dispatch(reduxFunc.listingFunc.fetchSelectedListingForOrganizerFunc({ accessToken, listingId: id }))
            } else (
                dispatch(reduxFunc.listingFunc.resetSelectedListing())
            )
        }

    }, [accessToken, dispatch, id])






    const NewBlogSchema = Yup.object().shape({
        "category": Yup.string().required("Zorunlu alan!"),
        "title": Yup.string().required("Zorunlu alan!"),
        "description": Yup.string().required("Zorunlu alan!"),
        "coverImage": Yup.string().required("Zorunlu alan!"),

        "ageInterval": Yup.array().min(1, "en az bir tane seçilmeli!").required("Zorunlu alan!"),
        "companionship": Yup.string().required("Zorunlu alan!"),
        "format": Yup.string().required("Zorunlu alan!"),
        "images": Yup.array().min(1, "En az 1 resim olmalı").max(5, "En fazla 5 resim olmalı!"),
    });

    const defaultValues = useMemo(() => ({
        "category": selectedListingForOrganizer?.category ?? "",
        "title": selectedListingForOrganizer?.title ?? "",
        "description": selectedListingForOrganizer?.description ?? "",
        "coverImage": selectedListingForOrganizer?.coverImage ?? null,

        "ageInterval": selectedListingForOrganizer?.ageInterval
            ? selectedListingForOrganizer?.ageInterval?.map(item => ageIntervalsForListing.find(age => age?.value === item))
            : [],
        "companionship": selectedListingForOrganizer?.companionship ?? "",
        "format": selectedListingForOrganizer?.format ?? "",
        "images": selectedListingForOrganizer?.images,
        // .map(item => ({
        //     preview: item,
        //     name: item,
        //     path: item
        // })) ?? [],
        "subCategory": selectedListingForOrganizer?.subCategory ?? "activity",
        "type": selectedListingForOrganizer?.type ?? "activity",
    }), [
        selectedListingForOrganizer?.ageInterval,
        selectedListingForOrganizer?.category,
        selectedListingForOrganizer?.companionship,
        selectedListingForOrganizer?.coverImage,
        selectedListingForOrganizer?.description,
        selectedListingForOrganizer?.format,
        selectedListingForOrganizer?.images,
        selectedListingForOrganizer?.subCategory,
        selectedListingForOrganizer?.title,
        selectedListingForOrganizer?.type
    ]);

    const methods = useForm({
        resolver: yupResolver(NewBlogSchema),
        defaultValues,
        mode: "all",
    });

    const {
        watch,
        handleSubmit,
        trigger,
        setValue,
        reset,
        // formState
    } = methods;
    // const {
    //     errors,
    //     isValid
    // } = formState
    // console.log({ errors, isValid })

    useEffect(() => {
        reset(defaultValues)
    }, [defaultValues, id, reset])

    const values = watch();

    const handleCreate = async () => {

        try {

            // ############# cover Image  ###############
            const coverImageUploadUrl = await graphqlApi.dms.listingImagesPresignedUrls.listingCoverImagePresignedUrl({
                listingId: values.title,
                file: values.coverImage,
                accessToken
            })

            await graphqlApi.dms.handleSaveImage({
                file: values.coverImage,
                url: coverImageUploadUrl?.presignedUploadUrl
            })

            const coverImageUrl = coverImageUploadUrl.url
            console.log({ coverImageUrl })



            // ############# cover Image  ###############
            const imageUploadUrls = await graphqlApi.dms.listingImagesPresignedUrls.listingImagePresignedUrlList({
                listingId: values.title,
                file: values?.images,
                accessToken,
                imageCount: values?.images?.length
            })

            await imageUploadUrls.forEach(async (item, i) => {
                await graphqlApi.dms.handleSaveImage({
                    file: values.images[i],
                    url: item?.presignedUploadUrl
                })
            })

            const imageUrls = imageUploadUrls.map(item => item.url)
            console.log({ imageUrls })


            // ############# create listing  ###############
            const response = await handleFetchData({
                data: graphqlApi.activity.mutations.listingsForOrganizer.createListing({
                    values: {
                        ...values,
                        "ageInterval": values?.ageInterval?.map(item => item?.value)
                    },
                    coverImage: coverImageUrl,
                    images: imageUrls
                }),
                url: "/activity/graphql",
                accessToken,
            });
            console.log({ response })

            if (response?.data?.data?.organizer_createListing?._id) {
                enqueueSnackbar('Kayıt başarılı!');
                handleNext()
            }

        } catch (error) {
            console.error(error);
        }

    };

    console.log({ values })
    // console.log("first", typeof values?.coverImage)

    const handleEdit = async () => {

        try {
            // ############# cover Image  ###############
            let coverImageUploadUrl;
            let coverImageUrl = null
            if (typeof values?.coverImage !== "string") {
                coverImageUploadUrl = await graphqlApi.dms.listingImagesPresignedUrls.listingCoverImagePresignedUrl({
                    listingId: values.title,
                    file: values.coverImage,
                    accessToken
                })

                await graphqlApi.dms.handleSaveImage({
                    file: values.coverImage,
                    url: coverImageUploadUrl?.presignedUploadUrl
                })

                coverImageUrl = coverImageUploadUrl.url
                console.log({ coverImageUrl })
            }




            // ############# images  ###############
            const prevImagesUrl = values?.images?.filter(item => typeof item === "string")
            const newImages = values?.images?.filter(item => typeof item !== "string")


            const imageUploadUrls = await graphqlApi.dms.listingImagesPresignedUrls.listingImagePresignedUrlList({
                listingId: values.title,
                file: newImages,
                accessToken,
                imageCount: newImages?.length
            })

            await imageUploadUrls.forEach(async (item, i) => {
                await graphqlApi.dms.handleSaveImage({
                    file: newImages[i],
                    url: item?.presignedUploadUrl
                })
            })

            const newImagesUrls = imageUploadUrls.map(item => item.url)
            console.log({ newImagesUrls })


            const response = await handleFetchData({
                data: graphqlApi.activity.mutations.listingsForOrganizer.updateOrganizatorListing({
                    values: {
                        ...values,
                        "ageInterval": values?.ageInterval?.map(item => item?.value)
                    },
                    coverImage: coverImageUrl ?? values?.coverImage,
                    images: [...prevImagesUrl, ...newImagesUrls],
                    listingId: id
                }),
                url: "/activity/graphql",
                accessToken,
            });
            console.log({ response })

            if (response?.data?.data?.organizer_updateOrganizerListing?.success) {
                enqueueSnackbar('Güncelleme başarılı!');
                // setOrganizerOnboardingPageState("last")
                handleNext()
            } else {
                enqueueSnackbar('Güncelleme yapılamadı!', {
                    variant: "info"
                });

            }

        } catch (error) {
            console.error(error);
        }

    };

    const onSubmit = async () => {
        if (id) {
            handleEdit()
        } else {
            handleCreate()
        }
    }


    const renderOrganizerOnboardingPages = useCallback(() => {

        switch (activeStep) {
            case 0:
                return (<FirstPage />)
            case 1:
                return (<FormOne setValue={setValue} />)
            case 2:
                return (<FormTwo values={values} setValue={setValue} />)
            case 3:
                return (<LastPage />)
            default:
                return (<FirstPage />)
        }

    }, [activeStep, setValue, values])


    return (
        <Container maxWidth={settings.themeStretch ? false : 'lg'}>
            <Grid container spacing={3} sx={{ mt: 5 }}>

                <Grid item xs={12}>
                    <CustomBreadcrumbs
                        heading={id ? "Listeleme Güncelle" : "Listeleme Oluştur"}
                        sx={{
                            mb: { xs: 3, md: 5 },
                            ml: '50px'
                        }}
                    />
                </Grid>

                <Grid item xs={12} md={7} >

                    <Stepper
                        activeStep={activeStep}
                        alternativeLabel
                        connector={<ColorlibConnector />}
                        sx={{
                            width: '100%',
                            transition: 'all',
                            transitionDuration: '1s',
                            mb: 5,
                        }}
                    >
                        {steps.map((label, index) => {
                            return (
                                <Step key={label} sx={{ transitionDuration: '1s' }}>
                                    <StepLabel StepIconComponent={ColorlibStepIcon} sx={{ transitionDuration: '1s' }}>
                                        {label}
                                    </StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>

                    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                        <Stack
                            spacing={3}
                            sx={{
                                width: '100%',
                                height: '100%',
                                px: { sm: '10%' }
                            }}
                        >

                            <Box>
                                {renderOrganizerOnboardingPages()}
                            </Box>

                            <Box
                                sx={{
                                    display: (activeStep === 4 || activeStep === 3) ? 'none' : 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                }}
                            >

                                <LoadingButton
                                    variant='contained'
                                    disabled={activeStep === 0}
                                    onClick={() => {
                                        handleBack()
                                    }}
                                >
                                    Geri
                                </LoadingButton>

                                <LoadingButton
                                    // type={activeStep === 2 ? "submit" : ""}
                                    disabled={
                                        activeStep === 3 ||
                                        (activeStep === 1 && (!values.title || !values.category || !values.description || !values.coverImage)) ||
                                        (activeStep === 2 && ((values.ageInterval === "" || values.ageInterval === null) || !values.companionship || !values.format))
                                    }
                                    variant='contained'
                                    onClick={() => {
                                        if (activeStep === 0) {
                                            handleNext()
                                        } else if (activeStep === 1) {
                                            trigger(['title', "category", "description", "coverImage"])
                                            if (values.title && values.category && values.description && values.coverImage) {
                                                handleNext()
                                            }
                                        } else if (activeStep === 2) {
                                            trigger(['ageInterval', "companionship", "format"])
                                            if (values.ageInterval && values.companionship && values.format) {
                                                // handleSubmit(onSubmit)
                                                onSubmit()
                                            }

                                        }
                                    }}
                                >
                                    {activeStep === 0 ? "Başla" : (activeStep === 1 ? "İleri" : "İleri")}
                                </LoadingButton>
                            </Box>

                        </Stack>
                    </FormProvider>

                </Grid>

                <Grid item xs={12} md={5} sx={{ display: { xs: "none", md: 'block' } }}>
                    <CreateListingCard post={values} />
                    <br />
                    <MultiFilePreview files={values?.images} thumbnail />
                    {!id && (<MultiFilePreview files={values?.images} />)}

                </Grid>

            </Grid>
        </Container>
    )
}



const FirstPage = () => {
    const { id } = useParams()
    return (
        <Box
            sx={{
                minHeight: '100px',
                py: 2,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',

            }}
        >
            <IconButton
                sx={{
                    backgroundColor: (theme) => theme.palette.primary.light,
                    p: 2,
                    mb: 2,
                }}
            >
                <ChecklistIcon
                    sx={{
                        width: "100px",
                        height: '100px',
                        color: (theme) => theme.palette.primary.main
                    }}
                />
            </IconButton>
            <Typography
                sx={{
                    fontSize: 35,
                    fontFamily: 'sen !important',
                    color: theme => theme.palette.primary.main
                }}
            >
                {id ? "Listelemeyi Güncelle" : "Yeni Listeleme Oluştur"}
            </Typography>
            <Typography
                sx={{
                    fontSize: 20,
                    fontFamily: 'sen !important',
                    color: '#999',
                    textAlign: 'center',
                }}
            >
                Oluşturduğunuz listelemenin ön izlemesini sağ tarafta görüntüleyebilirsiniz.
            </Typography>
        </Box>
    )
}

const FormOne = ({ setValue }) => {

    const { categories } = useSelector(state => state.parameter)
    // console.log({ categories })
    const handleDrop = useCallback(
        (acceptedFiles) => {
            const file = acceptedFiles[0];

            const newFile = Object.assign(file, {
                preview: URL.createObjectURL(file),
            });

            if (file) {
                setValue('coverImage', newFile, { shouldValidate: true });
            }
        },
        [setValue]
    );

    const handleRemoveFile = useCallback(() => {
        setValue('coverImage', null);
    }, [setValue]);

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={6} >
                <RHFTextField name="title" label="Başlık" />
            </Grid>

            <Grid item xs={12} md={6} >
                <RHFSelect name="category" label="Kategori">
                    {categories?.data?.map((status, i) => (
                        <MenuItem key={`${status}-${i}`} value={status?._id} sx={{ textTransform: 'capitalize' }}>
                            {status?._id?.toLocaleLowerCase()}
                        </MenuItem>
                    ))}
                </RHFSelect>
            </Grid>

            <Grid item xs={12}  >
                <RHFTextField multiline rows={4} name="description" label="Açıklama" />
            </Grid>

            <Grid item xs={12}  >
                <Typography variant="subtitle2">Kapak Resmi</Typography>

                <RHFUpload
                    text1="Kapak Resmi"
                    name="coverImage"
                    maxSize={3145728}
                    onDrop={handleDrop}
                    onDelete={handleRemoveFile}
                />
            </Grid>

        </Grid>
    )
}

const FormTwo = ({ values, setValue }) => {

    const handleDrop = useCallback(
        (acceptedFiles) => {
            const files = values.images || [];

            const newFiles = acceptedFiles.map((file) =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                })
            );

            setValue('images', [...files, ...newFiles], { shouldValidate: true });
        },
        [setValue, values.images]
    );

    const handleRemoveFile = useCallback(
        (inputFile) => {
            const filtered = values.images && values.images?.filter((file) => file !== inputFile);
            setValue('images', filtered);
        },
        [setValue, values.images]
    );

    const handleRemoveAllFiles = useCallback(() => {
        setValue('images', []);
    }, [setValue]);


    return (
        <Grid container spacing={3}>


            <Grid item xs={12}>
                {/* <RHFSelect name="ageInterval" label="Yaş Aralığı">
                    {ageIntervalsForListing.map((status, i) => (
                        <MenuItem key={`${status}-${i}`} value={status?.value} sx={{ textTransform: 'capitalize' }}>
                            {status?.name?.toLocaleLowerCase()}
                        </MenuItem>
                    ))}
                </RHFSelect> */}

                <RHFAutocomplete
                    name="ageInterval"
                    placeholder="Yaş Aralığı"
                    multiple
                    disableCloseOnSelect
                    options={ageIntervalsForListing}
                    getOptionLabel={(option) => option?.value?.toString()}
                    isOptionEqualToValue={(option, value) => option?.value === value?.value}
                    renderOption={(props, option) => (
                        <li {...props} key={option?.value?.toString()}>
                            {option?.name}
                        </li>
                    )}
                    renderTags={(selected, getTagProps) => {

                        // console.log({ selected })
                        return selected?.map((option, index) => (
                            <Chip
                                {...getTagProps({ index })}
                                key={option?.value?.toString()}
                                label={option?.name}
                                size="small"
                                color="info"
                                variant="soft"
                            />
                        ))

                    }}
                />
            </Grid>

            <Grid item xs={12}>
                <RHFSelect name="companionship" label="Veli katılımı">
                    {companionshipsForListing.map((status, i) => (
                        <MenuItem key={`${status}-${i}`} value={status?.value} sx={{ textTransform: 'capitalize' }}>
                            {status?.name?.toLocaleLowerCase()}
                        </MenuItem>
                    ))}
                </RHFSelect>
            </Grid>

            <Grid item xs={12}>
                <RHFSelect name="format" label="Format">
                    {formatsForListing.map((status, i) => (
                        <MenuItem key={`${status}-${i}`} value={status?.value} sx={{ textTransform: 'capitalize' }}>
                            {status?.name?.toLocaleLowerCase()}
                        </MenuItem>
                    ))}
                </RHFSelect>
            </Grid>


            <Grid item xs={12}  >
                <Stack spacing={1.5}>
                    <Typography variant="subtitle2">Resimler</Typography>
                    <RHFUpload
                        multiple
                        thumbnail
                        name="images"
                        maxSize={3145728}
                        onDrop={handleDrop}
                        onRemove={handleRemoveFile}
                        onRemoveAll={handleRemoveAllFiles}
                        onUpload={() => console.info('ON UPLOAD')}
                    />
                </Stack>
            </Grid>

        </Grid>
    )
}

const LastPage = () => {
    return (
        <Box
            sx={{
                minHeight: '100px',
                py: 5,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                flexDirection: 'column',
            }}
        >
            <IconButton
                sx={{
                    backgroundColor: (theme) => theme.palette.primary.light,
                    p: 2,
                    mb: 5,
                }}
            >
                <ThumbUpRoundedIcon
                    sx={{
                        width: "100px",
                        height: '100px',
                        color: (theme) => theme.palette.primary.main
                    }}
                />
            </IconButton>
            <Typography
                sx={{
                    fontSize: 35,
                    fontFamily: 'sen !important',
                    color: theme => theme.palette.primary.main
                }}
            >
                Listeleme başarılı bir şelilde oluşturulmuştur.
            </Typography>
        </Box>
    )
}

export default CreateListing