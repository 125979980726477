/* eslint-disable react/self-closing-comp */
/* eslint-disable arrow-body-style */
/* eslint-disable no-constant-condition */
/* eslint-disable react/prop-types */

import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { useSelector } from 'react-redux';
import { Box, Grid, Tooltip } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, useFormContext } from "react-hook-form";

import { useSnackbar } from '../../../../components/snackbar';
import { RHFTextField } from '../../../../components/hook-form';
import { RHFUpload } from '../../../../components/hook-form/rhf-upload';
import FormProvider from '../../../../components/hook-form/form-provider';
import useFetchData from '../../../hooks/_useFetchData';
import { graphqlApi } from '../../../api';



const OrganizerOnboardingIndividualPage = ({ setOrganizerOnboardingPageState, handleNext, handleBack }) => {

    const { handleFetchData } = useFetchData()
    const { enqueueSnackbar } = useSnackbar();
    const { user: userInfo } = useSelector(state => state.user)
    const { user, accessToken } = useSelector(state => state.auth)
    const [formPageState, setFormPageState] = useState({ pageNumber: 1, formState: null })

    const NewBlogSchema = Yup.object().shape({
        fullName: Yup.string().required('Zorunlu alan'),
        identificationNumber: Yup.string().required('Zorunlu alan')
            .max(11, "Geçerli bir TC kimlik numarası giriniz")
            .min(11, "Geçerli bir TC kimlik numarası giriniz"),
        organization: Yup.string(),
        phoneNumber: Yup.string().required('Zorunlu alan'),
        email: Yup.string().email("Geçerli bir email giriniz").required('Zorunlu alan'),
        address: Yup.string().required('Zorunlu alan'),
        logo: Yup.mixed().nullable(false),
    });

    const defaultValues = {
        fullName: '',
        identificationNumber: "",
        organization: "",
        logo: null,
        phoneNumber: "",
        email: "",
        address: ""
    };

    const methods = useForm({
        resolver: yupResolver(NewBlogSchema),
        defaultValues,
        mode: "all",
    });

    const {
        watch,
        handleSubmit,
        formState,
        trigger,
    } = methods;

    const {
        isValid,
        errors,
        touchedFields,
    } = formState


    const onSubmit = async () => {

        try {

            const data = watch();

            const uploadUrl = await graphqlApi.dms.organizationPresignedUrls.logoImageOrganizationPresignedUrls({
                organizationId: userInfo?._id,
                file: data.logo,
                accessToken
            })
            console.log({ uploadUrl })
            const imageUrl = await graphqlApi.dms.handleSaveImage({ file: data.logo, url: uploadUrl?.presignedUploadUrl })
            console.log({ imageUrl })

            const response = await handleFetchData({
                data: graphqlApi.user.mutations.userLMutations.userUpdate({
                    values: data,
                    profileImageUrl: uploadUrl?.url,
                    // userId: user?.userId
                }),
                url: "/user/graphql",
                accessToken,
            });
            console.log({ response })

            if (true) {
                enqueueSnackbar('Kayıt başarılı!');
                setOrganizerOnboardingPageState("last")
                handleNext()
            }

        } catch (error) {
            console.error(error);
        }

    };



    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    px: { sm: '10%' }
                }}
            >

                <Box>
                    {
                        formPageState.pageNumber === 1 ? (
                            <FormOne methods={methods} />
                        ) : (
                            <FormTwo methods={methods} />
                        )
                    }
                </Box>


                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        mt: { xs: '100px' },
                    }}
                >

                    <Tooltip
                        title={
                            formPageState.pageNumber === 1
                                ? "Birinci adıma giderseniz bilgileriniz silinir"
                                : "Birinci forma gidip bilgilerinizi kontrol edebilirsiniz"
                        }
                    >
                        <LoadingButton
                            variant='contained'
                            // disabled={formPageState.pageNumber === 2}
                            onClick={() => {
                                if (formPageState.pageNumber === 2) {
                                    setFormPageState(prevState => ({ ...prevState, pageNumber: 1 }))
                                    handleBack()
                                } else {
                                    setOrganizerOnboardingPageState("first")
                                    handleBack()
                                }
                            }}
                        >
                            geri
                        </LoadingButton>
                    </Tooltip>

                    <LoadingButton
                        disabled={formPageState.pageNumber === 1 ? !!(
                            errors?.fullName || errors?.identificationNumber
                        ) : !!(
                            errors?.address || errors?.email || errors?.logo || errors?.organization || errors?.phoneNumber || false
                        )}
                        variant='contained'
                        onClick={() => {
                            if (formPageState.pageNumber === 1) {
                                trigger(['fullName', "identificationNumber"])
                                if (touchedFields.identificationNumber || touchedFields.fullName) {
                                    setFormPageState(prevState => ({ ...prevState, pageNumber: 2 }))
                                    handleNext()
                                }
                            } else {
                                trigger(['address', "email", "logo", "organization", "phoneNumber"])
                                if (isValid) {
                                    onSubmit()
                                }

                            }
                        }}
                    >
                        {formPageState.pageNumber === 1 ? "İleri" : "kaydet"}
                    </LoadingButton>
                </Box>


            </Box>
        </FormProvider>
    )
}

const FormOne = () => {

    return (
        <Box
            sx={{
                display: 'flex',
                gap: '30px',
                width: '100%'
            }}
        >
            <RHFTextField name="fullName" label="*Ad Soyad" />
            <RHFTextField name="identificationNumber" label="*TC Kimlik No (Daha donra fatura işlemleri için lazım olacak)" />
        </Box>
    )
}


const FormTwo = () => {

    const { setValue } = useFormContext()

    const handleDrop = useCallback(
        (acceptedFiles) => {
            const file = acceptedFiles[0];

            const newFile = Object.assign(file, {
                preview: URL.createObjectURL(file),
            });

            if (file) {
                setValue('logo', newFile, { shouldValidate: true });
            }
        },
        [setValue]
    );

    const handleRemoveFile = () => {
        setValue('logo', null);
    };

    return (
        <Grid container spacing={3}>

            <Grid item xs={12} md={6}>
                <Grid container spacing={3}>
                    <Grid item xs={12} >
                        <RHFTextField name="organization" label="Organizasyon Adı" />
                    </Grid>
                    <Grid item xs={12}>
                        <RHFTextField name="email" label="*Email" />
                    </Grid>
                    <Grid item xs={12}>
                        <RHFTextField name="phoneNumber" label="*Telefon No" />
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} md={6}>

                <RHFUpload
                    name="logo"
                    helperText=" "
                    maxSize={3145728}
                    onDrop={handleDrop}
                    onDelete={handleRemoveFile}
                    sx={{
                        height: '100%',
                        '& > div:not(.MuiFormHelperText-root)': {
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        },

                    }}
                />
            </Grid>

            <Grid item xs={12}>
                <RHFTextField name="address" label="*Adres" multiline rows={3} />
            </Grid>

        </Grid>
    )
}


export default OrganizerOnboardingIndividualPage