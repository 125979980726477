/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable arrow-body-style */
import { enqueueSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Button, Container, Pagination, paginationClasses } from '@mui/material'

import { graphqlApi } from 'src/hophop/api'
import { reduxFunc } from 'src/hophop/redux'
import { RouterLink } from 'src/routes/components'
import Iconify from 'src/components/iconify/iconify'
import useFetchData from 'src/hophop/hooks/_useFetchData'
import { useSettingsContext } from 'src/components/settings'
import { PostItemSkeleton } from 'src/sections/blog/post-skeleton'
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs'

import ListingCard from './_ListingCard'


const Listings = () => {

    const dispatch = useDispatch()
    const settings = useSettingsContext();
    const { handleFetchData } = useFetchData()
    const { accessToken } = useSelector(state => state.auth)
    const { listingsForModerator, loading } = useSelector(state => state.listing)
    const [variables, setVariables] = useState({
        "filter": {
            "ageInterval": null,
            "category": null,
            "companionship": null,
            "createdByCurrentUser": null,
            "description": null,
            "format": null,
            "status": null,
            "title": null,
            "type": null
        },
        "pagination": {
            "page": 1,
            "pageSize": 6
        }
    })

    useEffect(() => {
        if (accessToken) {
            dispatch(reduxFunc.listingFunc.fetchListingsForModeratorFunc({ accessToken, variables }))
        }
    }, [accessToken, dispatch, variables])

    console.log({ listingsForModerator })


    const handleDelete = async ({ id }) => {

        try {
            const response = await handleFetchData({
                data: graphqlApi.activity.mutations.listingsForModerator.removeListing({ listingId: id }),
                url: "/activity/graphql",
                accessToken,
            });
            console.log({ response })

            if (response?.data?.data?.moderator_removeListing?.success) {
                enqueueSnackbar('Başarılı bir şekilde silindi!');
                dispatch(reduxFunc.listingFunc.fetchListingsForModeratorFunc({ accessToken, variables }))
            }
            if (response?.data?.data?.moderator_removeListing?.success === false) {
                enqueueSnackbar('Listeleme silinemedi!', {
                    variant: "error"
                });
                dispatch(reduxFunc.listingFunc.fetchListingsForModeratorFunc({ accessToken, variables }))
            }
            if (response?.data?.errors) {
                response?.data?.errors?.forEach((item, index) => {
                    if (item?.message === "There are activities are referenced to the listing") {
                        enqueueSnackbar('Aktiviteye bağlı bir listeleme olduğu için silinemez!', {
                            variant: "error",
                            transitionDuration: 5
                        })
                    }
                })
            }

        } catch (error) {
            console.error(error);
        }

    };

    const handlePagination = (event, value) => {
        // console.log({ event, value })
        setVariables(prevState => ({
            ...prevState,
            pagination: {
                ...prevState.pagination,
                page: value
            }
        }))
    };

    const renderSkeleton = (
        <>
            {[...Array(10)].map((_, index) => (
                <PostItemSkeleton key={index} variant="horizontal" />
            ))}
        </>
    );

    const renderList = (
        <>
            {listingsForModerator?.data?.map((post) => (
                <ListingCard key={post._id} post={post} handleDelete={handleDelete} />
            ))}
        </>
    );

    return (
        <Container maxWidth={settings.themeStretch ? false : 'lg'}>

            <CustomBreadcrumbs
                heading="Listelemeler"
                action={
                    <Button
                        component={RouterLink}
                        href="/listelemeler/olustur"
                        variant="contained"
                        startIcon={<Iconify icon="mingcute:add-line" />}
                    >
                        Yeni Listeleme
                    </Button>
                }
                sx={{ mb: { xs: 3, md: 5 }, }}
            />

            <Box
                gap={3}
                display="grid"
                gridTemplateColumns={{
                    xs: 'repeat(1, 1fr)',
                    md: 'repeat(2, 1fr)',
                }}
            >
                {loading ? renderSkeleton : renderList}
            </Box>

            {listingsForModerator?.count > variables.pagination.pageSize && (
                <Pagination
                    onChange={handlePagination}
                    onClick={(e) => console.log({ e })}
                    count={Math.ceil(listingsForModerator?.count / variables.pagination.pageSize)}
                    sx={{
                        mt: 8,
                        [`& .${paginationClasses.ul}`]: {
                            justifyContent: 'center',
                        },
                    }}
                />
            )}
        </Container>
    )
}

export default Listings