import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
// routes
import { paths } from 'src/routes/paths';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// components
import Iconify from 'src/components/iconify';
import { RouterLink } from 'src/routes/components';
import FormProvider, { RHFTextField, RHFCode } from 'src/components/hook-form';
// assets
import { SentIcon } from 'src/assets/icons';
import { enqueueSnackbar } from 'notistack';
import { useNavigate } from 'react-router';
import useFetchData from 'src/hophop/hooks/_useFetchData';

// ----------------------------------------------------------------------

export default function ClassicNewPasswordView() {

  const navigate = useNavigate()
  const password = useBoolean();
  const { handleFetchData } = useFetchData()


  const NewPasswordSchema = Yup.object().shape({
    code: Yup.string().min(6, '6 karakter içermeli!').required('Gerekli'),
    email: Yup.string().required('Gerekli').email('Geçerli bir email adresi giriniz!'),
    password: Yup.string().required('Gerekli')
      .matches(/^(?=.*[a-z])/, 'En az 1 küçük karakter olmalı')
      .matches(/^(?=.*[A-Z])/, 'En az bir büyük karakter olmalı')
      .matches(/^(?=.*[0-9])/, 'En az bir numara içermeli')
      .matches(/^(?=.*[!@#%&?.,+%-_])/, 'En az bir özel karakter içermeli')
      .min(8, "En az 8 karakter içermeli"),
    confirmPassword: Yup.string()
      .required('Gerekli')
      .oneOf([Yup.ref('password')], 'Şifreler eşleşmeli!'),
  });

  const defaultValues = {
    code: '',
    email: '',
    password: '',
    confirmPassword: '',
  };

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(NewPasswordSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      // await new Promise((resolve) => setTimeout(resolve, 500));
      await handleFetchData({
        data: JSON.stringify({
          "email": data.email,
          "token": "",
          "password": "Deneme123?"
        }),
        url: "/auth/auth/resetpassword"
      })

      console.log('DATA:', {
        email: data.email,
        code: `${data.code1}${data.code2}${data.code3}${data.code4}${data.code5}${data.code6}`,
        password: data.password,
      });
      sessionStorage.removeItem('email-recovery');
      enqueueSnackbar('Şifre değiştirildi!');
      navigate("/auth");
    } catch (error) {
      console.error(error);
    }
  });

  const renderForm = (
    <Stack spacing={3} alignItems="center">
      <RHFTextField
        name="email"
        label="Email"
        placeholder="example@gmail.com"
        InputLabelProps={{ shrink: true }}
      />

      <RHFCode name="code" />

      <RHFTextField
        name="password"
        label="Şifre"
        type={password.value ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={password.onToggle} edge="end">
                <Iconify icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <RHFTextField
        name="confirmPassword"
        label="şifreyi onayla"
        type={password.value ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={password.onToggle} edge="end">
                <Iconify icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting}
      >
        Şifreyi güncelle
      </LoadingButton>

      <Typography variant="body2">
        {`Don’t have a code? `}
        <Link
          variant="subtitle2"
          sx={{
            cursor: 'pointer',
          }}
        >
          Tekrar gönder
        </Link>
      </Typography>

      <Link
        component={RouterLink}
        href={paths.authDemo.classic.login}
        color="inherit"
        variant="subtitle2"
        sx={{
          alignItems: 'center',
          display: 'inline-flex',
        }}
      >
        <Iconify icon="eva:arrow-ios-back-fill" width={16} />
        Geri dön
      </Link>
    </Stack>
  );

  const renderHead = (
    <>
      <SentIcon sx={{ height: 96 }} />

      <Stack spacing={1} sx={{ my: 5 }}>
        <Typography variant="h3">İstek başarılı bir şekilde gönderilmiştir!</Typography>

        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          Onay kodunu email adresine gönderdik.
          <br />
          Lütfen 6 haneli onay kodunu aşağıdaki kutuya yazınız!
        </Typography>
      </Stack>
    </>
  );

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      {renderHead}

      {renderForm}
    </FormProvider>
  );
}
