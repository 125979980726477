/* eslint-disable no-shadow */
/* eslint-disable arrow-body-style */
import { enqueueSnackbar } from 'notistack';
import isEqual from 'lodash/isEqual';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux'
import React, { useEffect, useCallback, useState } from 'react'

import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Container from '@mui/material/Container';
import TableBody from '@mui/material/TableBody';
import IconButton from '@mui/material/IconButton';
import TableContainer from '@mui/material/TableContainer';

import {
    useTable,
    emptyRows,
    TableNoData,
    getComparator,
    TableEmptyRows,
    TableHeadCustom,
    TableSelectedAction,
    TablePaginationCustom,
} from 'src/components/table';
import { graphqlApi } from 'src/hophop/api';
import { reduxFunc } from 'src/hophop/redux'
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { RouterLink } from 'src/routes/components';
import { useBoolean } from 'src/hooks/use-boolean';
import useFetchData from 'src/hophop/hooks/_useFetchData';
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';

import UserTableRow from './location-table-row';


const TABLE_HEAD = [
    { id: '', label: '' },
    { id: 'customer', label: 'Müşteri', width: 180 },
    { id: 'district', label: 'Aktivite', width: 220 },
    { id: 'park', label: 'Konum', width: 100 },
    { id: '', width: 88 },
    { id: '', width: 88 },
];



const ReservationDetailList = () => {

    const { id } = useParams()
    console.log({ id })
    const table = useTable();
    const confirm = useBoolean();
    const dispatch = useDispatch()
    const settings = useSettingsContext();
    const { handleFetchData } = useFetchData()
    const { accessToken } = useSelector(state => state.auth)
    const { listingsForModerator: list } = useSelector(state => state.listing)
    const [variables, setVariables] = useState({
        "filter": {
            // "byCoordinates": {
            //   "lat": null,
            //   "long": null,
            //   "radius": null
            // },
            "city": null,
            "createdByCurrentUser": null,
            "district": null,
            "parking": null
        },
        "pagination": {
            "page": 1,
            "pageSize": 5
        }
    })

    useEffect(() => {
        if (accessToken) {
            dispatch(reduxFunc.listingFunc.fetchListingsForModeratorFunc({ accessToken, variables }))
        }
    }, [accessToken, dispatch, variables])


    const handleChangePage = (a, b) => {
        console.log({ a, b })

        setVariables(prevState => ({
            ...prevState,
            pagination: {
                ...prevState.pagination,
                page: b + 1
            }
        }))

        table.onChangePage(a, b)

    }



    const handleDeleteRow = useCallback(async (id) => {

        // try {
        //     const response = await handleFetchData({
        //         data: graphqlApi.activity.mutations.locationsForModerator.removeLocation({ locationId: id }),
        //         accessToken,
        //         url: "/activity/graphql",
        //     })
        //     console.log({ response })

        //     if (response?.data?.data?.moderator_removeLocation?.success) {
        //         enqueueSnackbar('Başarılı bir şekilde silinmiştir!');
        //         dispatch(reduxFunc.locationFunc.fetchLocationsForModeratorFunc({ accessToken, variables }))
        //     }

        //     if (response?.data?.errors) {
        //         response?.data?.errors?.forEach((item, index) => {
        //             console.log(item)
        //             if (item?.message === "There are activities are referenced to the location") {
        //                 enqueueSnackbar('Önce referans olunan aktivitelerin silinmesi gerekir!', {
        //                     variant: "error"
        //                 })
        //             }
        //         })
        //     }

        // } catch (error) {
        //     console.log(error)
        // }

    }, [
        // handleFetchData, accessToken, dispatch, variables
    ]);

    console.log({ list, selected: table.selected })

    return (
        <Container maxWidth={settings.themeStretch ? false : 'lg'}>

            <CustomBreadcrumbs
                heading="Rezervasyon Detayı"
                // action={
                //     <Button
                //         component={RouterLink}
                //         href="/konumlar/olustur"
                //         variant="contained"
                //         startIcon={<Iconify icon="mingcute:add-line" />}
                //     >
                //         Yeni Konum
                //     </Button>
                // }
                sx={{
                    mb: { xs: 3, md: 5 },
                }}
            />

            <Card>


                <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
                    <TableSelectedAction
                        dense={table.dense}
                        numSelected={table?.selected?.length}
                        rowCount={list?.data?.length}
                        onSelectAllRows={(checked) =>
                            table.onSelectAllRows(
                                checked,
                                list?.data.map((row) => row.id)
                            )
                        }
                        action={
                            <>
                                <Tooltip title="Mesaj">
                                    <IconButton color="primary" onClick={confirm.onTrue}>
                                        <Iconify icon="solar:trash-bin-trash-bold" />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Bildirim">
                                    <IconButton color="primary" onClick={confirm.onTrue}>
                                        <Iconify icon="solar:trash-bin-trash-bold" />
                                    </IconButton>
                                </Tooltip>
                            </>
                        }
                    />

                    <Scrollbar>
                        <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
                            <TableHeadCustom
                                order={table.order}
                                orderBy={table.orderBy}
                                headLabel={TABLE_HEAD}
                                rowCount={list?.data?.length}
                                numSelected={table?.selected?.length}
                                onSort={table.onSort}
                            />

                            <TableBody>
                                {list?.data
                                    // ?.slice(
                                    //     table.page * table.rowsPerPage,
                                    //     table.page * table.rowsPerPage + table.rowsPerPage
                                    // )
                                    ?.map((row) => (
                                        <UserTableRow
                                            key={row?._id}
                                            row={row}
                                            selected={table.selected.includes(row?._id)}
                                            onSelectRow={() => table.onSelectRow(row?._id)}
                                            onDeleteRow={() => handleDeleteRow(row?._id)}
                                        />
                                    ))}

                                {/* <TableEmptyRows
                                        height={denseHeight}
                                        emptyRows={emptyRows(table?.page, table?.rowsPerPage, locationsForModerator?.data?.length)}
                                    /> */}

                                <TableNoData notFound={list?.count === 0} />
                            </TableBody>
                        </Table>
                    </Scrollbar>
                </TableContainer>

                <TablePaginationCustom
                    count={list?.count ?? 0}
                    page={table.page}
                    rowsPerPage={variables.pagination.pageSize}

                    onPageChange={handleChangePage}
                    // onRowsPerPageChange={table.onChangeRowsPerPage}

                    dense={table.dense}
                    onChangeDense={table.onChangeDense}
                />
            </Card>
        </Container>
    )
}

export default ReservationDetailList