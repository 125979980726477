/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/prop-types */
/* eslint-disable no-constant-condition */
/* eslint-disable arrow-body-style */

import * as Yup from 'yup'
import { LoadingButton } from '@mui/lab';
import { useParams } from 'react-router';
import { useForm } from 'react-hook-form';
import GoogleMapReact from 'google-map-react';
import React, { useEffect, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Card, Container, Grid, MenuItem, Typography } from '@mui/material';

import { graphqlApi } from 'src/hophop/api';
import { enqueueSnackbar } from 'notistack';
import { reduxFunc } from 'src/hophop/redux';
import useFetchData from 'src/hophop/hooks/_useFetchData';
import { parkingForLocation } from 'src/hophop/utils/_enums';
import { useSettingsContext } from 'src/components/settings';
import FormProvider from 'src/components/hook-form/form-provider';
import { RHFSelect, RHFTextField } from 'src/components/hook-form';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';


const CreateLocations = () => {

    const { id } = useParams()
    const dispatch = useDispatch()
    const settings = useSettingsContext();
    const { handleFetchData } = useFetchData()
    const { accessToken } = useSelector(state => state.auth)
    const { cities } = useSelector(state => state.parameter)

    const [selectedCity, setSelectedCity] = useState(null)

    useEffect(() => {
        if (accessToken) {
            dispatch(reduxFunc.parameterFunc.fetchParametersFunc({ accessToken }))
        }
    }, [accessToken, dispatch])

    const NewBlogSchema = Yup.object().shape({
        "title": Yup.string().required("Zorunlu alan!"),
        "city": Yup.string().required("Zorunlu alan!"),
        "district": Yup.string().required("Zorunlu alan!"),
        "description": Yup.string().required("Zorunlu alan!"),
        "parking": Yup.string().required("Zorunlu alan!"),

        "long": Yup.string("").required("Zorunlu alan!"),
        "lat": Yup.string("").required("Zorunlu alan!"),
    });

    const defaultValues = {
        "title": "",
        "city": "",
        "district": "",
        "description": "",
        "parking": "",

        "long": "",
        "lat": "",
    };

    const methods = useForm({
        resolver: yupResolver(NewBlogSchema),
        defaultValues,
        mode: "all",
    });

    const {
        watch,
        handleSubmit,
        formState,
        trigger,
        setValue,
        setError,
        reset
    } = methods;

    const {
        isValid,
        errors,
        touchedFields,
    } = formState
    const values = watch();

    useEffect(() => {
        setSelectedCity(cities?.data?.find(item => item?.name === values?.city))
    }, [cities?.data, values?.city])

    useEffect(() => {
        if (values.lat) {
            setError("lat", null)
        }
    }, [setError, values.lat])



    console.log({ values, errors })


    const onSubmit = async () => {
        try {
            const response = await handleFetchData({
                data: graphqlApi.activity.mutations.locationsForModerator.createLocation({ values }),
                accessToken,
                url: "/activity/graphql",
            })
            console.log({ response })

            if (response?.data?.data?.moderator_createLocation?._id) {
                enqueueSnackbar("Lokasyon başarılı bir şekilde kayıtedildi!")
                reset()
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <Container maxWidth={settings.themeStretch ? false : 'lg'}>

            <CustomBreadcrumbs
                heading={id ? "Konum Güncelle" : "Konum Oluştur"}
                sx={{
                    mb: { xs: 3, md: 5 },
                    ml: '50px'
                }}
            />

            <Card
                sx={{
                    p: 3,
                    maxWidth: '900px',
                    mx: { xs: 1, sm: 'auto', lg: 3 },
                    mt: 3,
                }}
            >
                <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={3}>

                        <Grid item xs={12} md={6} >
                            <RHFTextField name="title" label="Başlık" />
                        </Grid>

                        <Grid item xs={12} md={6} >
                            <RHFSelect name="parking" label="Park Yeri">
                                {(parkingForLocation)?.map((status, i) => (
                                    <MenuItem key={status?.value ?? i} value={status?.value} sx={{ textTransform: 'capitalize' }}>
                                        {status?.name?.toLocaleLowerCase()}
                                    </MenuItem>
                                ))}
                            </RHFSelect>
                        </Grid>

                        <Grid item xs={12} md={6} >
                            <RHFSelect name="city" label="İl">
                                {(cities?.data ?? [])?.map((status, i) => (
                                    <MenuItem key={status?._id ?? i} value={status?.name} sx={{ textTransform: 'capitalize' }}>
                                        {status?.name?.toLocaleLowerCase()}
                                    </MenuItem>
                                ))}
                            </RHFSelect>
                        </Grid>

                        <Grid item xs={12} md={6} >
                            <RHFSelect name="district" label="İlçe">
                                {(selectedCity?.districts || [])?.map((status, i) => (
                                    <MenuItem key={status?._id ?? i} value={status} sx={{ textTransform: 'capitalize' }}>
                                        {status.toLocaleLowerCase()}
                                    </MenuItem>
                                ))}
                            </RHFSelect>
                        </Grid>

                        <Grid item xs={12}  >
                            <RHFTextField multiline rows={4} name="description" label="Adres" />
                        </Grid>

                        <Grid item xs={12}  >
                            <GoogleMapComponent setValue={setValue} errors={errors} />
                        </Grid>

                        <Grid item xs={12}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                }}
                            >

                                <Box sx={{ flex: 1 }} />

                                <LoadingButton
                                    type='submit'
                                    disabled={false}
                                    variant='contained'
                                    onClick={() => {

                                    }}
                                >
                                    Kaydet
                                </LoadingButton>

                            </Box>
                        </Grid>

                    </Grid>
                </FormProvider>
            </Card>

        </Container>
    )
}


const GoogleMapComponent = ({ setValue, errors }) => {

    const defaultProps = {
        center: {
            lat: 41.01258256731506,
            lng: 28.997054894224362,
        },
        zoom: 8
    };

    const [defaultValues, setDefaultValues] = useState(defaultProps.center)

    return (
        <>
            <Box
                sx={{
                    height: { xs: '300px', sm: '400px', md: '400px', lg: '400px', xl: '400px' },
                    width: '100%',
                    borderRadius: '12px',
                    overflow: 'hidden',
                    border: errors?.lat?.message ? "3px solid #FF5630" : "",

                }}
            >
                <GoogleMapReact
                    bootstrapURLKeys={{ key: "" }}
                    defaultCenter={defaultProps.center}
                    defaultZoom={defaultProps.zoom}

                    draggable
                    onDrag={(e) => console.log(e)}
                    // onChange={(e) => console.log(e)}
                    onClick={(e) => {
                        console.log(e)
                        setDefaultValues({
                            lat: e.lat,
                            lng: e.lng
                        })
                        setValue("lat", e.lat)
                        setValue("long", e.lng)
                    }}
                    style={{
                        width: '100%',
                        height: '100%',
                        position: 'relative',
                        borderRadius: '8px'
                    }}
                >
                    <AnyReactComponent
                        lat={defaultValues.lat}
                        lng={defaultValues.lng}
                    // text="IYTE"
                    />
                </GoogleMapReact>
            </Box>

            <Typography
                sx={{
                    color: '#FF5630',
                    fontSize: '12px',
                    mt: '5px'
                }}
            >
                {errors?.lat?.message}
            </Typography>
        </>
    );
}


const AnyReactComponent = ({ text = "konum", color = "red" }) => {

    return (
        <Box
            sx={{
                position: 'relative',
                top: '-50px',
                left: '-20px',
                width: '40px',
                height: '40px',
                backgroundColor: color,
                transform: 'rotate(45deg)',
                borderRadius: '50%',
                borderEndEndRadius: '0px',
            }}
        >
            <Box
                sx={{
                    width: '40px',
                    height: '40px',
                    backgroundColor: color,
                    transform: 'rotate(-45deg)',
                    color: '#FFFFFF',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '50%',
                }}
            >
                {text}
            </Box>
        </Box>
    )
};

export default CreateLocations