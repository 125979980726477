/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable arrow-body-style */
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';

import IconButton from '@mui/material/IconButton';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import { Container } from '@mui/material';

import { HophopLogoImage } from '../../../assets';
import { constants } from '../../../constants';


const OnboardingNavbar = () => {

    const handleLogout = () => {
        localStorage.removeItem(constants.localStorage.userLoginData)
        window.location.reload();
    }


    return (
        <Box sx={{ flexGrow: 1, boxShadow: '1px 1px 5px 0px #ccc' }}>
            <AppBar position="static">
                <Container maxWidth="xl">
                    <Toolbar>

                        <Box
                            sx={{
                                // backgroundColor: 'red',
                                // minWidth: '100px',
                                // height: '100px',
                                height: { xs: 'auto', sm: '40px', },
                                maxWidth: '160px',
                                cursor: 'pointer',
                                mr: '20px',
                            }}
                        >
                            <img src={HophopLogoImage} alt="logo" style={{ width: '100%', height: '100%' }} />
                        </Box>
                        <Box
                            sx={{
                                // backgroundColor: 'red',
                                // minWidth: '100px',
                                flex: 1,
                            }}
                        />

                        <IconButton
                            onClick={handleLogout}
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{
                                mr: 2,
                                backgroundColor: '#ffffff33'
                            }}
                        >
                            <LogoutRoundedIcon />
                        </IconButton>

                    </Toolbar>
                </Container>
            </AppBar>
        </Box >
    )
}

export default OnboardingNavbar