/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable react/self-closing-comp */
/* eslint-disable arrow-body-style */

import { styled } from '@mui/material/styles';
// import { useDispatch, useSelector } from "react-redux";
import { useCallback, useState } from "react"
import { Box, Stepper, Step, StepLabel, Container } from '@mui/material';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';

import { BusinessCenterOutlined, Person2Outlined } from '@mui/icons-material';
import OutlinedFlagRoundedIcon from '@mui/icons-material/OutlinedFlagRounded';
import PlayCircleOutlineOutlinedIcon from '@mui/icons-material/PlayCircleOutlineOutlined';

import OrganizerOnboardingFistPage from "./_OrganizerOnboardingFistPage";
import OrganizerOnboardingLastPage from "./_OrganizerOnboardingLastPage";
import OrganizerOnboardingIndividualPage from "./_OrganizerOnboardingIndividualPage";
import OrganizerOnboardingInstituePage from "./_OrganizerOnboardingInstituePage";
import OnboardingNavbar from "../onboardingComponents/_OnboardingNavbar";
// import { graphqlApi } from "../../../api";
// import { reduxFunc } from '../../../redux';



// const steps = ['', '', '', ''];
const steps = ['Başlangıç', 'Kişisel Bilgiler', 'Organizasyon Bilgileri', 'Bitiş'];



const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    transitionDuration: '1s',

    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22,
        transitionDuration: '1s',
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor: '#7DC9CF',
            transitionDuration: '1s',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor: '#7DC9CF',
            transitionDuration: '1s',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderRadius: 1,
        transitionDuration: '1s'
    },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
        backgroundColor: '#7DC9CF',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
        backgroundColor: '#7DC9CF',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',

    }),
    transitionDuration: '1s',
}));

function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
        1: <PlayCircleOutlineOutlinedIcon />,
        2: <Person2Outlined />,
        3: <BusinessCenterOutlined />,
        4: <OutlinedFlagRoundedIcon />,
    };

    return (
        <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
            {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    );
}


const OrganizerOnboardingMainPage = () => {


    // const dispatch = useDispatch()
    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = useState(new Set());
    // const { accessToken, user } = useSelector(state => state.auth)
    const [organizerOnboardingPagesState, setOrganizerOnboardingPageState] = useState("first")
    // console.log({ user })

    // useEffect(() => {
    //     if (accessToken) {
    //         dispatch(
    //             reduxFunc.fetchUserInfoFunc({
    //                 userId: user?.userId,
    //                 accessToken
    //             })
    //         )
    //     }
    // }, [accessToken, dispatch, user?.userId])



    const handleNext = useCallback(() => {
        let newSkipped = skipped;
        if (skipped.has(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    }, [activeStep, skipped]);

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const renderOrganizerOnboardingPages = useCallback(() => {

        switch (organizerOnboardingPagesState) {
            case "first":
                return (
                    <OrganizerOnboardingFistPage
                        setOrganizerOnboardingPageState={setOrganizerOnboardingPageState}
                        handleNext={handleNext}
                    // handleBack={handleBack}
                    />
                )
            case "individual":
                return (
                    <OrganizerOnboardingIndividualPage
                        setOrganizerOnboardingPageState={setOrganizerOnboardingPageState}
                        handleNext={handleNext}
                        handleBack={handleBack}

                    />
                )
            case "institue":
                return (
                    <OrganizerOnboardingInstituePage
                        setOrganizerOnboardingPageState={setOrganizerOnboardingPageState}
                        handleNext={handleNext}
                        handleBack={handleBack}

                    />
                )
            case 'last':
                return (
                    <OrganizerOnboardingLastPage
                        setOrganizerOnboardingPageState={setOrganizerOnboardingPageState}
                        handleNext={handleNext}
                        handleBack={handleBack}

                    />
                )
            default:
                return (
                    <OrganizerOnboardingLastPage
                        setOrganizerOnboardingPageState={setOrganizerOnboardingPageState}
                        handleNext={handleNext}
                        handleBack={handleBack}

                    />
                )
        }
    }, [handleNext, organizerOnboardingPagesState])

    // console.log("organizer onboarding")
    return (
        <Box
            sx={{
                pb: '100px'
            }}
        >

            <OnboardingNavbar />

            {/* <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    minHeight: '100vh',
                    p: { xs: '10px' },
                    mt: { xs: '100px', sm: '150px' }
                }}
            > */}
            <Container
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: { xs: '50px', sm: '100px' },
                    width: '100%',
                    my: { xs: '100px', sm: '150px' }

                }}
            >


                <Stepper
                    activeStep={activeStep}
                    alternativeLabel
                    connector={<ColorlibConnector />}
                    sx={{
                        // display: { xs: 'none', sm: 'flex' },
                        width: '100%',
                        transition: 'all',
                        transitionDuration: '1s'
                    }}
                >
                    {steps.map((label, index) => {
                        return (
                            <Step key={label} sx={{ transitionDuration: '1s' }}>
                                <StepLabel StepIconComponent={ColorlibStepIcon} sx={{ transitionDuration: '1s' }}>
                                    {label}
                                </StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>


                <Box sx={{ width: '100%', }} >
                    {renderOrganizerOnboardingPages()}
                </Box>


            </Container>
            {/* </Box> */}

        </Box>
    )

}

export default OrganizerOnboardingMainPage