/* eslint-disable arrow-body-style */
/* eslint-disable react/prop-types */
/* eslint-disable react/self-closing-comp */
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import GoogleMapReact from 'google-map-react';
import { useEffect, useMemo, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MenuItem from '@mui/material/MenuItem';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import { graphqlApi } from 'src/hophop/api';
import { reduxFunc } from 'src/hophop/redux';
import { useSnackbar } from 'src/components/snackbar';
import { useDispatch, useSelector } from 'react-redux';
import useFetchData from 'src/hophop/hooks/_useFetchData';
import { parkingForLocation } from 'src/hophop/utils/_enums';
import FormProvider, { RHFSelect, RHFTextField } from 'src/components/hook-form';



export default function LocationQuickEditForm({ open, onClose, id }) {

  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar();
  const { handleFetchData } = useFetchData()
  const [selectedCity, setSelectedCity] = useState(null)
  const { accessToken } = useSelector(state => state.auth)
  const { cities } = useSelector(state => state.parameter)
  const { selectedlocationForModerator } = useSelector(state => state.location)


  useEffect(() => {
    if (accessToken) {
      dispatch(reduxFunc.locationFunc.fetchSelectedlocationForModeratorFunc({ accessToken, locationId: id }))
      dispatch(reduxFunc.parameterFunc.fetchParametersFunc({ accessToken }))
    }
  }, [accessToken, dispatch, id])

  const NewBlogSchema = Yup.object().shape({
    "title": Yup.string().required("Zorunlu alan!"),
    "city": Yup.string().required("Zorunlu alan!"),
    "district": Yup.string().required("Zorunlu alan!"),
    "description": Yup.string().required("Zorunlu alan!"),
    "parking": Yup.string().required("Zorunlu alan!"),

    "long": Yup.string().required("Zorunlu alan!"),
    "lat": Yup.string().required("Zorunlu alan!"),
  });

  const defaultValues = useMemo(() => ({
    "title": selectedlocationForModerator?.address?.title ?? "",
    "city": selectedlocationForModerator?.city ?? "",
    "district": selectedlocationForModerator?.district ?? "",
    "description": selectedlocationForModerator?.address?.description ?? "",
    "parking": selectedlocationForModerator?.parking ?? "",

    "long": selectedlocationForModerator?.long ?? "",
    "lat": selectedlocationForModerator?.lat ?? "",

    "country": "Turkiye"
  }), [selectedlocationForModerator]);

  const methods = useForm({
    resolver: yupResolver(NewBlogSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
    setValue,
    watch
  } = methods;
  const values = watch()

  useEffect(() => {
    if (selectedlocationForModerator) {
      reset(defaultValues)
    }
  }, [defaultValues, reset, selectedlocationForModerator])

  useEffect(() => {
    setSelectedCity(cities?.data?.find(item => item?.name === values?.city))
  }, [cities?.data, values?.city])


  const onSubmit = handleSubmit(async (data) => {

    try {
      const response = await handleFetchData({
        data: graphqlApi.activity.mutations.locationsForModerator.updateModeratorLocation({ values, locationId: id }),
        accessToken,
        url: "/activity/graphql",
      })
      console.log({ response })

      if (response?.data?.data?.moderator_updateLocation) {
        enqueueSnackbar("Lokasyon başarılı bir şekilde güncellendi!")
        dispatch(reduxFunc.locationFunc.fetchLocationsForModeratorFunc({ accessToken }))
        onClose()
      } else {
        enqueueSnackbar("Güncelleme yapılamadı!", {
          variant: "error"
        })
      }
    } catch (error) {
      console.log(error)
      enqueueSnackbar("Güncelleme yapılamadı!", {
        variant: "error"
      })
    }

  });

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { maxWidth: 720 },
      }}
    >
      <FormProvider methods={methods} onSubmit={onSubmit}>

        <DialogTitle>Konum Güncelle</DialogTitle>

        <DialogContent>
          <Grid container spacing={3} sx={{ mt: 1 }}>

            <Grid item xs={12} md={6} >
              <RHFTextField name="title" label="Başlık" />
            </Grid>

            <Grid item xs={12} md={6} >
              <RHFSelect name="parking" label="Park Yeri">
                {(parkingForLocation)?.map((status, i) => (
                  <MenuItem key={status?.value ?? i} value={status?.value} sx={{ textTransform: 'capitalize' }}>
                    {status?.name?.toLocaleLowerCase()}
                  </MenuItem>
                ))}
              </RHFSelect>
            </Grid>

            <Grid item xs={12} md={6} >
              <RHFSelect name="city" label="İl">
                {(cities?.data ?? [])?.map((status, i) => (
                  <MenuItem key={status?._id ?? i} value={status?.name} sx={{ textTransform: 'capitalize' }}>
                    {status?.name?.toLocaleLowerCase()}
                  </MenuItem>
                ))}
              </RHFSelect>
            </Grid>

            <Grid item xs={12} md={6} >
              <RHFSelect name="district" label="İlçe">
                {(selectedCity?.districts || [])?.map((status, i) => (
                  <MenuItem key={status?._id ?? i} value={status} sx={{ textTransform: 'capitalize' }}>
                    {status.toLocaleLowerCase()}
                  </MenuItem>
                ))}
              </RHFSelect>
            </Grid>

            <Grid item xs={12}  >
              <RHFTextField multiline rows={4} name="description" label="Adres" />
            </Grid>

            <Grid item xs={12}  >
              {(values?.lat && values?.long) && (<GoogleMapComponent setValue={setValue} values={values} />)}
            </Grid>

          </Grid>
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            İptal
          </Button>

          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            Kaydet
          </LoadingButton>
        </DialogActions>

      </FormProvider>
    </Dialog>
  );
}


LocationQuickEditForm.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};


const GoogleMapComponent = ({ setValue, values }) => {

  const defaultProps = {
    lat: values.lat,// ?? 41.01258256731506,
    lng: values?.long,// ?? 28.997054894224362,
  };

  const [defaultValues, setDefaultValues] = useState(defaultProps)

  useEffect(() => {
    setDefaultValues({
      lat: values.lat,
      lng: values?.long,
    })
  }, [values.lat, values?.long])



  return (
    <Box
      sx={{
        height: { xs: '300px', sm: '400px', md: '400px', lg: '400px', xl: '400px' },
        width: '100%',
        borderRadius: '12px',
        overflow: 'hidden'
      }}
    >
      <GoogleMapReact
        bootstrapURLKeys={{ key: "" }}
        defaultCenter={defaultProps}
        center={defaultProps}
        defaultZoom={8}
        draggable
        onDrag={(e) => console.log(e)}
        onClick={(e) => {
          setDefaultValues({
            lat: e.lat,
            lng: e.lng
          })
          setValue("lat", e.lat)
          setValue("long", e.lng)
        }}
        // eslint-disable-next-line react/jsx-no-bind
        // onChange={handleResetLocation}

        style={{
          width: '100%',
          height: '100%',
          position: 'relative',
          borderRadius: '8px'
        }}
      >
        <AnyReactComponent
          lat={defaultValues.lat}
          lng={defaultValues.lng}
        />
      </GoogleMapReact>
    </Box>
  );
}


const AnyReactComponent = ({ text = "konum", color = "red" }) => {

  return (
    <Box
      sx={{
        position: 'relative',
        top: '-50px',
        left: '-20px',
        width: '40px',
        height: '40px',
        backgroundColor: color,
        transform: 'rotate(45deg)',
        borderRadius: '50%',
        borderEndEndRadius: '0px',
      }}
    >
      <Box
        sx={{
          width: '40px',
          height: '40px',
          backgroundColor: color,
          transform: 'rotate(-45deg)',
          color: '#FFFFFF',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '50%',
          fontSize: 10,
        }}
      >
        {text}
      </Box>
    </Box>
  )
};