import PropTypes from 'prop-types';
// @mui
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// components
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { parkingForLocation } from 'src/hophop/utils/_enums';
//

// ----------------------------------------------------------------------

export default function LocationTableRow({ row, selected, onApproveRow, onRejectRow }) {

  const approve = useBoolean();
  const reject = useBoolean();
  const quickEdit = useBoolean();
  const popover = usePopover();

  return (
    <>
      <TableRow hover selected={selected}>
        {/* <TableCell padding="checkbox">
          <Checkbox checked={selected} onClick={onSelectRow} />
        </TableCell> */}

        <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
          <Avatar alt={row.address.title} src={row.createdBy.displayImageUrl} sx={{ mr: 2 }} />

          <ListItemText
            primary={row?.address?.title}
            secondary={row?.address?.description}
            primaryTypographyProps={{ typography: 'body2' }}
            secondaryTypographyProps={{ component: 'span', color: 'text.disabled' }}
          />
        </TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>{row?.city}</TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>{row?.district}</TableCell>

        {/* <TableCell sx={{ whiteSpace: 'nowrap' }}>{role}</TableCell> */}

        <TableCell>
          <Label
            variant="soft"
            color={
              (row?.parking === 'exists' && 'success') ||
              (row?.parking === 'limitid' && 'warning') ||
              (row?.parking === 'nonexist' && 'error') ||
              'default'
            }
          >
            {parkingForLocation?.find(item => item?.value === row?.parking)?.name}
          </Label>
        </TableCell>

        {row?.status !== "approved" && (<TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
          <Tooltip title="Onayla" placement="top" arrow>
            <IconButton color={quickEdit.value ? 'inherit' : 'default'} onClick={approve.onTrue}>
              <Iconify icon="typcn:tick" />
            </IconButton>
          </Tooltip>

          <Tooltip title="Onayla" placement="top" arrow>
            <IconButton color={popover.open ? 'inherit' : 'default'} onClick={reject.onTrue} sx={{ color: 'error.main' }}>
              <Iconify icon="entypo:cross" />
            </IconButton>
          </Tooltip>
          {/* <IconButton color={popover.open ? 'inherit' : 'default'} onClick={popover.onOpen}>
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton> */}
        </TableCell>)}

      </TableRow>



      <ConfirmDialog
        open={approve.value}
        onClose={approve.onFalse}
        title="Konum onayla"
        content="Konumu onaylamak istediğinize eminmisiniz?"

        action={
          <Button variant="contained" color="success" onClick={() => {
            onApproveRow(row?._id)
            approve.onFalse()
          }}
          >
            Onayla
          </Button>
        }
      />

      <ConfirmDialog
        open={reject.value}
        onClose={reject.onFalse}
        title="Konum Reddet"
        content="Konumu reddetmek istediğinize eminmisiniz?"

        action={
          <Button variant="contained" color="error" onClick={() => {
            onRejectRow(row?._id)
            reject.onFalse()
          }}
          >
            Reddet
          </Button>
        }
      />
    </>
  );
}

LocationTableRow.propTypes = {
  onApproveRow: PropTypes.func,
  onRejectRow: PropTypes.func,
  row: PropTypes.object,
  selected: PropTypes.bool,
};
