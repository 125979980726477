/* eslint-disable react/no-unescaped-entities */
import * as Yup from 'yup';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import { useForm } from 'react-hook-form';
import { enqueueSnackbar } from 'notistack';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import { yupResolver } from '@hookform/resolvers/yup';
import InputAdornment from '@mui/material/InputAdornment';

import Iconify from 'src/components/iconify';
import { useBoolean } from 'src/hooks/use-boolean';
import { RouterLink } from 'src/routes/components';
import useFetchData from 'src/hophop/hooks/_useFetchData';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
// import { reduxFunc } from 'src/hophop/redux';

// ----------------------------------------------------------------------

export default function ClassicRegisterView() {

  const password = useBoolean();
  // const dispatch = useDispatch()
  const navigate = useNavigate()
  const { handleFetchData } = useFetchData()

  const RegisterSchema = Yup.object().shape({
    email: Yup.string().required('Gerekli').email('Geçerli bir email adresi giriniz'),
    password: Yup.string().required('Gerekli')
      .matches(/^(?=.*[a-z])/, 'En az 1 küçük karakter olmalı')
      .matches(/^(?=.*[A-Z])/, 'En az bir büyük karakter olmalı')
      .matches(/^(?=.*[0-9])/, 'En az bir numara içermeli')
      .matches(/^(?=.*[!@#%&?.,+%-_])/, 'En az bir özel karakter içermeli')
      .min(8, "En az 8 karakter içermeli"),
    passwordConfirm: Yup.string().oneOf([Yup.ref('password'), null], 'Şifreler eşleşmeli!'),
  });

  const defaultValues = {
    email: '',
    password: '',
    passwordConfirm: '',
  };

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
    reset,
  } = methods;

  const onSubmit = handleSubmit(async (data) => {

    console.log({ data })
    try {

      const response = await handleFetchData({
        data: JSON.stringify({
          email: data.email,
          password: data.password,
          role: "organizer"
        }),
        url: "/auth/auth/register"
      })

      console.log({ response })

      if (response?.response?.data?.errors?.length > 0) {

        // response?.response?.data?.errors?.map(item => {
        //   enqueueSnackbar(item?.msg, {
        //     variant: 'error'
        //   })
        //   return item
        // })

        enqueueSnackbar("Başka bir email deneyiniz ya da daha sonra tekrar deneyiniz!", {
          variant: 'error'
        })
        // navigate("/auth")

      }

      if (response?.data?.isSuccess) {
        enqueueSnackbar("Kayıt başarılı!")
        // navigate("/auth/verify-email")
        navigate("/auth")
        // dispatch(reduxFunc.reduxLoginFunc(data))
      }

    } catch (error) {
      console.error(error);
      reset();
      navigate("/auth")


    }
  });

  const renderHead = (
    <Stack spacing={2} sx={{ mb: 5, position: 'relative' }}>
      <Typography variant="h4">Yeni bir hesap oluştur</Typography>

      <Stack direction="row" spacing={0.5}>
        <Typography variant="body2"> Zaten hesabın var mı? </Typography>

        <Link href="/auth/login" component={RouterLink} variant="subtitle2">
          Giriş Yap
        </Link>
      </Stack>
    </Stack>
  );

  const renderTerms = (
    <Typography
      component="div"
      sx={{ color: 'text.secondary', mt: 2.5, typography: 'caption', textAlign: 'center' }}
    >
      <Link underline="always" color="text.primary">
        Kullanım Koşulları
      </Link>
      {' ve '}
      <Link underline="always" color="text.primary">
        Gizlilik Politikası'nı
      </Link>
      {'Kabul ediyorum. '}
      .
    </Typography>
  );

  const renderForm = (
    <Stack spacing={2.5}>

      <RHFTextField name="email" label="Email adresi" />

      <RHFTextField
        name="password"
        label="Şifre"
        type={password.value ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={password.onToggle} edge="end">
                <Iconify icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <RHFTextField
        name="passwordConfirm"
        label="Şifre onayla"
        type={password.value ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={password.onToggle} edge="end">
                <Iconify icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <LoadingButton
        fullWidth
        color="inherit"
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting}
      >
        Kayıt Ol
      </LoadingButton>
    </Stack>
  );

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      {renderHead}

      {renderForm}

      {renderTerms}
    </FormProvider>
  );
}
