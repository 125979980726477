const userQuery = ({ userId }) => {

  const data = JSON.stringify({
    query: `
        query User($userId: ID!) {
            user(userId: $userId) {
              _id
              onboarded
              role
              personalInformation {
                fullName
                phoneNumber
                profileImageUrl
                description
                childAge
                identificationNumber
                organization
                taxOffice
                taxNo
              }
              contact {
                phoneNumber
                phoneNumberVerified
                email
                emailVerified
                address
              }
              rating {
                average
                numberOfReviews
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
          }
        `,
    variables: {
      "userId": userId
    }
  })

  return data
}


const allUsersQuery = ({ userId }) => {

  const data = JSON.stringify({
    query: `
        query Users {
            users {
              count
              data {
                _id
                onboarded
                role
                personalInformation {
                  fullName
                  phoneNumber
                  profileImageUrl
                  description
                  childAge
                  identificationNumber
                  organization
                  taxOffice
                  taxNo
                }
                contact {
                  phoneNumber
                  phoneNumberVerified
                  email
                  emailVerified
                  address
                }
                rating {
                  average
                  numberOfReviews
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
            }
          }
        `,
    variables: {}
  })

  return data
}


export const userQueries = {
  userQuery,
  allUsersQuery,
}
