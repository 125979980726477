import axios from 'axios'
// eslint-disable-next-line no-unused-vars
import React, { useState } from 'react'

const useFetchData = () => {

    const [data, setData] = useState(null)
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)

    // eslint-disable-next-line no-shadow
    const handleFetchData = async ({ data, url = "/activity/graphql", method = 'post', accessToken = "" }) => {
        // console.log({ data, url, accessToken })
        setLoading(true)
        try {
            const config = {
                method,
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_BASE_URL}${url}`,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                },
                data,
            };

            console.log({ config })

            const responseData = await axios(config)
            setData(responseData)
            setLoading(false)
            setError(null)
            return responseData

        } catch (err) {
            setLoading(false)
            setError(err)
            setData(null)
            return err
        }
    }


    return ({
        data,
        error,
        loading,
        handleFetchData,
    })
}

export default useFetchData