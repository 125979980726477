import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

// eslint-disable-next-line react/prop-types, arrow-body-style
const RouteGuard = ({ children, isAuth, redirectPath }) => {
    // console.log({ isAuth, redirectPath })
    return isAuth ? children : <Navigate to={redirectPath} />
    // return isAuth ? <Outlet /> : <Navigate to={redirectPath} />
}

export default RouteGuard