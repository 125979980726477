const makeActive = ({ activityId }) => {

  const data = JSON.stringify({
    query: `
    mutation Moderator_setActivityAsActive($activityId: String!) {
      moderator_setActivityAsActive(activityId: $activityId) {
        success
      }
    }
              `,
    variables: {
      "activityId": activityId
    }
  })

  return data

}


const makePassive = ({ activityId }) => {

  const data = JSON.stringify({
    query: `
    mutation Moderator_setActivityAsPassive($activityId: String!) {
      moderator_setActivityAsPassive(activityId: $activityId) {
        success
      }
    }
              `,
    variables: {
      "activityId": activityId
    }
  })

  return data

}


const createActivity = ({ values }) => {

  const data = JSON.stringify({
    query: `
    mutation Moderator_createActivity($activityInput: ActivityByModeratorInput!) {
      moderator_createActivity(activityInput: $activityInput) {
        _id
      }
    }
              `,
    variables: {
      "activityInput": {
        "additionalDetails": values?.additionalDetails,
        "duration": values?.duration,
        "fee": values?.fee,
        "hoursBeforeReservationExpires": values?.hoursBeforeReservationExpires,
        "listingId": values?.listingId,
        "locationId": values?.locationId,
        "price": Number(values?.price),
        "quota": values?.quota,
        "schedule": {
          "days": values?.schedule?.days,
          "endDate": values?.schedule?.endDate,
          "repeatedIntervalType": values?.schedule?.repeatedIntervalType,
          "startDate": values?.schedule?.startDate,
          "totalDayCount": values?.schedule?.totalDayCount
        }
      }
    }

  })

  return data

}


const removeActivity = ({ activityId }) => {

  const data = JSON.stringify({
    query: `
    mutation Moderator_removeActivity($activityId: String!) {
      moderator_removeActivity(activityId: $activityId) {
        success
      }
    }
    `,
    variables: {
      "activityId": activityId
    }
  })

  return data

}


const updateActivity = ({ activityId, values }) => {

  const data = JSON.stringify({
    query: `
    mutation Moderator_updateActivity($activityId: String!, $updateInput: UpdateActivityByModeratorInput!) {
      moderator_updateActivity(activityId: $activityId, updateInput: $updateInput) {
        _id
      }
    }
              `,
    variables: {
      "activityId": activityId,
      "updateInput": {
        "additionalDetails": values?.additionalDetails ?? "",
        "duration": values?.duration ?? "",
        "fee": values?.fee ?? "",
        "hoursBeforeReservationExpires": values?.hoursBeforeReservationExpires ?? "",
        "price": values?.price ?? "",
        "quota": values?.quota ?? "",
        "schedule": {
          "days": values?.schedule?.days ?? "",
          "endDate": values?.schedule?.endDate ?? "",
          "repeatedIntervalType": values?.schedule?.repeatedIntervalType ?? "",
          "startDate": values?.schedule?.startDate ?? "",
          "totalDayCount": values?.schedule?.totalDayCount ?? ""
        }
      }
    }

  })

  return data

}




export const activitiesForOrganizer = {
  createActivity,
  removeActivity,
  updateActivity,

  makeActive,
  makePassive,
}