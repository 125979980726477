const approveListing = ({ listingId }) => {

  const data = JSON.stringify({
    query: `
        mutation ApproveListingByModerator($listingId: String!) {
          approveListingByModerator(listingId: $listingId) {
            _id
          }
        }
              `,
    variables: {
      "listingId": listingId
    }
  })

  return data

}


const rejectListing = ({ listingId }) => {

  const data = JSON.stringify({
    query: `
    mutation RejectListingByModerator($listingId: String!) {
      rejectListingByModerator(listingId: $listingId) {
        _id
      }
    }
              `,
    variables: {
      "listingId": listingId
    }
  })

  return data

}


const createListing = ({ values, images, coverImage }) => {

  const data = JSON.stringify({
    query: `
    mutation Moderator_createListing($listingInput: ListingByModeratorInput!) {
      moderator_createListing(listingInput: $listingInput) {
        _id
      }
    }
              `,
    variables: {
      "listingInput": {
        "ageInterval": values?.ageInterval ?? "",
        "category": values?.category ?? "",
        "companionship": values?.companionship ?? "",
        "coverImage": coverImage ?? "",
        "description": values?.description ?? "",
        "format": values?.format ?? "",
        "images": images ?? [],
        "title": values?.title ?? "",

        "subCategory": values?.subCategory ?? "",
        "type": values?.type ?? ""
      }
    }
  })

  return data

}


const removeListing = ({ listingId }) => {

  const data = JSON.stringify({
    query: `
    mutation Moderator_removeListing($listingId: String!) {
      moderator_removeListing(listingId: $listingId) {
        success
      }
    }
              `,
    variables: {
      "listingId": listingId
    }
  })

  return data

}


const updateModeratorListing = ({ listingId, values, images, coverImage }) => {

  console.log({ listingId, values, images })

  const data = JSON.stringify({
    query: `
    mutation Moderator_updateModeratorListing($listingId: String!, $updateInput: UpdateModeratorListingByModeratorInput!) {
      moderator_updateModeratorListing(listingId: $listingId, updateInput: $updateInput) {
        success
      }
    }
              `,
    variables: {
      "listingId": listingId,
      "updateInput": {
        "ageInterval": values?.ageInterval ?? null,
        "category": values?.category ?? null,
        "companionship": values?.companionship ?? null,
        "coverImage": coverImage ?? null,
        "description": values?.description ?? null,
        "format": values?.format ?? null,
        "images": images ?? [],
        "subCategory": values?.subCategory ?? null,
        "title": values?.title ?? null,
        // "type": values?.type ?? null
      }
    }
  })

  return data

}


const updateOrganizatorListing = ({ listingId, values }) => {

  const data = JSON.stringify({
    query: `
    mutation UpdateOrganizerListingByModerator($listingId: String!, $updateListingInput: UpdateOrganizerListingByModeratorInput!) {
      updateOrganizerListingByModerator(listingId: $listingId, updateListingInput: $updateListingInput) {
        _id
      }
    }
              `,
    variables: {
      "listingId": listingId,
      "updateListingInput": {
        "ageInterval": values?.ageInterval ?? null,
        "category": values?.category ?? null,
        "companionship": values?.companionship ?? null,
        // "coverImage": values?.coverImage ?? null,
        "description": values?.description ?? null,
        "format": values?.format ?? null,
        "images": values?.images ?? null,
        "subCategory": values?.subCategory ?? null,
        "title": values?.title ?? null,
        // "type": values?.type ?? null,

      }
    }
  })

  return data

}


export const listingsForModerator = {
  approveListing,
  rejectListing,
  createListing,
  removeListing,
  updateModeratorListing,
  updateOrganizatorListing,
}