const activityDetail = () => {

  const data = JSON.stringify({
    query: `
    query Organizer_activities($filter: FilterActivityByOrganizerInput, $pagination: Pagination) {
      organizer_activities(filter: $filter, pagination: $pagination) {
        count
        data {
          _id
          additionalDetails
          createdAt
          createdBy {
            _id
            displayName
            displayImageUrl
            description
            role
          }
          duration
          fee
          hoursBeforeReservationExpires
          listing {
            _id
            title
            category
            subCategory
            ageInterval
            format
            description
            images
            coverImage
            companionship
          }
          location {
            _id
            city
            district
            country
            address {
              title
              description
            }
            lat
            long
            parking
          }
          price
          quota
          schedule {
            _id
            repeatedIntervalType
            startDate
            endDate
            totalDayCount
            createdAt
            updatedAt
          }
          status
          updatedAt
          updatedBy {
            _id
            displayName
            displayImageUrl
            description
            role
          }
        }
      }
    }
              `,
    variables: {
      "activityId": null
    }
  })

  return data

}

const allActivities = () => {

  const data = JSON.stringify({
    query: `
    query Organizer_activities($filter: FilterActivityByOrganizerInput, $pagination: Pagination) {
      organizer_activities(filter: $filter, pagination: $pagination) {
        count
        data {
          _id
          additionalDetails
          createdAt
          createdBy {
            _id
            displayName
            displayImageUrl
            description
            role
          }
          duration
          fee
          hoursBeforeReservationExpires
          listing {
            _id
            title
            category
            subCategory
            ageInterval
            format
            description
            images
            coverImage
            companionship
          }
          location {
            _id
            city
            district
            country
            address {
              title
              description
            }
            lat
            long
            parking
          }
          price
          quota
          schedule {
            _id
            repeatedIntervalType
            startDate
            endDate
            totalDayCount
            createdAt
            updatedAt
          }
          status
          updatedAt
          updatedBy {
            _id
            displayName
            displayImageUrl
            description
            role
          }
        }
      }
    }
              `,
    variables: {
      "filter": {
        "additionalDetails": null,
        "duration": {
          "from": null,
          "to": null
        },
        "fee": null,
        "listing": {
          "ageInterval": null,
          "category": null,
          "companionship": null,
          "description": null,
          "format": null,
          "title": null
        },
        "location": {
          // "byCoordinates": {
          //    "long": null,
          //    "lat": null,
          //    "radius": null
          // },
          "city": null,
          "district": null,
          "parking": null
        },
        "price": {
          "from": null,
          "to": null
        },
        "quota": {
          "from": null,
          "to": null
        },
        "schedule": {
          "dateInterval": {
            "to": null,
            "from": null
          },
          "days": null
        },
        "status": null
      },
      "pagination": {
        "page": null,
        "pageSize": null
      }
    }
  })

  return data

}


export const activitiesForOrganizer = {
  activityDetail,
  allActivities,
}