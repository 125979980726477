/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/self-closing-comp */
/* eslint-disable arrow-body-style */
/* eslint-disable no-constant-condition */
/* eslint-disable react/prop-types */


import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { useParams } from 'react-router';
import { styled } from '@mui/material/styles';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { Box, Grid, Stepper, Step, StepLabel, MenuItem, Typography, Stack, IconButton, Card, Container, Tooltip, Dialog, DialogTitle, DialogContent, DialogActions, Button, ButtonBase, Chip, Skeleton } from '@mui/material';

import CelebrationIcon from '@mui/icons-material/Celebration';
import ThumbUpRoundedIcon from '@mui/icons-material/ThumbUpRounded';
import { BusinessCenterOutlined, Person2Outlined } from '@mui/icons-material';
import OutlinedFlagRoundedIcon from '@mui/icons-material/OutlinedFlagRounded';
import PlayCircleOutlineOutlinedIcon from '@mui/icons-material/PlayCircleOutlineOutlined';

import { graphqlApi } from 'src/hophop/api';
import { reduxFunc } from 'src/hophop/redux';
import { useBoolean } from 'src/hooks/use-boolean';
import Iconify from 'src/components/iconify/iconify';
import { useSnackbar } from 'src/components/snackbar';
import useFetchData from 'src/hophop/hooks/_useFetchData';
import { useSettingsContext } from 'src/components/settings';
import FormProvider from 'src/components/hook-form/form-provider';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';
import { RHFAutocomplete, RHFCheckbox, RHFSelect, RHFTextField } from 'src/components/hook-form';
import { dayMapping, hoursBeforeReservationExpiresList, repeatIntervalTypes, timeMapping, } from 'src/hophop/utils/_enums';

import CreateActivityCard from './_CreateActivityCard';


const steps = ['Başlangıç', 'Temel Bilgiler', 'Detay Bilgiler', 'Bitiş'];


const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    transitionDuration: '1s',

    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22,
        transitionDuration: '1s',
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor: '#7DC9CF',
            transitionDuration: '1s',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor: '#7DC9CF',
            transitionDuration: '1s',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderRadius: 1,
        transitionDuration: '1s'
    },
}));


const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
        backgroundColor: '#7DC9CF',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
        backgroundColor: '#7DC9CF',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',

    }),
    transitionDuration: '1s',
}));


function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
        1: <PlayCircleOutlineOutlinedIcon />,
        2: <Person2Outlined />,
        3: <BusinessCenterOutlined />,
        4: <OutlinedFlagRoundedIcon />,
    };

    return (
        <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
            {icons[String(props?.icon)]}
        </ColorlibStepIconRoot>
    );
}



function getDates(startDate, stopDate, interval = 1) {
    const dateArray = [];
    const currentDate = new Date(startDate);
    console.log({ interval })

    while (currentDate <= stopDate) {

        if (interval === "monthly") {
            dateArray.push(new Date(currentDate));

            console.log({ currentDate, stopDate })
            currentDate.setMonth(currentDate.getMonth() + 1)
        } else if (typeof interval === "object") {

            console.log(currentDate?.getDay(), interval.includes(currentDate?.getDay()))
            if (interval.includes(currentDate?.getDay())) {
                dateArray.push(new Date(currentDate.getTime()));
            }
            currentDate.setDate(currentDate.getDate() + 1);

        } else {
            dateArray.push(new Date(currentDate));
            currentDate.setDate(currentDate.getDate() + interval);
        }

    }

    return dateArray;
}

const CreateActivity = () => {

    const { id } = useParams()
    const dispatch = useDispatch()
    const settings = useSettingsContext();
    const addOrUpdateDatePopup = useBoolean();
    const { enqueueSnackbar } = useSnackbar();
    const { handleFetchData } = useFetchData()
    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = useState(new Set());
    const { accessToken } = useSelector(state => state?.auth)
    const { selectedActivityForModerator } = useSelector(state => state?.activity)

    useEffect(() => {
        if (accessToken) {
            dispatch(reduxFunc.parameterFunc.fetchCreateActivityParametersForModeratorFunc({ accessToken }))
        }
    }, [accessToken, dispatch])

    useEffect(() => {
        if (accessToken) {
            if (id) {
                dispatch(reduxFunc.activityFunc.fetchSelectedActivityForModeratorFunc({ accessToken, activityId: id }))
            } else (
                dispatch(reduxFunc.activityFunc.resetSelectedActivity())
            )

            if (!id) {
                dispatch(reduxFunc.listingFunc.resetSelectedListing())
            }
        }
    }, [accessToken, dispatch, id])

    useEffect(() => {
        if (selectedActivityForModerator) {
            dispatch(reduxFunc.listingFunc.fetchSelectedListingForModeratorFunc({ accessToken, listingId: selectedActivityForModerator?.listing?._id }))
            dispatch(reduxFunc.locationFunc.fetchLocationsForModeratorFunc({ accessToken, listingId: selectedActivityForModerator?.location?._id }))
        }
    }, [accessToken, dispatch, selectedActivityForModerator])


    const handleNext = useCallback(() => {
        let newSkipped = skipped;
        if (skipped.has(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    }, [activeStep, skipped]);

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };



    const NewActivitySchema = Yup.object().shape({
        "additionalDetails": Yup.string(""),
        "duration": Yup.string("").required("Gerekli!"), // int
        "fee": Yup.string("").required("Gerekli!"), // free | paid
        "hoursBeforeReservationExpires": Yup.string("").required("Gerekli!"), // int
        "listingId": Yup.string("").required("Gerekli!"),
        "locationId": Yup.string("").required("Gerekli!"),
        "price": Yup.string("").required("Gerekli!"), // int
        "quota": Yup.string("").required("Gerekli!"), // int

        "schedule": Yup.object({
            "isRepetetive": Yup.boolean(), // **** bu backende gitmeyecek, sadece front için ****
            "repeatedIntervalType": Yup.string("").required("Gerekli!"), // once | daily | weekly | monthly  
            "totalDayCount": Yup.string("").required("Gerekli!"), // int // bu kullanıcıya gösterme frontta arka planda hesapla
            "days": Yup.array(""),

            "startDate": null, // data input
            "endDate": null, // data input
            "day": Yup.array(""),
            "startTime": Yup.string("").required("Gerekli!"),
            "endTime": Yup.string("").required("Gerekli!"),
        })
    });

    // console.log({ selectedActivityForModerator })

    const defaultValues = useMemo(() => ({

        "additionalDetails": selectedActivityForModerator?.additionalDetails ?? "",
        "duration": selectedActivityForModerator?.duration ?? "", // int
        "fee": selectedActivityForModerator?.fee ?? "", // free | paid
        "hoursBeforeReservationExpires": selectedActivityForModerator?.hoursBeforeReservationExpires ?? "", // int
        "listingId": selectedActivityForModerator?.listing?._id ?? "",
        "locationId": selectedActivityForModerator?.location?._id ?? "",
        "price": selectedActivityForModerator?.price ?? "", // int
        "quota": selectedActivityForModerator?.quota ?? "", // int

        "schedule": {
            "isRepetetive": selectedActivityForModerator?.schedule?.totalDayCount > 1, // **** bu backende gitmeyecek, sadece front için ****
            "repeatedIntervalType": selectedActivityForModerator?.schedule?.repeatedIntervalType ?? "once", // once | daily | weekly | monthly  
            "totalDayCount": selectedActivityForModerator?.schedule?.totalDayCount ?? "", // int // bu kullanıcıya gösterme frontta arka planda hesapla
            "days": selectedActivityForModerator?.schedule?.days ?? [
                // {
                //     "date": null,
                //     "day": null,
                //     "startTime": null
                //     "endTime": null,
                // }
            ],

            "startDate": selectedActivityForModerator ? new Date(selectedActivityForModerator?.schedule?.startDate) : null, // data input
            "endDate": selectedActivityForModerator ? new Date(selectedActivityForModerator?.schedule?.endDate) : null, // data input
            "day": [],
            "startTime": selectedActivityForModerator?.schedule?.[0]?.startTime ?? "",
            "endTime": selectedActivityForModerator?.schedule?.[0]?.endTime ?? "",
        }


    }), [selectedActivityForModerator])

    const methods = useForm({
        resolver: yupResolver(NewActivitySchema),
        defaultValues,
        mode: "all",
    });

    const {
        watch,
        handleSubmit,
        formState,
        trigger,
        setValue,
        control,
        reset
    } = methods;

    const {
        isValid,
        errors,
        touchedFields,
    } = formState
    const values = watch();

    useEffect(() => {
        if (id) {
            reset(defaultValues)
        } else {
            reset({
                "additionalDetails": "",
                "duration": "", // int
                "fee": "", // free | paid
                "hoursBeforeReservationExpires": "", // int
                "listingId": "",
                "locationId": "",
                "price": "", // int
                "quota": "", // int

                "schedule": {
                    "isRepetetive": false, // **** bu backende gitmeyecek, sadece front için ****
                    "repeatedIntervalType": "once", // once | daily | weekly | monthly  
                    "totalDayCount": "", // int // bu kullanıcıya gösterme frontta arka planda hesapla
                    "days": [],

                    "startDate": null, // data input
                    "endDate": null, // data input
                    "day": [],
                    "startTime": "",
                    "endTime": "",
                }

            })
        }
    }, [defaultValues, reset, id])


    useEffect(() => {
        if (!values?.schedule?.isRepetetive) {
            setValue("schedule.repeatedIntervalType", "once")
            setValue("schedule.totalDayCount", 1)
            setValue("schedule.onceDay", 1)
        } else {
            setValue("schedule.repeatedIntervalType", values?.schedule?.repeatedIntervalType === "once" ? "" : values?.schedule?.repeatedIntervalType)
            setValue("schedule.totalDayCount", values?.schedule?.days?.length)
            setValue("schedule.onceDay", "")
        }
    }, [setValue, values?.schedule?.days?.length, values?.schedule?.isRepetetive, values?.schedule?.repeatedIntervalType])

    useEffect(() => {
        if (!id) {

            if (values?.schedule?.repeatedIntervalType === "once") {

                if (values?.schedule?.startDate) {
                    setValue("schedule.days", [
                        {
                            "date": values?.schedule?.startDate, // date 
                            "day": new Date(values?.schedule?.startDate).getDay(), // int
                            "startTime": values?.schedule?.startTime, // int
                            "endTime": values?.schedule?.endTime,  // int
                        }
                    ])
                    // setValue("schedule.endDate", values?.schedule.startDate)
                }

            } else if (values?.schedule?.repeatedIntervalType === "daily") {

                if (values?.schedule?.startDate) {

                    const dates = getDates(values?.schedule?.startDate, values?.schedule?.endDate, 1)
                    setValue("schedule.days", dates?.map(item => ({
                        "date": item, // date 
                        "day": new Date(item).getDay(), // int
                        "startTime": values?.schedule?.startTime, // int
                        "endTime": values?.schedule?.endTime,  // int
                    })))
                }

            } else if (values?.schedule?.repeatedIntervalType === "weekly") {

                const dates = getDates(values?.schedule?.startDate, values?.schedule?.endDate, values?.schedule?.day?.map(item => item?.value))
                setValue("schedule.days", dates?.map(item => ({
                    "date": item, // date 
                    "day": new Date(item).getDay(), // int
                    "startTime": values?.schedule?.startTime, // int
                    "endTime": values?.schedule?.endTime,  // int
                })))

            } else if (values?.schedule?.repeatedIntervalType === "monthly") {
                const dates = getDates(values?.schedule?.startDate, values?.schedule?.endDate, "monthly")
                setValue("schedule.days", dates?.map(item => ({
                    "date": item, // date 
                    "day": new Date(item).getDay(), // int
                    "startTime": values?.schedule?.startTime, // int
                    "endTime": values?.schedule?.endTime,  // int
                })))
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        setValue,
        values?.schedule?.repeatedIntervalType,
        values?.schedule?.day,
        values?.schedule?.startDate,
        values?.schedule?.endDate,
        values?.schedule?.startTime,
        values?.schedule?.endTime,
    ])

    useEffect(() => {
        if (values.fee === "free") {
            setValue("price", "0")
        }
    }, [setValue, values.fee])

    useEffect(() => {
        if (!id) {
            if (values?.schedule?.repeatedIntervalType === "once") {
                setValue("schedule.endDate", values?.schedule.startDate)
            }
        }
    }, [id, setValue, values.fee, values?.schedule?.repeatedIntervalType, values?.schedule.startDate])


    // console.log({ values, errors })

    const onSubmit = async () => {

        console.log(values)

        if (values?.schedule?.repeatedIntervalType === "once") {
            // console.log(values?.schedule)
        } else if (values?.schedule?.repeatedIntervalType === "daily") {
            console.log(values?.schedule)
        } else if (values?.schedule?.repeatedIntervalType === "weekly") {
            console.log(values?.schedule)
        } else if (values?.schedule?.repeatedIntervalType === "monthly") {
            console.log(values?.schedule)
        }



        if (id) {
            try {
                const response = await handleFetchData({
                    data: graphqlApi.activity.mutations.activitiesForModerator.updateActivity({
                        values,
                        activityId: id
                    }),
                    url: "/activity/graphql",
                    accessToken,
                });
                console.log({ response })

                if (response?.data?.data?.moderator_updateActivity?._id) {
                    enqueueSnackbar('Güncelleme başarılı!');
                    handleNext()
                }

                if (response?.data?.errors?.length) {
                    response?.data?.errors?.map(item => {
                        enqueueSnackbar(item?.message, {
                            variant: 'error'
                        });
                        return null
                    })
                }

                if (response?.response?.data?.errors?.length) {
                    response?.response?.data?.errors?.map(item => {
                        enqueueSnackbar(item?.message, {
                            variant: 'error'
                        });
                        return null
                    })
                }

            } catch (error) {
                console.error(error);
                enqueueSnackbar('Kayıt başarısız!', {
                    variant: 'error'
                });

            }
        } else {
            try {
                const response = await handleFetchData({
                    data: graphqlApi.activity.mutations.activitiesForModerator.createActivity({
                        values,
                    }),
                    url: "/activity/graphql",
                    accessToken,
                });
                console.log({ response })

                if (response?.data?.data?.moderator_createActivity?._id) {
                    enqueueSnackbar('Kayıt başarılı!');
                    handleNext()
                }

                if (response?.data?.errors?.length) {
                    response?.data?.errors?.map(item => {
                        enqueueSnackbar(item?.message, {
                            variant: 'error'
                        });
                        return null
                    })
                }

                if (response?.response?.data?.errors?.length) {
                    response?.response?.data?.errors?.map(item => {
                        enqueueSnackbar(item?.message, {
                            variant: 'error'
                        });
                        return null
                    })
                }

            } catch (error) {
                console.error(error);
                enqueueSnackbar('Kayıt başarısız!', {
                    variant: 'error'
                });

            }
        }

    };


    const [selectedDate, setSelectedDate] = useState(null)
    const handleRemoveFromDate = (date) => {
        console.log({ date })
        const filteredDates = values?.schedule?.days?.filter(item => item?.date !== date)
        console.log({ filteredDates })
        setValue("schedule.days", filteredDates)
    }

    const handleEditDate = (date) => {
        addOrUpdateDatePopup.onTrue()
        setSelectedDate(date)
    }

    const handleAddDate = () => {
        setSelectedDate(null)
        addOrUpdateDatePopup.onTrue()
    }

    const handleAddOrUpdateDate = (data) => {
        console.log({ data })
        if (selectedDate) {
            const newDates = values?.schedule?.days?.map(item => {
                console.log(item?.date === selectedDate?.date ? data : "false")
                return item?.date === selectedDate?.date ? data : item
            })
            console.log({ newDates })
            setValue("schedule.days", newDates)
        } else {
            // const newDates = values?.schedule?.days?.map(item => {
            //     console.log(item?.date === selectedDate?.date ? data : "false")
            //     return item?.date === selectedDate?.date ? data : item
            // })
            // console.log({ newDates })
            const newDates = [...(values?.schedule?.days ?? []), data]
            setValue("schedule.days", newDates)
        }
        addOrUpdateDatePopup.onFalse()
    }

    const renderOrganizerOnboardingPages = useCallback(() => {

        switch (activeStep) {
            case 0:
                return (<FirstPage />)
            case 1:
                return (<FormOne values={values} setValue={setValue} />)
            case 2:
                return (!id ? <FormTwo values={values} setValue={setValue} control={control} /> : null)
            case 3:
                return (<LastPage />)
            default:
                return (<FirstPage />)
        }

    }, [activeStep, control, id, setValue, values])


    // console.log({ activeStep })

    return (
        <Container maxWidth={settings.themeStretch ? false : 'lg'}>

            <CustomBreadcrumbs
                heading={id ? "Aktivite Güncelle" : "Aktivite Oluştur"}
                sx={{
                    my: { xs: 3, md: 5 },
                }}
            />

            <Grid container spacing={3} sx={{ mt: 5 }}>


                <Grid item xs={12} md={6}>
                    <Card sx={{ p: { xs: 3, md: 5 }, pt: { xs: 5, md: 10 }, maxWidth: '900px', boxShadow: '1px 1px 25px 0px #ddd' }}>

                        <Stepper
                            activeStep={activeStep}
                            alternativeLabel
                            connector={<ColorlibConnector />}
                            sx={{
                                // display: { xs: 'none', sm: 'flex' },
                                width: '100%',
                                transition: 'all',
                                transitionDuration: '1s',
                                mb: 5,
                            }}
                        >
                            {steps.map((label, index) => {
                                return (
                                    <Step key={label} sx={{ transitionDuration: '1s' }}>
                                        <StepLabel StepIconComponent={ColorlibStepIcon} sx={{ transitionDuration: '1s' }}>
                                            {label}
                                        </StepLabel>
                                    </Step>
                                );
                            })}
                        </Stepper>

                        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                            <Stack
                                spacing={3}
                                sx={{
                                    width: '100%',
                                    height: '100%',
                                }}
                            >

                                <Box>
                                    {renderOrganizerOnboardingPages()}
                                </Box>

                                <Box
                                    sx={{
                                        display: activeStep === 3 ? 'none' : 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                    }}
                                >

                                    <LoadingButton
                                        variant='contained'
                                        disabled={activeStep === 0}
                                        onClick={() => {
                                            handleBack()
                                            // if (formPageState.pageNumber === 2) {
                                            //     // setFormPageState(prevState => ({ ...prevState, pageNumber: 1 }))
                                            //     handleBack()
                                            // } else {
                                            //     // setOrganizerOnboardingPageState("first")
                                            //     handleBack()
                                            // }
                                        }}
                                    >
                                        Geri
                                    </LoadingButton>

                                    <LoadingButton
                                        // disabled={formPageState.pageNumber === 4 ? !!(
                                        //     errors?.fullName || errors?.identificationNumber
                                        // ) : !!(
                                        //     errors?.address || errors?.email || errors?.logo || errors?.organization || errors?.phoneNumber || false
                                        // )}
                                        disabled={
                                            Boolean(
                                                activeStep === 3 ||
                                                activeStep === 1 && (
                                                    errors.listingId ||
                                                    errors.locationId ||
                                                    errors.additionalDetails ||
                                                    errors.price ||
                                                    errors.quota ||
                                                    errors.duration ||
                                                    errors.fee ||
                                                    errors.hoursBeforeReservationExpires
                                                )
                                            )
                                        }
                                        variant='contained'
                                        onClick={() => {
                                            // handleNext()
                                            if (activeStep === 1) {
                                                trigger(['listingId', "locationId", "additionalDetails", "price", "quota", "duration", "fee", "hoursBeforeReservationExpires"])
                                                if (values.listingId) {
                                                    handleNext()
                                                }
                                            } else if (activeStep === 2) {
                                                // trigger(['address', "email", "logo", "organization", "phoneNumber"])
                                                // if (isValid) {
                                                handleSubmit(onSubmit())
                                                // }
                                            } else {
                                                handleNext()
                                            }
                                        }}
                                    >
                                        {activeStep !== 3 ? "İleri" : "kaydet"}
                                    </LoadingButton>
                                </Box>


                            </Stack>
                        </FormProvider>

                    </Card>

                </Grid>

                <Grid item xs={12} md={6}>
                    <CreateActivityCard values={values} />

                    {
                        values?.schedule?.days?.length ? (
                            <Stack sx={{ mt: 5, }} spacing={1}>

                                {
                                    values?.schedule?.days?.map((item, index) => {

                                        return (
                                            <Card
                                                key={index}
                                                sx={{
                                                    p: 2,
                                                    boxShadow: '1px 1px 10px 0px #ccc'
                                                }}
                                            >
                                                <Stack direction="row" alignItems="center" justifyContent="space-between">

                                                    <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
                                                        <Typography>
                                                            {
                                                                new Intl.DateTimeFormat("tr", {
                                                                    dateStyle: "medium"
                                                                }).format(new Date(item?.date))
                                                            }
                                                        </Typography>

                                                        <Typography> {timeMapping.find(timeItem => timeItem?.value === item?.startTime)?.name ?? " ... "} </Typography> -
                                                        <Typography> {timeMapping.find(timeItem => timeItem?.value === item?.endTime)?.name ?? " ... "} </Typography>
                                                    </Stack>

                                                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                                                        <Tooltip title="Güncelle" placement="top" arrow>
                                                            <IconButton color={addOrUpdateDatePopup.value ? 'inherit' : 'default'} onClick={() => handleEditDate(item)}>
                                                                <Iconify icon="tabler:edit" />
                                                            </IconButton>
                                                        </Tooltip>

                                                        <Tooltip title="sil" placement="top" arrow>
                                                            <IconButton color='default' onClick={() => handleRemoveFromDate(item?.date)} sx={{ color: 'error.main' }}>
                                                                <Iconify icon="entypo:cross" />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Stack>

                                                </Stack>
                                            </Card>
                                        )
                                    })
                                }
                                {
                                    activeStep !== 3 ? (
                                        <ButtonBase
                                            onClick={handleAddDate}
                                            sx={{
                                                border: '1px dashed #ccc',
                                                borderRadius: '12px',
                                                p: 2,
                                                mt: 2,
                                                '&:active': {
                                                    border: 'none',
                                                    boxShadow: '1px 1px 10px 0px #ccc'
                                                },
                                                '& svg': {
                                                    transform: 'scale(1.2)',
                                                },
                                                '&:active svg': {
                                                    transform: 'scale(1.3)',
                                                },
                                            }}
                                        >
                                            <Iconify icon="mingcute:add-fill" />
                                        </ButtonBase>
                                    ) : null
                                }


                            </Stack>
                        ) : (
                            <Skeleton
                                height={50}
                                sx={{
                                    mt: 3
                                }}
                            />
                        )
                    }


                    <AddAndUpdateNewDatePopup
                        selectedDate={selectedDate}
                        open={addOrUpdateDatePopup.value}
                        onClose={addOrUpdateDatePopup.onFalse}
                        handleAddOrUpdateDate={handleAddOrUpdateDate}
                    />

                </Grid>

            </Grid>

        </Container>
    )
}

const AddAndUpdateNewDatePopup = ({ selectedDate, open, other, onClose, handleAddOrUpdateDate }) => {

    // console.log({ selectedDate })
    const NewActivitySchema = Yup.object().shape({
        "date": null,
        "day": null,
        "startTime": null,
        "endTime": null,
    });


    const defaultValues = useMemo(() => ({
        "date": selectedDate?.date ? new Date(selectedDate?.date) : null,
        "day": selectedDate?.day ?? "",
        "startTime": selectedDate?.startTime ?? "",
        "endTime": selectedDate?.endTime ?? "",
    }), [selectedDate])

    const methods = useForm({
        resolver: yupResolver(NewActivitySchema),
        defaultValues,
        mode: "all",
    });




    const {
        watch,
        handleSubmit,
        formState,
        trigger,
        setValue,
        control,
        reset
    } = methods;

    const {
        isValid,
        errors,
        touchedFields,
    } = formState
    const values = watch();

    useEffect(() => {
        if (values.date) {
            setValue("day", new Date(values?.date).getDay())
        }
    }, [setValue, values.date])


    useEffect(() => {
        reset(defaultValues)
    }, [defaultValues, reset, selectedDate])

    const onSubmit = (data) => {
        // console.log({ data })
        handleAddOrUpdateDate(data)
    }

    return (
        <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose} {...other}>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>

                <DialogTitle sx={{ pb: 2 }}>{selectedDate ? "Güncelle" : "Ekle"}</DialogTitle>

                <DialogContent sx={{ typography: 'body2' }}>
                    <Grid container spacing={3} sx={{ pt: 2 }}>

                        <Grid item xs={12}>
                            <Controller
                                name="date"
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                    <DatePicker
                                        {...field}
                                        format="dd/MM/yyyy"
                                        className='date'
                                        label="Aktivite tarihi"
                                        minDate={new Date()}
                                        slotProps={{
                                            textField: {
                                                fullWidth: true,
                                                error: !!error,
                                                helperText: error?.message,
                                            },
                                        }}
                                    />
                                )}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <RHFSelect name="startTime" label="Başlama saati">
                                {timeMapping.map((status) => (
                                    <MenuItem key={status?.value} value={status?.value} sx={{ textTransform: 'capitalize' }}>
                                        {status?.name?.toLocaleLowerCase()}
                                    </MenuItem>
                                ))}
                            </RHFSelect>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <RHFSelect name="endTime" label="Bitiş saati">
                                {timeMapping.map((status) => (
                                    <MenuItem key={status?.value} value={status?.value} sx={{ textTransform: 'capitalize' }}>
                                        {status?.name?.toLocaleLowerCase()}
                                    </MenuItem>
                                ))}
                            </RHFSelect>
                        </Grid>

                    </Grid>
                </DialogContent>

                <DialogActions>

                    <Button variant="contained" color="primary" onClick={handleSubmit(onSubmit)}>
                        Ekle
                    </Button>

                    <Button variant="outlined" color="inherit" onClick={onClose}>
                        iptal
                    </Button>

                </DialogActions>

            </FormProvider>
        </Dialog>
    );
}


const FirstPage = () => {
    const { id } = useParams()

    return (
        <Box
            sx={{
                minHeight: '100px',
                py: 2,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',

            }}
        >
            <IconButton
                sx={{
                    backgroundColor: (theme) => theme.palette.primary.light,
                    p: 2,
                    mb: 2,
                }}
            >
                <CelebrationIcon
                    sx={{
                        width: "100px",
                        height: '100px',
                        color: (theme) => theme.palette.primary.main
                    }}
                />
            </IconButton>
            <Typography
                sx={{
                    fontSize: 35,
                    fontFamily: 'sen !important',
                    color: theme => theme.palette.primary.main
                }}
            >
                {id ? "Aktivite Güncelle" : "Yeni Aktivite Oluştur"}
            </Typography>
            <Typography
                sx={{
                    fontSize: 20,
                    fontFamily: 'sen !important',
                    color: '#999',
                    textAlign: 'center',
                }}
            >
                Oluşturduğunuz aktivitenin ön izlemesini sağ tarafta görüntüleyebilirsiniz.
            </Typography>
        </Box>
    )
}

const FormOne = ({ setValue, values }) => {

    const { listings, locations } = useSelector(state => state.parameter)

    return (
        <Grid container spacing={3}>


            <Grid item xs={12} md={6} >
                <RHFSelect name="listingId" label="Listeleme">
                    {listings?.map((status) => (
                        <MenuItem key={status?._id} value={status?._id} sx={{ textTransform: 'capitalize' }}>
                            {status?.title?.toLocaleLowerCase()}
                        </MenuItem>
                    ))}
                </RHFSelect>
            </Grid>

            <Grid item xs={12} md={6} >
                <RHFSelect name="locationId" label="Lokasyon">
                    {locations?.map((status) => (
                        <MenuItem key={status?._id} value={status?._id} sx={{ textTransform: 'capitalize' }}>
                            {status?.address?.title?.toLocaleLowerCase()}
                        </MenuItem>
                    ))}
                </RHFSelect>
            </Grid>

            <Grid item xs={12} md={6} >
                {/* <RHFTextField type="number" name="duration" label="Süre" /> */}
                <RHFSelect name="duration" label="Süre">
                    {[
                        { value: 10, name: "10 dk" },
                        { value: 20, name: "20 dk" },
                        { value: 30, name: "30 dk" },
                        { value: 40, name: "40 dk" },
                        { value: 50, name: "50 dk" },
                        { value: 60, name: "60 dk" },
                        { value: 70, name: "70 dk" },
                        { value: 80, name: "80 dk" },
                        { value: 90, name: "90 dk" },
                    ]?.map((status) => (
                        <MenuItem key={status?.value} value={status?.value} sx={{ textTransform: 'capitalize' }}>
                            {status?.name?.toLocaleLowerCase()}
                        </MenuItem>
                    ))}
                </RHFSelect>
            </Grid>

            <Grid item xs={12} md={6} >
                <RHFTextField type="number" name="quota" label="Kota" />
            </Grid>

            <Grid item xs={12} >
                {/* <RHFTextField type="number" name="hoursBeforeReservationExpires" label="Son rezervasyon başlamadan ne kadar önce?" /> */}
                <RHFSelect name="hoursBeforeReservationExpires" label="Son rezervasyon saati">
                    {hoursBeforeReservationExpiresList?.map((status) => (
                        <MenuItem key={status?.value} value={status?.value} sx={{ textTransform: 'capitalize' }}>
                            {status?.name?.toLocaleLowerCase()}
                        </MenuItem>
                    ))}
                </RHFSelect>
            </Grid>


            <Grid item xs={12} md={6} >
                <RHFSelect name="fee" label="Ücret seçeneği">
                    <MenuItem value="free" onClick={() => setValue("price", 0)}>
                        Ücretsiz
                    </MenuItem>
                    <MenuItem value="paid" >
                        Ücretli
                    </MenuItem>
                </RHFSelect>
            </Grid>



            <Grid item xs={12} md={6}>
                <Stack spacing={0} direction="row" alignItems="center">
                    {/* <RHFCheckbox name="fee"
                        label="Ücretli mi?"
                        onClick={(e) => {
                            console.log(e.target.value)
                            if (!e.target.value) {
                                setValue("price", 0)
                            }
                        }}
                        sx={{
                            whiteSpace: 'nowrap'
                        }}
                    /> */}
                    <RHFTextField type="number" name="price" label="Ücret" disabled={values?.fee === "free"} />
                </Stack>
            </Grid>



            <Grid item xs={12}  >
                <RHFTextField multiline rows={4} name="additionalDetails" label="Ek Açıklama" />
            </Grid>

        </Grid>
    )
}

const FormTwo = ({ values, setValue, control }) => {

    return (
        <Grid container spacing={3}>

            <Grid item xs={12}>
                <RHFCheckbox name="schedule.isRepetetive" label="Tekrar eden bir aktivite mi?" />
            </Grid>

            {
                !values?.schedule?.isRepetetive ? (
                    <>
                        <Grid item xs={12}>
                            <Controller
                                name="schedule.startDate"
                                control={control}

                                render={({ field, fieldState: { error } }) => (
                                    <DatePicker
                                        {...field}
                                        format="dd/MM/yyyy"
                                        className='date'
                                        label="Aktivite tarihi"
                                        minDate={new Date()}
                                        slotProps={{
                                            textField: {
                                                fullWidth: true,
                                                error: !!error,
                                                helperText: error?.message,
                                            },
                                        }}
                                    />
                                )}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <RHFSelect name="schedule.startTime" label="Başlama saati">
                                {timeMapping.slice(0, (timeMapping.length - Math.ceil(Number(values.duration / 60)) * 2)).map((status) => (
                                    <MenuItem key={status?.value} value={status?.value} sx={{ textTransform: 'capitalize' }}>
                                        {status?.name?.toLocaleLowerCase()}
                                    </MenuItem>
                                ))}
                            </RHFSelect>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <RHFSelect name="schedule.endTime" label="Bitiş saati">
                                {timeMapping.slice(timeMapping.map(item => item.value).indexOf(values.schedule.startTime + Math.ceil(Number(values.duration / 60)) * 2)).map((status) => (
                                    <MenuItem key={status?.value} value={status?.value} sx={{ textTransform: 'capitalize' }}>
                                        {status?.name?.toLocaleLowerCase()}
                                    </MenuItem>
                                ))}
                            </RHFSelect>
                        </Grid>
                    </>
                ) : (
                    <>
                        <Grid item xs={12} >
                            <RHFSelect name="schedule.repeatedIntervalType" label="Aktivite aralığı">
                                {repeatIntervalTypes.map((status, index) => (
                                    <MenuItem key={status?.value} value={status?.value} sx={{ textTransform: 'capitalize' }}>
                                        {status?.name?.toLocaleLowerCase()}
                                    </MenuItem>
                                ))}
                            </RHFSelect>
                        </Grid>

                        {
                            (
                                values?.schedule?.repeatedIntervalType === "weekly"
                                || values?.schedule?.repeatedIntervalType === "monthly"
                            ) && (
                                <Grid item xs={12} >
                                    {/* <RHFSelect name="schedule.day" label="Aktivite günü">
                                        {dayMapping.map((status) => (
                                            <MenuItem key={status?.value} value={status?.value} sx={{ textTransform: 'capitalize' }}>
                                                {status?.name?.toLocaleLowerCase()}
                                            </MenuItem>
                                        ))}
                                    </RHFSelect> */}

                                    <RHFAutocomplete
                                        name="schedule.day"
                                        placeholder="Haftanın hangi günleri?"
                                        multiple
                                        disableCloseOnSelect
                                        options={dayMapping}
                                        getOptionLabel={(option) => option?.value?.toString()}
                                        isOptionEqualToValue={(option, value) => option?.value === value?.value}
                                        renderOption={(props, option) => (
                                            <li {...props} key={option?.value}>
                                                {option?.name}
                                            </li>
                                        )}
                                        renderTags={(selected, getTagProps) =>
                                            selected.map((option, index) => (
                                                <Chip
                                                    {...getTagProps({ index })}
                                                    key={option?.value}
                                                    label={option?.name}
                                                    size="small"
                                                    color="info"
                                                    variant="soft"
                                                />
                                            ))
                                        }
                                    />
                                </Grid>
                            )
                        }

                        <Grid item xs={12} md={6}>
                            <Controller
                                name="schedule.startDate"
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                    <DatePicker
                                        {...field}
                                        format="dd/MM/yyyy"
                                        className='date'
                                        label="Başlangıç tarihi"
                                        minDate={new Date()}
                                        slotProps={{
                                            textField: {
                                                fullWidth: true,
                                                error: !!error,
                                                helperText: error?.message,
                                            },
                                        }}
                                    />
                                )}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Controller
                                name="schedule.endDate"
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                    <DatePicker
                                        {...field}
                                        format="dd/MM/yyyy"
                                        className='date'
                                        label="Bitiş tarihi"
                                        minDate={new Date()}
                                        slotProps={{
                                            textField: {
                                                fullWidth: true,
                                                error: !!error,
                                                helperText: error?.message,
                                            },
                                        }}
                                    />
                                )}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <RHFSelect name="schedule.startTime" label="Başlama saati">
                                {timeMapping.slice(0, (timeMapping.length - Math.ceil(Number(values.duration / 60)) * 2)).map((status) => (
                                    <MenuItem key={status?.value} value={status?.value} sx={{ textTransform: 'capitalize' }}>
                                        {status?.name?.toLocaleLowerCase()}
                                    </MenuItem>
                                ))}
                            </RHFSelect>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <RHFSelect name="schedule.endTime" label="Bitiş saati">
                                {timeMapping.slice(timeMapping.map(item => item.value).indexOf(values.schedule.startTime + Math.ceil(Number(values.duration / 60)) * 2)).map((status) => (
                                    <MenuItem key={status?.value} value={status?.value} sx={{ textTransform: 'capitalize' }}>
                                        {status?.name?.toLocaleLowerCase()}
                                    </MenuItem>
                                ))}
                            </RHFSelect>
                        </Grid>
                    </>
                )
            }





            {/* <Grid item xs={12} md={6}>
                <Controller
                    name="excludedDates"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <DateRangePickerDay
                            {...field}
                            format="dd/MM/yyyy"
                            slotProps={{
                                textField: {
                                    fullWidth: true,
                                    error: !!error,
                                    helperText: error?.message,
                                },
                            }}
                        />
                    )}
                />
            </Grid> */}


            {/* <Grid item xs={12} >
                <Grid container spacing={3} >

                    <Grid item xs={12}>
                        <Typography variant="subtitle2">monthlyDates</Typography>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Controller
                            name="monthlyDates.startDate"
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                                <DatePicker
                                    {...field}
                                    format="dd/MM/yyyy"
                                    slotProps={{
                                        textField: {
                                            fullWidth: true,
                                            error: !!error,
                                            helperText: error?.message,
                                        },
                                    }}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Controller
                            name="monthlyDates.endDate"
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                                <TimePicker
                                    {...field}
                                    // format="dd/MM/yyyy"
                                    slotProps={{
                                        textField: {
                                            fullWidth: true,
                                            error: !!error,
                                            helperText: error?.message,
                                        },
                                    }}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
            </Grid> */}

            {/* <Grid item xs={12} >
                <Grid container spacing={3} >

                    <Grid item xs={12}>
                        <Typography variant="subtitle2">weeklyDates</Typography>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Controller
                            name="weeklyDates.startDate"
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                                <DatePicker
                                    {...field}
                                    format="dd/MM/yyyy"
                                    slotProps={{
                                        textField: {
                                            fullWidth: true,
                                            error: !!error,
                                            helperText: error?.message,
                                        },
                                    }}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Controller
                            name="weeklyDates.endDate"
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                                <TimePicker
                                    {...field}

                                    // format="dd/MM/yyyy"
                                    slotProps={{
                                        textField: {
                                            fullWidth: true,
                                            error: !!error,
                                            helperText: error?.message,
                                        },
                                    }}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
            </Grid> */}




        </Grid>
    )
}

const LastPage = () => {

    const { id } = useParams()

    return (
        <Box
            sx={{
                minHeight: '100px',
                py: 5,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',

                flexDirection: 'column',

            }}
        >
            <IconButton
                sx={{
                    backgroundColor: (theme) => theme.palette.primary.light,
                    p: 2,
                    mb: 5,
                }}
            >
                <ThumbUpRoundedIcon
                    sx={{
                        width: "100px",
                        height: '100px',
                        color: (theme) => theme.palette.primary.main
                    }}
                />
            </IconButton>
            <Typography
                sx={{
                    fontSize: 40,
                    fontFamily: 'sen !important',
                    color: theme => theme.palette.primary.main
                }}
            >
                {id ? "Başarılı bir şelilde güncellenmiştir." : "Başarılı bir şelilde oluşturulmuştur."}
            </Typography>
        </Box>
    )
}

export default CreateActivity