/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable arrow-body-style */
import { Box, Button, Container, Pagination, paginationClasses } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { reduxFunc } from 'src/hophop/redux'
import { useRouter } from 'src/routes/hook';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';
import { RouterLink } from 'src/routes/components';
import Iconify from 'src/components/iconify/iconify';
import { useSettingsContext } from 'src/components/settings';
import useFetchData from 'src/hophop/hooks/_useFetchData';
// import TourItem from 'src/sections/tour/tour-item';

import { graphqlApi } from 'src/hophop/api';
import { enqueueSnackbar } from 'notistack';

import ActivityCard from './_ActivityCard';



const Activities = () => {

    const router = useRouter();
    const dispatch = useDispatch()
    const settings = useSettingsContext();
    const { handleFetchData } = useFetchData()
    const { accessToken } = useSelector(state => state.auth)
    const { activitiesForModerator } = useSelector(state => state.activity)
    const [variables, setVariables] = useState({
        "filter": {
            "additionalDetails": null,
            "createdByCurrentUser": null,
            "duration": {
                "from": null,
                "to": null
            },
            "fee": null,
            "listing": {
                "title": null,
                "format": null,
                "description": null,
                "companionship": null,
                "category": null,
                "ageInterval": null
            },
            "location": {
                "parking": null,
                "district": null,
                "city": null,
                // "byCoordinates": {
                //   "lat": null,
                //   "long": null,
                //   "radius": null
                // }
            },
            "price": {
                "from": null,
                "to": null
            },
            "quota": {
                "from": null,
                "to": null
            }
        },
        "pagination": {
            "page": 1,
            "pageSize": 6
        }
    })

    useEffect(() => {
        if (accessToken) {
            dispatch(reduxFunc.activityFunc.fetchActivitiesForModeratorFunc({ accessToken, variables }))
        }
    }, [accessToken, dispatch, variables])


    const handleEdit = useCallback((id) => {
        router.push(`/aktiviteler/edit/${id}`);
    }, [router]);

    const handleDelete = useCallback(async (id) => {
        // enqueueSnackbar('Başarılı bir şekilde silinmiştir!')
        try {
            const response = await handleFetchData({
                data: graphqlApi.activity.mutations.activitiesForModerator.removeActivity({ activityId: id }),
                accessToken,
                url: "/activity/graphql",
            })
            console.log({ response })

            if (response?.data?.data?.moderator_removeActivity?.success) {
                enqueueSnackbar('Başarılı bir şekilde silinmiştir!')
                dispatch(reduxFunc.activityFunc.fetchActivitiesForModeratorFunc({ accessToken, variables }))
            }

            if (response?.data?.errors) {
                response?.data?.errors?.forEach((item, index) => {
                    if (item?.message === "Activity not found") {
                        enqueueSnackbar('Aktivite bulunamadı!', {
                            variant: 'error'
                        })
                    } else {
                        enqueueSnackbar('Aktivite silinemedi!', {
                            variant: 'error'
                        })
                    }
                })
            }

        } catch (error) {
            console.log(error)
        }
    }, [accessToken, dispatch, handleFetchData, variables]);

    const handleMakePassive = useCallback(async (id) => {
        // enqueueSnackbar('Başarılı bir şekilde silinmiştir!')
        try {
            const response = await handleFetchData({
                data: graphqlApi.activity.mutations.activitiesForModerator.makePassive({ activityId: id }),
                accessToken,
                url: "/activity/graphql",
            })
            console.log({ response })

            if (response?.data?.data?.moderator_setActivityAsPassive?.success) {
                enqueueSnackbar('Başarılı bir şekilde pasif yapılmıştır!')
                dispatch(reduxFunc.activityFunc.fetchActivitiesForModeratorFunc({ accessToken, variables }))
            }

            if (response?.data?.errors) {
                response?.data?.errors?.forEach((item, index) => {
                    if (item?.message === "Activity not found") {
                        enqueueSnackbar('Aktivite bulunamadı!', {
                            variant: 'error'
                        })
                    } else {
                        enqueueSnackbar('Aktivite silinemedi!', {
                            variant: 'error'
                        })
                    }
                })
            }

        } catch (error) {
            console.log(error)
        }
    }, [accessToken, dispatch, handleFetchData, variables]);

    const handleMakeActive = useCallback(async (id) => {
        // enqueueSnackbar('Başarılı bir şekilde silinmiştir!')
        try {
            const response = await handleFetchData({
                data: graphqlApi.activity.mutations.activitiesForModerator.makeActive({ activityId: id }),
                accessToken,
                url: "/activity/graphql",
            })
            console.log({ response })

            if (response?.data?.data?.moderator_setActivityAsActive?.success) {
                enqueueSnackbar('Başarılı bir şekilde aktif edilmiştir!')
                dispatch(reduxFunc.activityFunc.fetchActivitiesForModeratorFunc({ accessToken, variables }))
            }

            if (response?.data?.errors) {
                response?.data?.errors?.forEach((item, index) => {
                    if (item?.message === "Activity not found") {
                        enqueueSnackbar('Aktivite bulunamadı!', {
                            variant: 'error'
                        })
                    } else {
                        enqueueSnackbar('Aktivite silinemedi!', {
                            variant: 'error'
                        })
                    }
                })
            }

        } catch (error) {
            console.log(error)
        }
    }, [accessToken, dispatch, handleFetchData, variables]);


    const handlePagination = (event, value) => {
        // console.log({ event, value })
        setVariables(prevState => ({
            ...prevState,
            pagination: {
                ...prevState.pagination,
                page: value
            }
        }))
    };



    return (
        <Container maxWidth={settings.themeStretch ? false : 'lg'}>

            <CustomBreadcrumbs
                heading="Aktiviteler"
                action={
                    <Button
                        component={RouterLink}
                        href="/aktiviteler/olustur"
                        variant="contained"
                        startIcon={<Iconify icon="mingcute:add-line" />}
                    >
                        Yeni Aktivite
                    </Button>
                }
                sx={{
                    my: { xs: 3, md: 5 },
                }}
            />

            <Box
                gap={3}
                display="grid"
                gridTemplateColumns={{
                    xs: 'repeat(1, 1fr)',
                    sm: 'repeat(2, 1fr)',
                    md: 'repeat(3, 1fr)',
                }}
            >
                {
                    (activitiesForModerator?.data ?? []).map((item, index) => (
                        <ActivityCard
                            key={index}
                            tour={item}
                            // onView={() => handleView(item._id)}
                            onEdit={() => handleEdit(item._id)}
                            onDelete={() => handleDelete(item._id)}
                            onMakeActive={() => handleMakeActive(item._id)}
                            onMakePassive={() => handleMakePassive(item._id)}
                        />
                    ))
                }
            </Box>

            {activitiesForModerator?.count > variables.pagination.pageSize && (
                <Pagination
                    onChange={handlePagination}
                    onClick={(e) => console.log({ e })}
                    count={Math.ceil(activitiesForModerator?.count / variables.pagination.pageSize)}
                    sx={{
                        mt: 8,
                        [`& .${paginationClasses.ul}`]: {
                            justifyContent: 'center',
                        },
                    }}
                />
            )}
        </Container>
    );
}

export default Activities