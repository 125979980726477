const activityDetail = ({ activityId }) => {

  const data = JSON.stringify({
    query: `
    query Moderator_activity($activityId: String!) {
      moderator_activity(activityId: $activityId) {
        _id
        listing {
          _id
          title
          category
          subCategory
          ageInterval
          format
          description
          images
          coverImage
          companionship
        }
        schedule {
          _id
          repeatedIntervalType
          startDate
          endDate
          totalDayCount
          days {
            # _id
            date
            day
            startTime
            endTime
            # createdAt
            # updatedAt
          }
          createdAt
          updatedAt
        }
        location {
          _id
          city
          country
          address {
            title
            description
          }
          lat
          long
          parking
        }
        additionalDetails
        quota
        hoursBeforeReservationExpires
        fee
        status
        price
        duration
        createdBy {
          _id
          displayName
          displayImageUrl
          role
        }
        updatedBy {
          _id
          displayName
          displayImageUrl
          role
        }
        createdAt
        updatedAt
      }
    }
              `,
    variables: {
      "activityId": activityId
    }
  })

  return data

}

const allActivities = ({ variables = null }) => {

  const data = JSON.stringify({
    query: `
    query Moderator_activities($filter: FilterActivityByModeratorInput, $pagination: Pagination) {
      moderator_activities(filter: $filter, pagination: $pagination) {
        data {
          _id
          listing {
            _id
            title
            category
            subCategory
            ageInterval
            format
            description
            images
            coverImage
            companionship
          }
          schedule {
            _id
            repeatedIntervalType
            startDate
            endDate
            totalDayCount
            createdAt
            updatedAt
          }
          location {
            _id
            city
            # district
            country
            address {
              title
              description
            }
            lat
            long
            parking
          }
          additionalDetails
          quota
          hoursBeforeReservationExpires
          fee
          status
          price
          duration
          createdBy {
            _id
            displayName
            displayImageUrl
            role
          }
          updatedBy {
            _id
            displayName
            displayImageUrl
            role
          }
          createdAt
          updatedAt
        }
        count
      }
    }
              `,
    variables: {
      "filter": {
        "additionalDetails": null,
        "createdByCurrentUser": null,
        "duration": {
          "from": null,
          "to": null
        },
        "fee": null,
        "listing": {
          "title": null,
          "format": null,
          "description": null,
          "companionship": null,
          "category": null,
          "ageInterval": null
        },
        "location": {
          "parking": null,
          "district": null,
          "city": null,
          // "byCoordinates": {
          //   "lat": null,
          //   "long": null,
          //   "radius": null
          // }
        },
        "price": {
          "from": null,
          "to": null
        },
        "quota": {
          "from": null,
          "to": null
        }
      },
      "pagination": {
        "page": variables?.pagination?.page ?? null,
        "pageSize": variables?.pagination?.pageSize ?? null
      }
    }
  })

  return data

}

const parametersForActivity = () => {

  const data = JSON.stringify({
    query: `
    query parametersForActivity {
      moderator_listingsOfModerators {
        data {
          _id
          title
        }
      }
      moderator_locationsOfModerators {
        data {
          _id
          address{
            title
          }
        }
      }
    }
    `,
    variables: {

    }
  })

  return data
}


export const activitiesForModerator = {
  activityDetail,
  allActivities,
  parametersForActivity,
}