import {
    reduxLoginFunc,
    reduxLogoutFunc,
} from './slices/_authSlice'

import {
    fetchUserInfoFunc
} from './slices/_userSlice'

import { locationFunc } from './slices/_locationSlice'
import { activityFunc } from './slices/_activitySlice'
import { listingFunc } from './slices/_listingSlice'
import { parameterFunc } from './slices/_parameterSlice'


export const reduxFunc = {
    reduxLoginFunc,
    reduxLogoutFunc,

    fetchUserInfoFunc,

    locationFunc,
    activityFunc,
    listingFunc,
    parameterFunc,

}