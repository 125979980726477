/* eslint-disable arrow-body-style */
import { Outlet } from "react-router";

import NotFoundPage from "src/pages/404";
import { HophopPages } from "src/hophop/pages";
import Iconify from "src/components/iconify/iconify";
import DashboardLayout from "src/layouts/dashboard/layout";

import RouteGuard from "../RouteGuard";


const icon = (name = "mingcute:add-line") => (<Iconify icon={name} />)


const navConfig = [
    {
        subheader: 'hophop',
        items: [
            {
                title: 'panel',
                path: '/panel',
                icon: icon("mingcute:dashboard-line"),
            },
            {
                title: 'Aktiviteler',
                path: "/aktiviteler",
                icon: icon("lucide:party-popper"),
                children: [
                    { title: 'Aktivite Listeleri', path: "/aktiviteler/liste" },
                    { title: 'Aktivite Oluştur', path: "/aktiviteler/olustur" },
                ]
            },
            {
                title: 'Listelemeler',
                path: "/listelemeler",
                icon: icon("ep:list"),
                children: [
                    { title: 'Listelemeler Listesi', path: "/listelemeler/liste" },
                    { title: 'Listeleme Oluştur', path: "/listelemeler/olustur" },
                ]
            },
            {
                title: 'Konumlar',
                path: "/konumlar",
                icon: icon("typcn:location"),
                children: [
                    { title: 'Konum Listeleri', path: "/konumlar/liste" },
                    { title: 'Konum Oluştur', path: "/konumlar/olustur" },
                ]
            },



            {
                title: 'Rezervasyonlar',
                path: "/rezervasyonlar/liste",
                icon: icon("fluent-mdl2:reservation-orders"),
                // children: [
                //     { title: 'Reservasyon Listeleri', path: "/rezervasyonlar/liste" },
                //     // { title: 'Konum Oluştur', path: "/konumlar/olustur" },
                // ]
            },
            {
                title: 'Müşteriler',
                path: "/musteriler/liste",
                icon: icon("fontisto:persons"),
                // children: [
                //     { title: 'Konum Listeleri', path: "/konumlar/liste" },
                //     { title: 'Konum Oluştur', path: "/konumlar/olustur" },
                // ]
            },
            {
                title: 'Yorumlar',
                path: "/yorumlar/liste",
                icon: icon("mdi:comments-text"),
                // children: [
                //     { title: 'Konum Listeleri', path: "/konumlar/liste" },
                //     { title: 'Konum Oluştur', path: "/konumlar/olustur" },
                // ]
            },
            {
                title: 'Mesajlar',
                path: "/mesajlar/liste",
                icon: icon("jam:messages-f"),
                // children: [
                //     { title: 'Konum Listeleri', path: "/konumlar/liste" },
                //     { title: 'Konum Oluştur', path: "/konumlar/olustur" },
                // ]
            },
            {
                title: 'Ödemeler',
                path: "/odemeler/liste",
                icon: icon("mdi:account-payment"),
                // children: [
                //     { title: 'Ödemeler Listesi', path: "/odemeler/liste" },
                //     // { title: 'Konum Oluştur', path: "/konumlar/olustur" },
                // ]
            },

        ],
    },

    // {
    //     subheader: 'menu 2',
    //     items: [
    //         {
    //             title: 'Konum Onaylama',
    //             path: '/konum-onayla',
    //             icon: icon("mingcute:location-3-line"),
    //         },
    //     ]
    // }

];

export const moderatorRoutes = ({ userRole, isOnboarding }) => {

    // console.log("moderator routes", userRole)

    return ({
        path: '',
        element: (
            <RouteGuard redirectPath="/" isAuth={userRole === "moderator"}>
                <Outlet />
            </RouteGuard>
        ),
        children: [

            // dashboard
            {
                path: '/',
                element: (
                    <DashboardLayout navConfig={navConfig}>
                        <Outlet />
                    </DashboardLayout>
                ),
                children: [
                    {
                        path: "",
                        element: (<HophopPages.DashboardPages.Moderator.Panel />)
                    },
                    {
                        path: "panel",
                        element: (<HophopPages.DashboardPages.Moderator.Panel />)
                    },


                    {
                        path: "aktiviteler",
                        element: (<HophopPages.DashboardPages.Moderator.Activities.Main />)
                    },
                    {
                        path: "aktiviteler/liste",
                        element: (<HophopPages.DashboardPages.Moderator.Activities.Main />)
                    },
                    {
                        path: "aktiviteler/olustur",
                        element: (<HophopPages.DashboardPages.Moderator.Activities.CreateActivity />)
                    },
                    {
                        path: "aktiviteler/edit/:id",
                        element: (<HophopPages.DashboardPages.Moderator.Activities.CreateActivity />)
                    },


                    {
                        path: "listelemeler",
                        element: (<HophopPages.DashboardPages.Moderator.Listings.Main />)
                    },
                    {
                        path: "listelemeler/liste",
                        element: (<HophopPages.DashboardPages.Moderator.Listings.Main />)
                    },
                    {
                        path: "listelemeler/olustur",
                        element: (<HophopPages.DashboardPages.Moderator.Listings.CreateListing />)
                    },
                    {
                        path: "listelemeler/edit/:id",
                        element: (<HophopPages.DashboardPages.Moderator.Listings.CreateListing />)
                    },


                    {
                        path: "konumlar",
                        element: (<HophopPages.DashboardPages.Moderator.Locations.Main />)
                    },
                    {
                        path: "konumlar/liste",
                        element: (<HophopPages.DashboardPages.Moderator.Locations.Main />)
                    },
                    {
                        path: "konumlar/olustur",
                        element: (<HophopPages.DashboardPages.Moderator.Locations.CreateLocations />)
                    },
                    {
                        path: "konum-onayla",
                        element: (<HophopPages.DashboardPages.Moderator.Locations.AproveLocations />)
                    },



                    {
                        path: "rezervasyonlar/liste",
                        element: (<HophopPages.DashboardPages.Moderator.Reservations.Main />)
                    },
                    {
                        path: "rezervasyonlar/:id",
                        element: (<HophopPages.DashboardPages.Moderator.Reservations.ReservationDetailList />)
                    },


                    {
                        path: "musteriler/liste",
                        element: (<HophopPages.DashboardPages.Moderator.Customers.Main />)
                    },

                    {
                        path: "yorumlar/liste",
                        element: (<HophopPages.DashboardPages.Moderator.Comments.Main />)
                    },

                    {
                        path: "mesajlar/liste",
                        element: (<HophopPages.DashboardPages.Moderator.Messages.Main />)
                    },

                    {
                        path: "odemeler/liste",
                        element: (<HophopPages.DashboardPages.Moderator.Payments.Main />)
                    },


                    {
                        path: "user-profile",
                        element: (<HophopPages.DashboardPages.Moderator.UserProfile />)
                    },
                    {
                        path: "settings",
                        element: (<HophopPages.DashboardPages.Moderator.Settings />)
                    },
                    { path: '*', element: <NotFoundPage /> },

                ]
            },

            { path: '*', element: <NotFoundPage /> },
            // { path: '*', element: <Navigate to="/¨" replace /> },
        ]
    })

}