import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
// routes
import { paths } from 'src/routes/paths';
import { RouterLink } from 'src/routes/components';
// assets
import { PasswordIcon } from 'src/assets/icons';
// components
import Iconify from 'src/components/iconify';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import { enqueueSnackbar } from 'notistack';
import useFetchData from 'src/hophop/hooks/_useFetchData';

// ----------------------------------------------------------------------

export default function ClassicForgotPasswordView() {

  const { handleFetchData } = useFetchData()

  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string().required('Gerekli').email('Geçerli bir email adresi giriniz!'),
  });

  const defaultValues = {
    email: '',
  };

  const methods = useForm({
    resolver: yupResolver(ForgotPasswordSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      // await new Promise((resolve) => setTimeout(resolve, 500));
      const response = await handleFetchData({
        data: JSON.stringify({
          email: data.email
        }),
        url: "/auth/auth/requestresetpassword"
      })

      console.log({ response })

      if (response?.response?.data?.message) {
        enqueueSnackbar(response?.response?.data?.message, {
          variant: 'error'
        })
      }
      if (response?.data?.isSuccess) {
        sessionStorage.setItem('email-recovery', data.email);
        enqueueSnackbar("Başarılı bir şekilde gönderilmiştir!")
        // enqueueSnackbar(response?.data?.message)
        // navigate("/auth/reset-password")
      }
      console.info('DATA', data);
    } catch (error) {
      console.error(error);
    }
  });

  const renderForm = (
    <Stack spacing={3} alignItems="center">
      <RHFTextField name="email" label="Email adresi" />

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting}
      >
        Gönder
      </LoadingButton>

      <Link
        component={RouterLink}
        href={paths.authDemo.classic.login}
        color="inherit"
        variant="subtitle2"
        sx={{
          alignItems: 'center',
          display: 'inline-flex',
        }}
      >
        <Iconify icon="eva:arrow-ios-back-fill" width={16} />
        Geri dön
      </Link>
    </Stack>
  );

  const renderHead = (
    <>
      <PasswordIcon sx={{ height: 96 }} />

      <Stack spacing={1} sx={{ my: 5 }}>
        <Typography variant="h3">Şifreni mi Unuttun?</Typography>

        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {/* Please enter the email address associated with your account and We will email you a link
          to reset your password. */}

          Lütfen hesabınıza ait email adresinizi giriniz. Emailinize gelen link ile şifrenizi sıfırlayabilirsiniz.
        </Typography>
      </Stack>
    </>
  );

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      {renderHead}

      {renderForm}
    </FormProvider>
  );
}
