/* eslint-disable no-shadow */
import axios from 'axios'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { graphqlApi } from '../../api'

// graphqlApi

const configFunc = ({ data, accessToken }) => ({
    method: 'post',
    url: `${process.env.REACT_APP_BASE_URL}/activity/graphql`,
    headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
        'accept': 'application/json'
    },
    data,
})


const initialState = {
    cities: [],
    categories: [],
    listings: [],
    locations: [],
    loading: false,
}

export const fetchParametersFunc = createAsyncThunk('parameter/fetchParameter', async ({ accessToken }) => {
    // console.log({ caaa: configFunc({ accessToken, data: graphqlApi.activity.queries.parameters() }) })
    const response = await axios(configFunc({ accessToken, data: graphqlApi.activity.queries.parameters() }))
    // console.log({ response })
    return response?.data

})

export const fetchCreateActivityParametersForModeratorFunc = createAsyncThunk('parameter/fetchCreateActivityParametersForModerator', async ({ accessToken }) => {
    // console.log({ caaa: configFunc({ accessToken, data: graphqlApi.activity.queries.parameters() }) })
    const response = await axios(configFunc({ accessToken, data: graphqlApi.activity.queries.activitiesForModerator.parametersForActivity() }))
    // console.log({ response })
    return response?.data

})



const { reducer: parameterReducer } = createSlice({
    name: 'user',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // ################# general parameters ######
        builder.addCase(fetchParametersFunc.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(fetchParametersFunc.fulfilled, (state, action) => {
            // console.log(action?.payload)
            state.cities = action.payload?.data?.anonymous_cities
            state.categories = action.payload?.data?.anonymous_categories
            state.loading = false
        })
        builder.addCase(fetchParametersFunc.rejected, (state, action) => {
            console.log(action)
            state.loading = false
        })


        // ################# create activity parameters ######
        builder.addCase(fetchCreateActivityParametersForModeratorFunc.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(fetchCreateActivityParametersForModeratorFunc.fulfilled, (state, action) => {
            console.log(action?.payload)
            state.listings = action.payload?.data?.moderator_listingsOfModerators?.data
            state.locations = action.payload?.data?.moderator_locationsOfModerators?.data
            state.loading = false
        })
        builder.addCase(fetchCreateActivityParametersForModeratorFunc.rejected, (state, action) => {
            console.log(action)
            state.loading = false
        })



    }
})


export default parameterReducer

export const parameterFunc = {
    fetchCreateActivityParametersForModeratorFunc,
    fetchParametersFunc,
}