/* eslint-disable arrow-body-style */


export const findImageContentType = (file) => {

    // console.log({ file })

    if (file?.type === "image/png") {
        return "pngImage"
    }

    return "jpegImage"
}