import { Suspense, lazy } from "react";
/* eslint-disable arrow-body-style */
import { Navigate, Outlet } from "react-router";
import { HophopPages } from "src/hophop/pages";


import SvgColor from "src/components/svg-color/svg-color";
import DashboardLayout from "src/layouts/dashboard/layout";
import NotFoundPage from "src/pages/404";
import Iconify from "src/components/iconify/iconify";
import { SplashScreen } from "src/components/loading-screen";
import RouteGuard from "../RouteGuard";


const icon = (name = "mingcute:add-line") => {

    return <Iconify icon={name} />
    // return <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
}

const ICONS = {
    blog: icon('ic_blog'),
    cart: icon('ic_cart'),
    chat: icon('ic_chat'),
    mail: icon('ic_mail'),
    user: icon('ic_user'),
    file: icon('ic_file'),
    lock: icon('ic_lock'),
    label: icon('ic_label'),
    blank: icon('ic_blank'),
    kanban: icon('ic_kanban'),
    folder: icon('ic_folder'),
    banking: icon('ic_banking'),
    booking: icon('ic_booking'),
    invoice: icon('ic_invoice'),
    calendar: icon('ic_calendar'),
    disabled: icon('ic_disabled'),
    external: icon('ic_external'),
    menuItem: icon('ic_menu_item'),
    ecommerce: icon('ic_ecommerce'),
    analytics: icon('ic_analytics'),
    dashboard: icon('ic_dashboard'),
};

const navConfig = [
    // GENERAL
    // ----------------------------------------------------------------------
    {
        subheader: 'hophop',
        items: [
            // { title: 'app', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },
            // { title: 'ecommerce', path: "#", icon: ICONS.ecommerce },
            // { title: 'analytics', path: "#", icon: ICONS.analytics },
            // { title: 'banking', path: "#", icon: ICONS.banking },
            // { title: 'booking', path: "#", icon: ICONS.booking },
            // { title: 'file', path: "#", icon: ICONS.file },

            // { title: 'panel', path: '/dashboard/panel', icon: ICONS.kanban },
            // { title: 'takvim', path: '/dashboard/takvim', icon: ICONS.calendar },
            // { title: 'rezervasyonlar', path: '/dashboard/rezervasyonlar', icon: ICONS.booking },
            // { title: 'listelemeler', path: '/dashboard/listelemeler', icon: ICONS.analytics },
            // { title: 'finans', path: '/dashboard/finans', icon: ICONS.banking },
            // { title: 'müşteriler', path: '/dashboard/müşteriler', icon: ICONS.user },

            // { title: 'konumlar', path: '/dashboard/konumlar', icon: ICONS.cart },
            // { title: 'yorumlar', path: '/dashboard/yorumlar', icon: ICONS.file },
            // { title: 'kaynaklar', path: '/dashboard/kaynaklar', icon: ICONS.folder },
            // { title: 'blog', path: '/dashboard/blog', icon: ICONS.blog },
            // { title: 'bildirimler', path: '/dashboard/bildirimler', icon: ICONS.chat },
            // { title: 'ödemeler', path: '/dashboard/ödemeler', icon: ICONS.invoice },



            {
                title: 'panel',
                path: '/panel',
                icon: icon("mingcute:dashboard-line"),
                // icon: ICONS.dashboard
            },
            {
                title: 'Aktiviteler',
                path: "/aktiviteler",
                icon: icon("lucide:party-popper"),
                // icon: ICONS.booking,
                children: [
                    { title: 'Aktivite Listeleri', path: "/aktiviteler/liste" },
                    { title: 'Aktivite Oluştur', path: "/aktiviteler/olustur" },
                ]
            },
            {
                title: 'Listelemeler',
                path: "/listelemeler",
                icon: icon("ep:list"),
                // icon: ICONS.ecommerce,
                children: [
                    { title: 'Listelemeler Listesi', path: "/listelemeler/liste" },
                    { title: 'Listeleme Oluştur', path: "/listelemeler/olustur" },
                ]
            },
            {
                title: 'Konumlar',
                path: "/konumlar",
                // icon: ICONS.lock,
                icon: icon("typcn:location"),
                children: [
                    { title: 'Konum Listeleri', path: "/konumlar/liste" },
                    { title: 'Konum Oluştur', path: "/konumlar/olustur" },
                ]
            },


            {
                title: 'Rezervasyonlar',
                path: "/rezervasyonlar/liste",
                icon: icon("fluent-mdl2:reservation-orders"),
                // children: [
                //     { title: 'Reservasyon Listeleri', path: "/rezervasyonlar/liste" },
                //     // { title: 'Konum Oluştur', path: "/konumlar/olustur" },
                // ]
            },
            {
                title: 'Müşteriler',
                path: "/musteriler/liste",
                icon: icon("fontisto:persons"),
                // children: [
                //     { title: 'Konum Listeleri', path: "/konumlar/liste" },
                //     { title: 'Konum Oluştur', path: "/konumlar/olustur" },
                // ]
            },
            {
                title: 'Yorumlar',
                path: "/yorumlar/liste",
                icon: icon("mdi:comments-text"),
                // children: [
                //     { title: 'Konum Listeleri', path: "/konumlar/liste" },
                //     { title: 'Konum Oluştur', path: "/konumlar/olustur" },
                // ]
            },
            {
                title: 'Mesajlar',
                path: "/mesajlar/liste",
                icon: icon("jam:messages-f"),
                // children: [
                //     { title: 'Konum Listeleri', path: "/konumlar/liste" },
                //     { title: 'Konum Oluştur', path: "/konumlar/olustur" },
                // ]
            },
            {
                title: 'Ödemeler',
                path: "/odemeler/liste",
                icon: icon("mdi:account-payment"),
                // children: [
                //     { title: 'Ödemeler Listesi', path: "/odemeler/liste" },
                //     // { title: 'Konum Oluştur', path: "/konumlar/olustur" },
                // ]
            },

        ],
    },

    // MANAGEMENT
    // ----------------------------------------------------------------------
    // {
    //   subheader: 'management',
    //   items: [
    //     // USER
    //     {
    //       title: 'user',
    //       path: PATH_DASHBOARD.user.root,
    //       icon: ICONS.user,
    //       children: [
    //         { title: 'profile', path: PATH_DASHBOARD.user.profile },
    //         { title: 'cards', path: PATH_DASHBOARD.user.cards },
    //         { title: 'list', path: PATH_DASHBOARD.user.list },
    //         { title: 'create', path: PATH_DASHBOARD.user.new },
    //         { title: 'edit', path: PATH_DASHBOARD.user.demoEdit },
    //         { title: 'account', path: PATH_DASHBOARD.user.account },
    //       ],
    //     },

    //     // E-COMMERCE
    //     {
    //       title: 'ecommerce',
    //       path: PATH_DASHBOARD.eCommerce.root,
    //       icon: ICONS.cart,
    //       children: [
    //         { title: 'shop', path: PATH_DASHBOARD.eCommerce.shop },
    //         { title: 'product', path: PATH_DASHBOARD.eCommerce.demoView },
    //         { title: 'list', path: PATH_DASHBOARD.eCommerce.list },
    //         { title: 'create', path: PATH_DASHBOARD.eCommerce.new },
    //         { title: 'edit', path: PATH_DASHBOARD.eCommerce.demoEdit },
    //         { title: 'checkout', path: PATH_DASHBOARD.eCommerce.checkout },
    //       ],
    //     },

    //     // INVOICE
    //     {
    //       title: 'invoice',
    //       path: PATH_DASHBOARD.invoice.root,
    //       icon: ICONS.invoice,
    //       children: [
    //         { title: 'list', path: PATH_DASHBOARD.invoice.list },
    //         { title: 'details', path: PATH_DASHBOARD.invoice.demoView },
    //         { title: 'create', path: PATH_DASHBOARD.invoice.new },
    //         { title: 'edit', path: PATH_DASHBOARD.invoice.demoEdit },
    //       ],
    //     },

    //     // BLOG
    //     {
    //       title: 'blog',
    //       path: PATH_DASHBOARD.blog.root,
    //       icon: ICONS.blog,
    //       children: [
    //         { title: 'posts', path: PATH_DASHBOARD.blog.posts },
    //         { title: 'post', path: PATH_DASHBOARD.blog.demoView },
    //         { title: 'create', path: PATH_DASHBOARD.blog.new },
    //       ],
    //     },
    //     {
    //       title: 'File manager',
    //       path: PATH_DASHBOARD.fileManager,
    //       icon: ICONS.folder,
    //     },
    //   ],
    // },

    // APP
    // ----------------------------------------------------------------------
    // {
    //   subheader: 'app',
    //   items: [
    //     {
    //       title: 'mail',
    //       path: PATH_DASHBOARD.mail.root,
    //       icon: ICONS.mail,
    //       info: <Label color="error">+32</Label>,
    //     },
    //     {
    //       title: 'chat',
    //       path: PATH_DASHBOARD.chat.root,
    //       icon: ICONS.chat,
    //     },
    //     {
    //       title: 'calendar',
    //       path: PATH_DASHBOARD.calendar,
    //       icon: ICONS.calendar,
    //     },
    //     {
    //       title: 'kanban',
    //       path: PATH_DASHBOARD.kanban,
    //       icon: ICONS.kanban,
    //     },
    //   ],
    // },

    // DEMO MENU STATES
    // {
    //   subheader: 'Other cases',
    //   items: [
    //     {
    //       // default roles : All roles can see this entry.
    //       // roles: ['user'] Only users can see this item.
    //       // roles: ['admin'] Only admin can see this item.
    //       // roles: ['admin', 'manager'] Only admin/manager can see this item.
    //       // Reference from 'src/guards/RoleBasedGuard'.
    //       title: 'item_by_roles',
    //       path: PATH_DASHBOARD.permissionDenied,
    //       icon: ICONS.lock,
    //       roles: ['admin'],
    //       caption: 'only_admin_can_see_this_item',
    //     },
    //     {
    //       title: 'menu_level',
    //       path: '#/dashboard/menu_level',
    //       icon: ICONS.menuItem,
    //       children: [
    //         {
    //           title: 'menu_level_2a',
    //           path: '#/dashboard/menu_level/menu_level_2a',
    //         },
    //         {
    //           title: 'menu_level_2b',
    //           path: '#/dashboard/menu_level/menu_level_2b',
    //           children: [
    //             {
    //               title: 'menu_level_3a',
    //               path: '#/dashboard/menu_level/menu_level_2b/menu_level_3a',
    //             },
    //             {
    //               title: 'menu_level_3b',
    //               path: '#/dashboard/menu_level/menu_level_2b/menu_level_3b',
    //               children: [
    //                 {
    //                   title: 'menu_level_4a',
    //                   path: '#/dashboard/menu_level/menu_level_2b/menu_level_3b/menu_level_4a',
    //                 },
    //                 {
    //                   title: 'menu_level_4b',
    //                   path: '#/dashboard/menu_level/menu_level_2b/menu_level_3b/menu_level_4b',
    //                 },
    //               ],
    //             },
    //           ],
    //         },
    //       ],
    //     },
    //     {
    //       title: 'item_disabled',
    //       path: '#disabled',
    //       icon: ICONS.disabled,
    //       disabled: true,
    //     },

    //     {
    //       title: 'item_label',
    //       path: '#label',
    //       icon: ICONS.label,
    //       info: (
    //         <Label color="info" startIcon={<Iconify icon="eva:email-fill" />}>
    //           NEW
    //         </Label>
    //       ),
    //     },
    //     {
    //       title: 'item_caption',
    //       path: '#caption',
    //       icon: ICONS.menuItem,
    //       caption:
    //         'Quisque malesuada placerat nisl. In hac habitasse platea dictumst. Cras id dui. Pellentesque commodo eros a enim. Morbi mollis tellus ac sapien.',
    //     },
    //     {
    //       title: 'item_external_link',
    //       path: 'https://www.google.com/',
    //       icon: ICONS.external,
    //     },
    //     {
    //       title: 'blank',
    //       path: PATH_DASHBOARD.blank,
    //       icon: ICONS.blank,
    //     },
    //   ],
    // },
];

const LoadingComp = () => <div>loading</div>

export const organizerRoutes = ({ userRole, isOnboarding, userLoading, authLoading }) => {

    console.log("organizer route", { isOnboarding })

    // if (userLoading || authLoading || (isOnboarding === null)) return "loading"

    return ({
        path: '',

        element: (
            <RouteGuard redirectPath="/" isAuth={userRole === "organizer"}>
                <Suspense fallback={<div>loading</div>}>
                    <Outlet />
                </Suspense>
            </RouteGuard>
        ),

        children: [

            // loading
            (isOnboarding === null && ({
                path: "*",
                element: (
                    <RouteGuard redirectPath="/onboarding" isAuth={isOnboarding === null} >
                        <LoadingComp />
                    </RouteGuard>
                )
            })),

            // onboarding
            (isOnboarding === false && ({
                path: '',
                element: (
                    <RouteGuard redirectPath="/panel" isAuth={!isOnboarding} >
                        <Outlet />
                    </RouteGuard>
                ),
                children: [
                    {
                        path: '/onboarding',
                        element: (<HophopPages.OnboardingPages.OrganizerOnboardingMainPage />)
                    },
                    {
                        path: '/',
                        element: (<HophopPages.OnboardingPages.OrganizerOnboardingMainPage />)
                    },
                ]
            })),

            // dashboard
            (!!isOnboarding && ({
                path: '/',
                element: (
                    <RouteGuard redirectPath="/onboarding" isAuth={isOnboarding}>
                        <DashboardLayout navConfig={navConfig}>
                            <Outlet />
                        </DashboardLayout>
                    </RouteGuard>
                ),
                children: [
                    {
                        path: "",
                        element: (<HophopPages.DashboardPages.Organizer.Panel />)
                    },
                    {
                        path: "panel",
                        element: (<HophopPages.DashboardPages.Organizer.Panel />)
                    },


                    {
                        path: "aktiviteler",
                        element: (<HophopPages.DashboardPages.Organizer.Activities.Main />)
                    },
                    {
                        path: "aktiviteler/liste",
                        element: (<HophopPages.DashboardPages.Organizer.Activities.Main />)
                    },
                    {
                        path: "aktiviteler/olustur",
                        element: (<HophopPages.DashboardPages.Organizer.Activities.CreateActivity />)
                    },
                    {
                        path: "aktiviteler/edit/:id",
                        element: (<HophopPages.DashboardPages.Organizer.Activities.CreateActivity />)
                    },


                    {
                        path: "listelemeler",
                        element: (<HophopPages.DashboardPages.Organizer.Listings.Main />)
                    },
                    {
                        path: "listelemeler/liste",
                        element: (<HophopPages.DashboardPages.Organizer.Listings.Main />)
                    },
                    {
                        path: "listelemeler/olustur",
                        element: (<HophopPages.DashboardPages.Organizer.Listings.CreateListing />)
                    },
                    {
                        path: "listelemeler/edit/:id",
                        element: (<HophopPages.DashboardPages.Organizer.Listings.CreateListing />)
                    },


                    {
                        path: "konumlar",
                        element: (<HophopPages.DashboardPages.Organizer.Locations.Main />)
                    },
                    {
                        path: "konumlar/liste",
                        element: (<HophopPages.DashboardPages.Organizer.Locations.Main />)
                    },
                    {
                        path: "konumlar/olustur",
                        element: (<HophopPages.DashboardPages.Organizer.Locations.CreateLocations />)
                    },
                    {
                        path: "konum-onayla",
                        element: (<HophopPages.DashboardPages.Organizer.Locations.AproveLocations />)
                    },



                    {
                        path: "rezervasyonlar/liste",
                        element: (<HophopPages.DashboardPages.Organizer.Reservations.Main />)
                    },
                    {
                        path: "rezervasyonlar/:id",
                        element: (<HophopPages.DashboardPages.Organizer.Reservations.ReservationDetailList />)
                    },


                    {
                        path: "musteriler/liste",
                        element: (<HophopPages.DashboardPages.Organizer.Customers.Main />)
                    },

                    {
                        path: "yorumlar/liste",
                        element: (<HophopPages.DashboardPages.Organizer.Comments.Main />)
                    },

                    {
                        path: "mesajlar/liste",
                        element: (<HophopPages.DashboardPages.Organizer.Messages.Main />)
                    },

                    {
                        path: "odemeler/liste",
                        element: (<HophopPages.DashboardPages.Organizer.Payments.Main />)
                    },


                    {
                        path: "user-profile",
                        element: (<HophopPages.DashboardPages.Organizer.UserProfile />)
                    },
                    {
                        path: "settings",
                        element: (<HophopPages.DashboardPages.Organizer.Settings />)
                    },
                    { path: '*', element: <NotFoundPage /> },

                ]
            })),


            // { path: '*', element: <NotFoundPage /> },
            // { path: '*', element: <Navigate to="/¨" replace /> },
        ]
    })

}