/* eslint-disable arrow-body-style */
import { Container } from '@mui/material'
import React from 'react'
import SeoIllustration from 'src/assets/illustrations/seo-illustration'
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs'
import { useSettingsContext } from 'src/components/settings'
import { ChatView } from 'src/sections/chat/view'
import AppWelcome from 'src/sections/overview/app/app-welcome'

const Messages = () => {
    const settings = useSettingsContext()
    return (
        <Container maxWidth={settings.themeStretch ? false : 'lg'}>
            <CustomBreadcrumbs
                heading="Mesajlar"
                // action={
                //     <Button
                //         component={RouterLink}
                //         href="/konumlar/olustur"
                //         variant="contained"
                //         startIcon={<Iconify icon="mingcute:add-line" />}
                //     >
                //         Yeni Konum
                //     </Button>
                // }
                sx={{
                    mb: { xs: 3, md: 5 },
                }}
            />

            <AppWelcome
                // title={`Welcome ${userInfo?.personalInformation?.fullName?.toUpperCase()}`}
                title="Hoş geldiniz!"
                description="Bu sayfayı kullanarak etkinlikler oluşturabilir ve yönetebilirsiniz."
                img={
                    <SeoIllustration
                        sx={{
                            p: 3,
                            // width: 360,
                            margin: { xs: 'auto', md: 'inherit' },
                        }}
                    />
                }
            // action={<Button variant="contained">Şimdi Başla</Button>}
            />
            {/* <ChatView /> */}

        </Container>
    )
}

export default Messages