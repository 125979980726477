import { listingsForModerator } from "./_listingsForModerator"
import { listingsForOrganizer } from "./_listingsForOrganizer"
import { locationsForModerator } from "./_locationsForModerator"
import { locationsForOrganizer } from "./_locationsForOrganizer"
import { activitiesForModerator } from "./_activitiesForModerator"
import { activitiesForOrganizer } from "./_activitiesForOrganizer"

export const mutations = {
    activitiesForModerator,
    activitiesForOrganizer,

    listingsForModerator,
    listingsForOrganizer,

    locationsForModerator,
    locationsForOrganizer,
}