/* eslint-disable react/self-closing-comp */
import axios from 'axios';
import * as Yup from 'yup';
import { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack, Card, InputAdornment, IconButton, Grid } from '@mui/material';

import Iconify from 'src/components/iconify';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFTextField } from 'src/components/hook-form';



export default function AccountChangePassword() {

  const { enqueueSnackbar } = useSnackbar();
  const { accessToken } = useSelector(state => state.auth)
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [showPassword3, setShowPassword3] = useState(false);

  const ChangePassWordSchema = Yup.object().shape({
    oldPassword: Yup.string().required('Eski şifre gerekli!'),
    newPassword: Yup.string()
      .min(8, 'Şifreniz en az 8 karakter olmalı!')
      .matches(/[a-z]/, "Küçük harf içermeli")
      .matches(/[A-Z]/, "Büyük harf içermeli")
      .matches(/[0-9]/, "Sayı içermeli")
      .matches(/(?=.*[?!@#$%^&*])/, "Özel karakter içermeli")
      .required('Yeni şifre gerekli!'),
    confirmNewPassword: Yup.string().oneOf([Yup.ref('newPassword'), null], 'Yeni şifre ile eşleşmedi!'),
  });

  const defaultValues = {
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  };

  const methods = useForm({
    resolver: yupResolver(ChangePassWordSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },

  } = methods;

  const onSubmit = async (data) => {

    try {

      const rawData = JSON.stringify({
        "oldPassword": data?.oldPassword,
        "password": data?.newPassword
      });

      const config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/auth/auth/resetpassword`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        },
        data: rawData
      };

      const response = await axios.request(config)

      console.log(response);


      reset();
      enqueueSnackbar('Şifreniz başarıyla güncellenmiştir!');

    } catch (error) {
      console.error(error);

      if (error?.response?.data?.message === "WrongPassword") {
        enqueueSnackbar("Yanlış Şifre!", {
          variant: 'error',
        })
      } else if (error?.response?.data?.message === 'PasswordMustBeDifferentFromPrevious') {
        enqueueSnackbar("Yeni şifre eski şifre ile aynı olmamalı!", {
          variant: 'error'
        })
      }
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>

      <Card>
        <Stack spacing={3} alignItems="flex-end" sx={{ p: { xs: 1, sm: 3 } }}>

          <Grid container spacing={3} >

            <Grid item xs={12} md={6}>
              <RHFTextField
                name="oldPassword"
                label="Şifre"
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                        {/* <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} /> */}
                        <Iconify icon={showPassword ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />

                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={12} md={6}></Grid>

            <Grid item xs={12} md={6}>
              <RHFTextField
                name="newPassword"
                label="New Password"
                type={showPassword2 ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword2(!showPassword2)} edge="end">
                        {/* <Iconify icon={showPassword2 ? 'eva:eye-fill' : 'eva:eye-off-fill'} /> */}
                        <Iconify icon={showPassword2 ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />

                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                helperText={
                  <Stack
                    component="span"
                    direction="column"
                    alignItems="left"
                  >
                    <span>
                      <Iconify icon="eva:info-fill" width={16} sx={{ mr: 0.5 }} /> Şifreniz en az 8 karakter olmalı
                    </span>
                    <span>
                      <Iconify icon="eva:info-fill" width={16} sx={{ mr: 0.5 }} /> Numara, büyük harf, küçük harf, özel karakter içermeli
                    </span>
                  </Stack>
                }
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <RHFTextField
                name="confirmNewPassword"
                label="Confirm New Password"
                type={showPassword3 ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword3(!showPassword3)} edge="end">
                        {/* <Iconify icon={showPassword3 ? 'eva:eye-fill' : 'eva:eye-off-fill'} /> */}
                        <Iconify icon={showPassword3 ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />

                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>








          </Grid>

          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            Save Changes
          </LoadingButton>
        </Stack>
      </Card>
    </FormProvider>
  );
}
