import { Container } from '@mui/material'
import React from 'react'
import { Helmet } from 'react-helmet-async'
import { useParams } from 'react-router'
import { useSelector } from 'react-redux'

import AppWelcome from 'src/sections/overview/app/app-welcome'
import { SeoIllustration } from '../../../../../assets/illustrations'
import { useSettingsContext } from '../../../../../components/settings'
// import { AppWelcome } from '../../../../../../sections/@dashboard/general/app'
// import Button from '../../../../../../theme/overrides/Button'


const Panel = () => {

    // const { slug } = useParams()
    const { themeStretch } = useSettingsContext();
    const { user: userInfo } = useSelector(state => state.user)
    console.log({ userInfo })

    return (
        <>
            <Helmet>
                <title> Hophop</title>
            </Helmet>

            <Container maxWidth={themeStretch ? false : 'xl'}>

                <AppWelcome
                    title={`Hoş geldiniz ${userInfo?.personalInformation?.fullName?.toUpperCase()}`}
                    // title={`Welcome back! \n ${user?.displayName}`}
                    description="Bu sayfayı kullanarak etkinlikler oluşturabilir ve yönetebilirsiniz."
                    img={
                        <SeoIllustration
                            sx={{
                                p: 3,
                                // maxWidth: 360,
                                margin: { xs: 'auto', md: 'inherit' },
                            }}
                        />
                    }
                // action={<Button variant="contained">Şimdi Başla</Button>}
                />
            </Container>
        </>
    )
}

export default Panel