/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/prop-types */
/* eslint-disable arrow-body-style */
import { Box, Typography } from '@mui/material'
import React from 'react'

import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import { LoadingButton } from '@mui/lab';

const OrganizerOnboardingLastPage = ({ setOrganizerOnboardingPageState, handleNext, handleBack }) => {

    const handleStart = () => {
        window.location.reload()
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >

            <Box>
                <CheckCircleOutlineRoundedIcon
                    sx={{
                        color: 'green',
                        width: '100px',
                        height: '100px',
                        mb: '50px'
                    }}
                />
            </Box>

            <Typography
                sx={{
                    mb: '50px',
                    fontSize: '28px'
                }}
            >
                Tebrikler. Başarılı bir şekilde tamamladınız!
            </Typography>

            <LoadingButton
                onClick={handleStart}
                variant='contained'
                sx={{
                    width: { xs: '100%', sm: '160px' }
                }}
            >
                Başla
            </LoadingButton>
        </Box>
    )
}

export default OrganizerOnboardingLastPage