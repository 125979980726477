/* eslint-disable no-shadow */
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect, useMemo } from 'react';
import { Box, Grid, Card, Stack, Typography } from '@mui/material';

import { fData } from 'src/utils/format-number';

// import { fData } from 'src/utils/formatNumber';
import { countries } from 'src/assets/data';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, {
  RHFSwitch,
  RHFSelect,
  RHFTextField,
  RHFUploadAvatar,
} from 'src/components/hook-form';
import { graphqlApi } from 'src/hophop/api';
import { reduxFunc } from 'src/hophop/redux';
import useFetchData from 'src/hophop/hooks/_useFetchData';



export default function AccountGeneral() {

  const dispatch = useDispatch()
  const { handleFetchData } = useFetchData()

  const { enqueueSnackbar } = useSnackbar();
  const { accessToken, user: authUser } = useSelector(state => state.auth)
  const { user: userInfo } = useSelector(state => state.user)
  // console.log({ userInfo })

  const UpdateUserSchema = Yup.object().shape({
    fullName: Yup.string(),
    // .required('Name is required'),
    email: Yup.string().required('Email gerekli').email('Geçerli bir email adresi girin!'),
    profileImageUrl: Yup.string().required('Avatar is required').nullable(true),
    phoneNumber: Yup.string().required('Phone number is required'),

    childAge: Yup.number().required('childAge is required'),

    identificationNumber: Yup.string().required('Address is required'),

    organization: Yup.string().required('State is required'),

    taxNo: Yup.string().required('Vergi numarası gerekli!'),
    taxOffice: Yup.string().required('Vergi dairesi gerekli!'),
    address: Yup.string().required('address is required'),
  });

  const defaultValues = useMemo(() => ({
    fullName: userInfo?.personalInformation?.fullName || '',
    email: userInfo?.contact?.email || '',
    profileImageUrl: userInfo?.personalInformation?.profileImageUrl || null,
    phoneNumber: userInfo?.contact?.phoneNumber || '',

    childAge: userInfo?.personalInformation?.childAge || "",

    identificationNumber: userInfo?.personalInformation?.identificationNumber || '',
    organization: userInfo?.personalInformation?.organization || '',
    taxNo: userInfo?.personalInformation?.taxNo || '',
    taxOffice: userInfo?.personalInformation?.taxOffice || '',
    address: userInfo?.contact?.address || '',

    // isPublic: !!userInfo?.personalInformation?.organization || false,
  }), [userInfo]);

  const methods = useForm({
    resolver: yupResolver(UpdateUserSchema),
    defaultValues,
  });

  const {
    setValue,
    handleSubmit,
    formState: { isSubmitting },
    reset,
    watch
  } = methods;


  useEffect(() => {
    reset({ ...defaultValues });
  }, [defaultValues, reset]);

  const onSubmit = async () => {

    const data = watch()
    let uploadUrl;

    console.log("****************************************")
    try {
      if (typeof data?.profileImageUrl !== "string") {
        uploadUrl = await graphqlApi.dms.organizationPresignedUrls.logoImageOrganizationPresignedUrls({
          organizationId: userInfo?._id,
          file: data.logo,
          accessToken
        })
        console.log({ uploadUrl })
        const imageUrl = await graphqlApi.dms.handleSaveImage({
          file: data.profileImageUrl,
          url: uploadUrl?.presignedUploadUrl
        })
        console.log({ imageUrl })
      }


      const response = await handleFetchData({
        data: graphqlApi.user.mutations.userLMutations.userUpdate({
          values: data,
          profileImageUrl: (typeof data?.profileImageUrl !== "string") ? uploadUrl?.url : data?.profileImageUrl,
          // userId: user?.userId
        }),
        url: "/user/graphql",
        accessToken,
      });
      console.log({ response })
      await dispatch(reduxFunc.fetchUserInfoFunc({ accessToken, userId: authUser?.userId }))
      enqueueSnackbar('Update success!');
      console.log('DATA', data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      if (file) {
        setValue('profileImageUrl', newFile, { shouldValidate: true });
      }
    },
    [setValue]
  );


  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>

        <Grid item xs={12} md={4}>
          <Card sx={{ py: 10, px: 3, textAlign: 'center' }}>
            <RHFUploadAvatar
              name="profileImageUrl"
              maxSize={3145728}
              onDrop={handleDrop}
            // helperText={
            //   <Typography
            //     variant="caption"
            //     sx={{
            //       mt: 2,
            //       mx: 'auto',
            //       display: 'block',
            //       textAlign: 'center',
            //       color: 'text.secondary',
            //     }}
            //   >
            //     *.jpeg, *.jpg, *.png
            //     <br /> max boyut {fData(3045728)}
            //   </Typography>
            // }
            />

            <Typography sx={{ textTransform: 'capitalize', my: 3, }}>
              {userInfo?.personalInformation?.fullName}
            </Typography>

            {/* <LoadingButton onClick={handleChangePhoto} variant="contained" >
              Change Photo
            </LoadingButton> */}

          </Card>
        </Grid>

        <Grid item xs={12} md={8}>
          <Card sx={{ p: 3 }}>


            {/* <RHFSwitch
              name="isPublic"
              labelPlacement="start"
              label="Kurumsal"
              sx={{ mb: 5 }}
            /> */}

            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              }}
            >

              <RHFTextField name="fullName" label="İsim" />
              <RHFTextField name="identificationNumber" label="TC. No." />
              <RHFTextField name="email" label="Email Adresi" />
              <RHFTextField name="phoneNumber" label="Telefon Numarası" />

              {
                userInfo?.personalInformation?.childAge && (
                  <RHFSelect native name="childAge" label="Çocuk yaşı" placeholder="çocuk yaşı">
                    <option value="" />
                    {countries.map((item) => (
                      <option key={item} >
                        dsdf
                      </option>
                    ))}
                  </RHFSelect>
                )
              }

              <RHFTextField name="organization" label="Organizasyon Adı" />
              <RHFTextField name="taxOffice" label="Vergi Dairesi" />
              <RHFTextField name="taxNo" label="Vergi No." />

            </Box>

            <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>

              <RHFTextField name="address" multiline rows={4} label="Adres" />

              <LoadingButton
                type="submit"
                variant="contained"
                loading={isSubmitting}
                onClick={() => onSubmit()}
              >
                Kaydet
              </LoadingButton>

            </Stack>

          </Card>
        </Grid>

      </Grid>
    </FormProvider>
  );
}
