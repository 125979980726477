import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { enqueueSnackbar } from 'notistack';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';

import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import { useBoolean } from 'src/hooks/use-boolean';
import { usePopover } from 'src/components/custom-popover';
import { parkingForLocation } from 'src/hophop/utils/_enums';
import { ConfirmDialog } from 'src/components/custom-dialog';
import useFetchData from 'src/hophop/hooks/_useFetchData';
import { graphqlApi } from 'src/hophop/api';

import UserQuickEditForm from './location-quick-edit-form';



export default function LocationTableRow({ row, selected, onEditRow, onSelectRow, onDeleteRow }) {

  const popover = usePopover();
  const confirm = useBoolean();
  const quickEdit = useBoolean();
  const { accessToken } = useSelector(state => state.auth)
  const { handleFetchData } = useFetchData()

  const handleUpdate = async () => {
    try {
      const response = await handleFetchData({
        data: graphqlApi.activity.queries.locationsForModerator.isUpdatable({ locationId: row?._id }),
        accessToken
      })

      console.log({ response })

      if (response?.data?.data?.user_isLocationUpdatable) {
        quickEdit.onTrue()
      } else {
        enqueueSnackbar("Bu konuma güncelleme yapılamaz!", {
          variant: "error"
        })
      }
    } catch (error) {
      console.log([{ error }])
    }
  }

  return (
    <>
      <TableRow hover selected={selected}>
        {/* <TableCell padding="checkbox">
          <Checkbox checked={selected} onClick={onSelectRow} />
        </TableCell> */}

        <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
          {/* <Avatar alt={name} src={avatarUrl} sx={{ mr: 2 }} /> */}

          <ListItemText
            primary={row?.address?.title}
            secondary={row?.address?.description}
            primaryTypographyProps={{ typography: 'body2' }}
            secondaryTypographyProps={{ component: 'span', color: 'text.disabled' }}
          />
        </TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>{row?.city}</TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>{row?.district}</TableCell>

        {/* <TableCell sx={{ whiteSpace: 'nowrap' }}>{role}</TableCell> */}

        <TableCell>
          <Label
            variant="soft"
            color={
              (row?.parking === 'exists' && 'success') ||
              (row?.parking === 'limitid' && 'warning') ||
              (row?.parking === 'nonexist' && 'error') ||
              'default'
            }
          >
            {parkingForLocation?.find(item => item?.value === row?.parking)?.name}
          </Label>
        </TableCell>

        <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
          <Tooltip title="Düzenle" placement="top" arrow>
            <IconButton color={quickEdit.value ? 'inherit' : 'default'} onClick={handleUpdate}>
              <Iconify icon="solar:pen-bold" />
            </IconButton>
          </Tooltip>

          <IconButton color={popover.open ? 'inherit' : 'default'} onClick={confirm.onTrue} sx={{ color: 'error.main' }}>
            <Iconify icon="solar:trash-bin-trash-bold" />
          </IconButton>
          {/* <IconButton color={popover.open ? 'inherit' : 'default'} onClick={popover.onOpen}>
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton> */}
        </TableCell>
      </TableRow>

      {quickEdit.value && <UserQuickEditForm currentUser={row} open={quickEdit.value} onClose={quickEdit.onFalse} id={row?._id} />}

      {/* <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        <MenuItem
          onClick={() => {
            confirm.onTrue();
            popover.onClose();
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon="solar:trash-bin-trash-bold" />
          Sil
        </MenuItem>

        <MenuItem
          onClick={() => {
            onEditRow();
            popover.onClose();
          }}
        >
          <Iconify icon="solar:pen-bold" />
          Düzenle
        </MenuItem>
      </CustomPopover> */}

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Konum sil"
        content="Silmek istediğinize emin misiniz?"

        action={
          <Button variant="contained" color="error" onClick={() => {
            onDeleteRow(row?._id)
            confirm.onFalse()
          }}
          >
            Sil
          </Button>
        }
      />
    </>
  );
}

LocationTableRow.propTypes = {
  onDeleteRow: PropTypes.func,
  onEditRow: PropTypes.func,
  onSelectRow: PropTypes.func,
  row: PropTypes.object,
  selected: PropTypes.bool,
};
