const listingDetail = ({ listingId }) => {

  const data = JSON.stringify({
    query: `
    query Moderator_listing($listingId: String!) {
      moderator_listing(listingId: $listingId) {
              _id
              title
              category
              subCategory
              ageInterval
              format
              description
              images
              coverImage
              companionship
              # createdBy {
              #   _id
              #   displayName
              #   displayImageUrl
              #   role
              # }
              updatedBy {
                _id
                displayName
                displayImageUrl
                role
              }
              status
              statusUpdatedBy {
                _id
                displayName
                displayImageUrl
                role
              }
              type
              createdAt
              updatedAt
            }
          }
              `,
    variables: {
      "listingId": listingId
    }
  })

  return data

}

const allListings = ({ variables = null }) => {

  const data = JSON.stringify({
    query: `
    query Moderator_listingsOfModerators($filter: FilterListingsOfModeratorsByModeratorInput, $pagination: Pagination) {
      moderator_listingsOfModerators(filter: $filter, pagination: $pagination) {
        count
        data {
          _id
          ageInterval
          category
          companionship
          coverImage
          createdAt
          createdBy {
            role
            displayName
            displayImageUrl
            description
            _id
          }
          description
          format
          images
          rejectionDetail
          status
          statusUpdatedBy {
            _id
            description
            displayImageUrl
            displayName
            role
          }
          subCategory
          title
          type
          updatedAt
          updatedBy {
            _id
            description
            displayImageUrl
            displayName
            role
          }
        }
      }
    }
      `,
    variables: {
      "filter": {
        "ageInterval": null,
        "category": null,
        "companionship": null,
        "createdByCurrentUser": null,
        "description": null,
        "format": null,
        "status": null,
        "title": null,
        "type": null
      },
      "pagination": {
        "page": variables?.pagination?.page ?? null,
        "pageSize": variables?.pagination?.pageSize ?? null
      }
    }
  })

  return data

}

const isUpdatable = ({ listingId }) => {

  const data = JSON.stringify({
    query: `
    query Query($listingId: String!) {
      user_isListingUpdatable(listingId: $listingId)
    }
    `,
    variables: {
      "listingId": listingId
    }
  })

  return data
}

export const listingsForModerator = {
  listingDetail,
  allListings,
  isUpdatable
}