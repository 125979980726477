import React from 'react'
import { Provider } from 'react-redux'
import { configureStore } from '@reduxjs/toolkit'

import authReducer from './slices/_authSlice'
import userReducer from './slices/_userSlice'
import activityReducer from './slices/_activitySlice'
import listingReducer from './slices/_listingSlice'
import locationReducer from './slices/_locationSlice'
import parameterReducer from './slices/_parameterSlice'




export const store = configureStore({
    reducer: {
        auth: authReducer,
        user: userReducer,
        activity: activityReducer,
        listing: listingReducer,
        location: locationReducer,
        parameter: parameterReducer,
    },
})



// eslint-disable-next-line arrow-body-style, react/prop-types
const ReduxProvider = ({ children }) => {
    return (
        <Provider store={store}>
            {children}
        </Provider>
    )
}

export default ReduxProvider