const createLocation = ({ values }) => {

  const data = JSON.stringify({
    query: `
    mutation Organizer_createLocation($locationInput: LocationByOrganizerInput!) {
      organizer_createLocation(locationInput: $locationInput) {
        _id
      }
    }
            `,
    variables: {
      "locationInput": {
        "address": {
          "description": values?.description ?? null,
          "title": values?.title ?? null,
        },
        "city": values?.city ?? null,
        "district": values?.district ?? null,
        // "country": values?.country ?? null,

        "lat": Number(values?.lat) ?? null,
        "long": Number(values?.long) ?? null,
        "parking": values?.parking ?? null
      }
    }
  })

  return data

}


const removeLocation = ({ locationId }) => {

  const data = JSON.stringify({
    query: `
    mutation Organizer_removeLocation($locationId: String!) {
      organizer_removeLocation(locationId: $locationId) {
        success
      }
    }
            `,
    variables: {
      "locationId": locationId
    }
  })

  return data

}


const updateOrganizatorLocation = ({ locationId, values }) => {

  const data = JSON.stringify({
    query: `
    mutation Moderator_updateLocation($locationId: String!, $updateInput: UpdateLocationByModeratorInput) {
      moderator_updateLocation(locationId: $locationId, updateInput: $updateInput) {
        _id
      }
    }
            `,
    variables: {
      "locationId": locationId,
      "updateInput": {
        "address": values?.address ?? null,
        "city": values?.city ?? null,
        "country": values?.country ?? null,
        "lat": values?.lat ?? null,
        "long": values?.long ?? null,
        "parking": values?.parking ?? null
      }
    }
  })

  return data

}


export const locationsForOrganizer = {
  createLocation,
  removeLocation,
  updateOrganizatorLocation,
}