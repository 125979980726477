/* eslint-disable arrow-body-style */
import axios from "axios"
import { findImageContentType } from "../../utils"
import { dmsConfig } from "./_dmsConfig"


const allOrganizationPresignedUrls = async ({ organizationId, file, accessToken }) => {

    const contentType = findImageContentType(file)
    // console.log({ organizationId, file, contentType })

    const data = JSON.stringify({
        query: `
            query OrganizationPresignedUrls($organizationId: ID!, $contentType: ImageContentType!) {
                organizationPresignedUrls(organizationId: $organizationId, contentType: $contentType) {
                    backgroundImage {
                        presignedUploadUrl
                        presignedThumbnailUpload
                        url
                    }
                    logoImage {
                        presignedUploadUrl
                        presignedThumbnailUpload
                        url
                    }
                }
            }
          `,
        variables: {
            "organizationId": organizationId,
            "contentType": contentType ?? "jpegImage",
        }
    })


    try {
        const res = await axios(dmsConfig({ data, accessToken }))
        // console.log({ res: res?.data?.data })
        return res?.data?.data?.organizationPresignedUrls.logoImage
    } catch (err) {
        // console.log({ err })
        return err
    }

}

const backgroundImageOrganizationPresignedUrls = async ({ organizationId, file, accessToken }) => {

    const contentType = findImageContentType(file)
    // console.log({ organizationId, file, contentType })

    const data = JSON.stringify({
        query: `
            query OrganizationPresignedUrls($organizationId: ID!, $contentType: ImageContentType!) {
                organizationPresignedUrls(organizationId: $organizationId, contentType: $contentType) {
                    backgroundImage {
                        presignedUploadUrl
                        presignedThumbnailUpload
                        url
                    }
                }
            }
          `,
        variables: {
            "organizationId": organizationId,
            "contentType": contentType ?? "jpegImage",
        }
    })


    try {
        const res = await axios(dmsConfig({ data, accessToken }))
        // console.log({ res: res?.data?.data })
        return res?.data?.data?.organizationPresignedUrls.backgroundImage
    } catch (err) {
        // console.log({ err })
        return err
    }

}


const logoImageOrganizationPresignedUrls = async ({ organizationId, file, accessToken }) => {

    const contentType = findImageContentType(file)
    // console.log({ organizationId, file, contentType, accessToken })

    const data = JSON.stringify({
        query: `
            query OrganizationPresignedUrls($organizationId: ID!, $contentType: ImageContentType!) {
                organizationPresignedUrls(organizationId: $organizationId, contentType: $contentType) {
                    logoImage {
                        presignedUploadUrl
                        presignedThumbnailUpload
                        url
                    }
                }
            }
          `,
        variables: {
            "organizationId": organizationId,
            "contentType": contentType ?? "jpegImage",
        }
    })


    try {
        const res = await axios(dmsConfig({ data, accessToken }))
        // console.log({ res: res?.data?.data })
        return res?.data?.data?.organizationPresignedUrls.logoImage
    } catch (err) {
        // console.log({ err })
        return err
    }

}



export const organizationPresignedUrls = {
    allOrganizationPresignedUrls,
    logoImageOrganizationPresignedUrls,
    backgroundImageOrganizationPresignedUrls,
}
