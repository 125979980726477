/* eslint-disable arrow-body-style */
/* eslint-disable react/jsx-boolean-value */
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet, useRoutes } from 'react-router-dom';

import { authRoutes } from 'src/hophop/routes/sections/auth';
import { commonRoutes } from 'src/hophop/routes/sections/common';

import RouteGuard from './RouteGuard';
import { reduxFunc } from '../redux';
import { organizerRoutes } from './sections/organizer';
import { moderatorRoutes } from './sections/moderator';

// ----------------------------------------------------------------------

const AppRouter = () => {

    const dispatch = useDispatch()
    const {
        isAuth,
        userRole,
        accessToken,
        isLoading: authLoading,
        user
    } = useSelector(state => state.auth)
    const {
        isOnboarding,
        user: userInfo,
        loading: userLoading,
    } = useSelector(state => state.user)

    // const isAuth = false
    // const userRole = "moderator"
    // const isOnboarding = true
    // console.log(userInfo)

    useEffect(() => {
        if (accessToken) {
            dispatch(reduxFunc.fetchUserInfoFunc({ accessToken, userId: user?.userId }))
        }
    }, [accessToken, dispatch, user?.userId])

    // console.log({ isOnboarding })

    return useRoutes([

        // Auth routes
        {
            path: 'auth',
            element: (
                <RouteGuard isAuth={!isAuth} redirectPath='/'>
                    <Outlet />
                </RouteGuard>
            ),
            children: [
                authRoutes,
            ],
        },

        // role base routes
        {
            path: '/',
            element: (
                <RouteGuard isAuth={isAuth} redirectPath='/auth'>
                    <Outlet />
                </RouteGuard>
            ),
            children: [

                // organizer
                (userRole === "organizer" && organizerRoutes({ userRole, isOnboarding, userLoading, authLoading })),


                // moderator
                (userRole === "moderator" && moderatorRoutes({ userRole, isOnboarding, userLoading }))

            ],
        },

        ...commonRoutes,

        { path: '*', element: <Navigate to="/" replace /> },
    ]);
}

export default AppRouter
