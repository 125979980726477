/* eslint-disable no-shadow */
import axios from 'axios'
import { enqueueSnackbar } from 'notistack';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { constants } from '../../constants'


const localData = localStorage.getItem(constants.localStorage.userLoginData)
  ? JSON.parse(localStorage.getItem(constants.localStorage.userLoginData))
  : null

// console.log({ localData })
const initialState = {
  data: localData ?? null,
  accessToken: localData?.accessToken ?? "",
  user: localData?.user ?? null,
  isAuth: !!localData,
  userRole: localData?.user?.role ?? "",
  isLoading: false,
  isSuccess: false,
  isError: false,
}

export const reduxLoginFunc = createAsyncThunk("auth/reduxLoginFunc", async (values) => {

  const data = JSON.stringify({
    "email": values?.email,
    "password": values?.password
  });

  const config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_BASE_URL}/auth/auth/login`,
    headers: {
      'Content-Type': 'application/json'
    },
    data
  };

  const response = await axios.request(config)
  return response?.data

})

export const reduxLogoutFunc = createAsyncThunk("auth/reduxLogoutFunc", async (values) => {

  const config = {
    method: 'delete',
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_BASE_URL}/auth/auth/logout`,
    headers: {
      'x-auth-token': values?.refreshToken
    },
    data: ""
  };

  const response = await axios(config)
  return response?.data

})


const { reducer: authReducer } = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    // loginReducer: (state, action) => {
    //   console.log(action)
    //   state.data = action.payload?.data
    //   state.user = action.payload?.data?.user
    //   state.userRole = action.payload?.data?.user?.role
    //   state.isAuth = action.payload?.isSuccess
    //   state.accessToken = action.payload?.data?.accessToken
    //   localStorage.setItem(constants.localStorage.userLoginData, JSON.stringify(action.payload?.data))

    // },
    // logoutReducer: (state, action) => {
    //   localStorage.removeItem(constants.localStorage.userLoginData)
    //   state.data = null
    //   state.accessToken = ""
    //   state.user = null
    //   state.isAuth = false
    //   state.isLoading = false
    //   state.isSuccess = false
    //   state.isError = false
    // }
  },
  extraReducers: (builder) => {

    // ################# login ######
    builder.addCase(reduxLoginFunc.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(reduxLoginFunc.fulfilled, (state, action) => {
      console.log(action?.payload)
      state.data = action.payload?.data
      state.user = action.payload?.data?.user
      state.userRole = action.payload?.data?.user?.role
      state.isAuth = action.payload?.isSuccess
      state.accessToken = action.payload?.data?.accessToken
      localStorage.setItem(constants.localStorage.userLoginData, JSON.stringify(action.payload?.data))
      state.isLoading = false
      enqueueSnackbar('Gişir Başarılı!')

    })
    builder.addCase(reduxLoginFunc.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      enqueueSnackbar('Kullanıcı bulunamadı ya da şifre hatalı!', {
        variant: "error"
      })

    })


    // ################# logout ######
    builder.addCase(reduxLogoutFunc.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(reduxLogoutFunc.fulfilled, (state, action) => {
      console.log(action?.payload)
      localStorage.removeItem(constants.localStorage.userLoginData)
      state.data = null
      state.accessToken = ""
      state.user = null
      state.isAuth = false
      state.isLoading = false
      state.isSuccess = false
      state.isError = false
      enqueueSnackbar('Çıkış Başarılı!')


    })
    builder.addCase(reduxLogoutFunc.rejected, (state, action) => {
      state.isLoading = false
      enqueueSnackbar('Çıkış Yapılamadı!', {
        variant: "error"
      })
    })
  }
})


// export const { logoutReducer, loginReducer } = actions
export default authReducer