/* eslint-disable no-shadow */
import axios from 'axios'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { graphqlApi } from '../../api'

// graphqlApi

const configFunc = ({ data, accessToken }) => ({
    method: 'post',
    url: `${process.env.REACT_APP_BASE_URL}/user/graphql`,
    headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
        'accept': 'application/json'
    },
    data,
})


const initialState = {
    user: null,
    loading: false,
    isOnboarding: null,

}

export const fetchUserInfoFunc = createAsyncThunk('user/fetchUserInfo', async ({ userId, accessToken }) => {

    // console.log({ userId, accessToken })
    const response = await axios(configFunc({ accessToken, data: graphqlApi.user.queries.userQueries.userQuery({ userId }) }))
    // console.log({ response })
    return response?.data

})



const { reducer: userReducer } = createSlice({
    name: 'user',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // ################# login ######
        builder.addCase(fetchUserInfoFunc.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(fetchUserInfoFunc.fulfilled, (state, action) => {
            // console.log(action?.payload)
            state.user = action.payload?.data?.user
            state.isOnboarding = action.payload?.data?.user?.onboarded
            state.loading = false
        })
        builder.addCase(fetchUserInfoFunc.rejected, (state, action) => {
            console.log(action)
            state.loading = false
        })
    }
})


export default userReducer