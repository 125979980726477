export const ageIntervalsForListing = [
    {
        value: 0,
        name: "0-2 Yaş",
    },
    {
        value: 1,
        name: "2-4 Yaş",
    },
    {
        value: 2,
        name: "4-6 Yaş",
    },
    {
        value: 3,
        name: "6-8 Yaş",
    },
    {
        value: 4,
        name: "8-10 Yaş",
    },
    {
        value: 5,
        name: "10-12 Yaş",
    },
    {
        value: 6,
        name: "12+ Yaş",
    },
]

// export const categoriesForListing = [
//     "ATÖLYELER",
//     "ANAOKULU VE KREŞLER",
//     "KURSLAR",
//     "ETKİNLİKLER",
//     "SPOR OKULLARI",
//     "HAFTASONU GİDİLECEK YERLER",
//     "ÇOCUKLARLA GİDİLEBİLECEK MÜZELER",
//     "ÇOCUK KÜTÜPHANELERİ",
//     "TARİHİ MEKANLAR",
//     "PARK VE EĞLENCELER",
//     "BEBEK VE ÇOCUK DOKTORLARI",
//     "ÇOCUK PSİKOLOGLARI",
//     "OYUN TERAPİSTİ",
//     "BEBEK VE ÇOCUK BAKICISI",
// ]

export const companionshipsForListing = [
    { value: "required", name: "zorunlu" },
    { value: "maybe", name: "zorunlu değil" },
    { value: "never", name: "katılamaz" },
]

export const formatsForListing = [
    { value: "faceToFace", name: "Yüz yüze" },
    { value: "online", name: "Online" },
    { value: "preRecorded", name: "Kayıttan" },
]

export const typesForListing = [
    { value: "activity", name: "Aktivite" },
    { value: "blog", name: "Blog" },
]

export const parkingForLocation = [
    { value: "exists", name: "Var" },
    { value: "nonExists", name: "Yok" },
    { value: "limited", name: "Limitli" },
]

export const repeatIntervalTypes = [
    // { value: "once", name: "Bir Kere" },
    { value: "daily", name: "Günlük" },
    { value: "weekly", name: "Haftalık" },
    // { value: "monthly", name: "Aylık" },
]

export const timeMapping = [
    // { value: 0, name: "00:00" },
    // { value: 1, name: "00:30" },
    // { value: 2, name: "01:00" },
    // { value: 3, name: "01:30" },
    // { value: 4, name: "02:00" },
    // { value: 5, name: "02:30" },
    // { value: 6, name: "03:00" },
    // { value: 7, name: "03:30" },
    // { value: 8, name: "04:00" },
    // { value: 9, name: "04:30" },
    // { value: 10, name: "05:00" },
    // { value: 11, name: "05:30" },
    { value: 12, name: "06:00" },
    { value: 13, name: "06:30" },
    { value: 14, name: "07:00" },
    { value: 15, name: "07:30" },
    { value: 16, name: "08:00" },
    { value: 17, name: "08:30" },
    { value: 18, name: "09:00" },
    { value: 19, name: "09:30" },
    { value: 20, name: "10:00" },
    { value: 21, name: "10:30" },
    { value: 22, name: "11:00" },
    { value: 23, name: "11:30" },
    { value: 24, name: "12:00" },
    { value: 25, name: "12:30" },
    { value: 26, name: "13:00" },
    { value: 27, name: "13:30" },
    { value: 28, name: "14:00" },
    { value: 29, name: "14:30" },
    { value: 30, name: "15:00" },
    { value: 31, name: "15:30" },
    { value: 32, name: "16:00" },
    { value: 33, name: "16:30" },
    { value: 34, name: "17:00" },
    { value: 35, name: "17:30" },
    { value: 36, name: "18:00" },
    { value: 37, name: "18:30" },
    { value: 38, name: "19:00" },
    { value: 39, name: "19:30" },
    { value: 40, name: "20:00" },
    { value: 41, name: "20:30" },
    { value: 42, name: "21:00" },
    { value: 43, name: "21:30" },
    { value: 44, name: "22:00" },
    { value: 45, name: "22:30" },
    { value: 46, name: "23:00" },
    { value: 47, name: "23:30" },
    { value: 0, name: "00:00" },
]

export const dayMapping = [
    { value: 1, name: "Pazartesi" },
    { value: 2, name: "Salı" },
    { value: 3, name: "Çarşamba" },
    { value: 4, name: "Perşembe" },
    { value: 5, name: "Cuma" },
    { value: 6, name: "Cumartesi" },
    { value: 0, name: "Pazar" },
]

export const hoursBeforeReservationExpiresList = [
    { value: 0, name: "Yok" },
    { value: 1, name: "1 saat önce" },
    { value: 2, name: "2 saat önce" },
    { value: 3, name: "3 saat önce" },
    { value: 6, name: "6 saat önce" },
    { value: 12, name: "12 saat önce" },
    { value: 24, name: "1 gün önce" },
]
