/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/prop-types */
/* eslint-disable arrow-body-style */
import React from 'react'
import { Box, ButtonBase } from '@mui/material'

import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';


const OrganizerOnboardingFistPage = ({ setOrganizerOnboardingPageState, handleNext }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' },
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                gap: '20px',
                // backgroundColor: 'red',
            }}
        >

            {
                [
                    {
                        name: "Bireysel",
                        icon: <PersonOutlineOutlinedIcon />,
                        function: () => setOrganizerOnboardingPageState("individual")
                    },
                    {
                        name: "Kurumsal",
                        icon: <BusinessOutlinedIcon />,
                        function: () => setOrganizerOnboardingPageState("institue")
                    }
                ].map((item, index) => {

                    return (
                        <ButtonBase
                            onClick={() => {
                                item?.function()
                                handleNext()
                            }}
                            key={`${item?.name}${index}`}
                            sx={{
                                border: '1px solid #fff',
                                boxShadow: '1px 1px 5px 0px #ddd',

                                borderRadius: '8px',
                                p: { xs: '30px 10px' },

                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                textAlign: 'center',
                                gap: '20px',
                                width: { xs: '100%', sm: '200px' },
                                transitionDuration: '0.5s',

                                '&:hover': {
                                    border: '1px solid #fff',
                                    translate: 'scale(1.05)',
                                    boxShadow: '1px 1px 20px 0px #ddd',
                                },

                                '& svg': {
                                    fontSize: '50px',
                                    color: '#aaa',
                                },
                                '& span': {
                                    color: '#aaa',
                                    fontSize: '30px'
                                }
                            }}
                        >
                            {item?.icon}
                            <span>
                                {item?.name}
                            </span>
                        </ButtonBase>
                    )
                })
            }


        </Box>
    )
}

export default OrganizerOnboardingFistPage