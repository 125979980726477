const locationDetail = () => {

  const data = JSON.stringify({
    query: `
        query LocationForOrganizer($locationId: String!) {
            locationForOrganizer(locationId: $locationId) {
              _id
              city
              country
              address
              lat
              long
              parking
              createdBy {
                _id
                displayName
                displayImageUrl
                role
              }
              updatedBy {
                _id
                displayName
                displayImageUrl
                role
              }
              createdAt
              updatedAt
            }
          }
              `,
    variables: {
      "locationId": null
    }
  })

  return data

}

const allLocations = () => {

  const data = JSON.stringify({
    query: `
    query Organizer_locations($pagination: Pagination) {
      organizer_locations(pagination: $pagination) {
        count
        data {
          _id
          city
          district
          country
          address {
            title
            description
          }
          lat
          long
          parking
          status
          # createdBy {
          #   _id
          #   displayName
          #   displayImageUrl
          #   description
          #   role
          # }
          updatedBy {
            _id
            displayName
            displayImageUrl
            description
            role
          }
          rejectionDetail
          createdAt
          updatedAt
        }
      }
    }
              `,
    variables: {
      "pagination": {
        "page": null,
        "pageSize": null
      }
    }
  })

  return data

}

const isUpdatable = ({ locationId }) => {

  const data = JSON.stringify({
    query: `
    query Query($locationId: String!) {
      user_isLocationUpdatable(locationId: $locationId)
    }
    `,
    variables: {
      "locationId": locationId
    }
  })

  return data
}

export const locationsForOrganizer = {
  locationDetail,
  allLocations,
  isUpdatable
}